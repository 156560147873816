import { combineReducers } from "redux";
import assistantReducer from "../../SmartAssistant/State/assistantReducer";
import newDeviceReducer from "../../Wocam/State/newDeviceReducer";
import { LOGOUT } from "../constants";
import accessListReducer from "./accessListReducer";
import activeNavbarReducer from "./activeNavBarReducer";
import alertDataReducer from "./alertDataReducer";
import authTokenReducer from "./authTokenReducer";
import deviceInfoReducer from "./deviceInfoReducer";
import loaderReducer from "./loaderReducer";
import navbarReducer from "./navbarReducer";
import redirectUrlAfterLoginReducer from "./redirectUrlAfterLoginReducer";
import userDataReducer from "./userDataReducer";

const initialState = {};

const combinedReducer = combineReducers({
  navbar: navbarReducer,
  userData: userDataReducer,
  authToken: authTokenReducer,
  alertData: alertDataReducer,
  deviceInfo: deviceInfoReducer,
  activeNavBar: activeNavbarReducer,
  loader: loaderReducer,
  accessList: accessListReducer,
  redirectUrlAfterLogin: redirectUrlAfterLoginReducer,
  newDevice: newDeviceReducer,
  smartAssistant: assistantReducer,
});

const rootReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT:
      return { ...initialState, deviceInfo: state.deviceInfo };

    default:
      return combinedReducer(state, action);
  }
};

export default rootReducer;
