import React from "react";

const IconPencil = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15.5" cy="15.5" r="15.5" fill="#3766E8" fillOpacity="0.1" />
      <g clipPath="url(#clip0)">
        <path
          d="M10.5691 21C10.4242 21 10.2793 20.9449 10.1692 20.834C9.94817 20.613 9.94817 20.2547 10.1692 20.0337L20.0371 10.1658C20.2581 9.94475 20.6164 9.94475 20.8374 10.1658C21.0584 10.3868 21.0584 10.7451 20.8374 10.9662L10.9696 20.834C10.8587 20.9442 10.7138 21 10.5691 21Z"
          fill="#3766E8"
        />
        <path
          d="M20.4377 21C20.2928 21 20.148 20.9449 20.0378 20.834L10.1692 10.9662C9.94817 10.7451 9.94817 10.3868 10.1692 10.1658C10.3902 9.94475 10.7485 9.94475 10.9696 10.1658L20.8374 20.0337C21.0584 20.2547 21.0584 20.613 20.8374 20.834C20.7265 20.9442 20.5817 21 20.4377 21Z"
          fill="#3766E8"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="11" height="11" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPencil;
