import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PAGE404, PERSON_SUITE, PERSON_SUITE_DETAIL } from "../Constants";
import PersonSuitListSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/PersonSuitSkeletion/PersonSuitListSkeleton";
import PersonSuiteSkeleton from "../Common/Components/SkeletonComponent/DetailsSkeleton/PersonSuiteSkeleton";
import Layout from "../Common/Containers/_layouts/Home";

const PersonSuite = lazy(() => import("./Containers/PersonSuite"));
const PersonSuiteList = lazy(() => import("./Containers/PersonSuiteList"));

function PersonSuiteModule() {
  return (
    <Layout>
      <Switch>
        <Route exact path={PERSON_SUITE_DETAIL}>
          <Suspense fallback={<PersonSuiteSkeleton fullPageSkeleton />}>
            <PersonSuite />
          </Suspense>
        </Route>
        <Route path={PERSON_SUITE}>
          <Suspense fallback={<PersonSuitListSkeleton />}>
            <PersonSuiteList />
          </Suspense>
        </Route>
        <Route path="*" render={() => <Redirect to={{ pathname: PAGE404 }} />} />
      </Switch>
    </Layout>
  );
}

export default PersonSuiteModule;
