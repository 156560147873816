export const EventType = {
  CANCEL_STATION_TIMER: "CANCEL_STATION_TIMER",
  AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
  FETCH_STATS: "FETCH_STATS",
};
export const UPDATE_TYPES = {
  OCCUPANCY_COUNT: "OCCUPANCY_COUNT",
  DETECTION: "DETECTION",
  CANCEL_STATION_TIMER: "CANCEL_STATION_TIMER",
  AVG_JOURNEY: "AVG_JOURNEY",
  VEHICLE_COUNT: "VEHICLE_COUNT",
  DEVICE_STATUS: "DEVICE_STATUS",
};

const events = EventType;

const getEvent = (type) => {
  return events[type];
};

const isEventsAvailable = (type) => {
  return getEvent(type);
};

const registerEvent = (type, fn) => {
  console.log(type, "Type1");
  if (!isEventsAvailable(type)) {
    events[type] = [];
  }

  events[type]?.push(fn);
};

const unregisterEvent = (type, fn) => {
  if (!isEventsAvailable(type)) {
    return;
  }

  events[type] = events[type]?.filter((event) => event !== fn);
};

const notify = (type, ...args) => {
  if (!isEventsAvailable(type)) {
    return;
  }

  events[type].forEach((event) => {
    if (typeof event !== "function") {
      return;
    }
    event(...args);
  });
};

const Events = {
  unregisterEvent,
  registerEvent,
  notify,
  type: EventType,
};

export default Events;
