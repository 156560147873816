import React from "react";

const IconPlay = (props) => {
  return props.playback ? (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_32751_214805)">
        <path d="M8 5V19L19 12L8 5Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_32751_214805">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.571411 0V24L19.4286 12L0.571411 0Z" fill="#545454" />
    </svg>
  );
};

export default IconPlay;
