import { useEffect, useState, useCallback } from "react";
import { API_BASE_URL, API_CONFIG_NO_MANIFEST_CALL } from "../../../../Constants";
import callApi from "../../../../Services/callApi";
import { getAllQueryVariables } from "../../../../Services/getQueryVariable";
import { modifyLocationData, updateURL } from "../Utils";

const useHandleFilter = () => {
  const { LocationId: locationId, taskId } = getAllQueryVariables();
  const [selectedLocation, setSelectedLocation] = useState({ value: "", label: "" });
  const [locationData, setLocationData] = useState(true);
  const [loadingLocationData, setLoadingLocationData] = useState(true);
  const [checklist, setChecklist] = useState("");

  const getLocationData = useCallback(async () => {
    setLoadingLocationData(true);
    const response = await callApi(
      `${API_BASE_URL}/analyse/${taskId}?location=&camera=&schedule=&raisedBy=&from=2024-08-17&to=2024-08-23`,
      {
        method: "GET",
      },
      API_CONFIG_NO_MANIFEST_CALL
    );
    if (response?.status === 200 && response?.data) {
      const { data } = response;

      const locationDataModified = modifyLocationData(data.data.location);
      setSelectedLocation(locationDataModified.find((location) => location.value === locationId));
      setLocationData(locationDataModified);
      setChecklist({ ...data?.checklist, referenceId: data?.referenceId });
    }

    setLoadingLocationData(false);
  }, [taskId]);

  useEffect(() => {
    if (taskId) {
      getLocationData();
    }
  }, []);

  useEffect(() => {
    if (selectedLocation && selectedLocation.value) {
      updateURL(selectedLocation.value);
    }
  }, [selectedLocation]);

  return {
    loadingLocationData,
    selectedLocation,
    setSelectedLocation,
    locationData,
    checklist,
  };
};

export default useHandleFilter;
