import React from "react";

function IconSmartAssistant({ size = "18", color = "white" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 3.32812C11.5097 3.32812 12.3281 2.50964 12.3281 1.5H13.1719C13.1719 2.50964 13.9903 3.32812 15 3.32812V4.17188C13.9903 4.17188 13.1719 4.99036 13.1719 6H12.3281C12.3281 4.99036 11.5097 4.17188 10.5 4.17188V3.32812ZM0.75 8.25C3.23528 8.25 5.25 6.23528 5.25 3.75H6.75C6.75 6.23528 8.76472 8.25 11.25 8.25V9.75C8.76472 9.75 6.75 11.7647 6.75 14.25H5.25C5.25 11.7647 3.23528 9.75 0.75 9.75V8.25ZM3.65701 9C4.64038 9.5457 5.45432 10.3596 6 11.343C6.54568 10.3596 7.35962 9.5457 8.343 9C7.35962 8.4543 6.54568 7.6404 6 6.65701C5.45432 7.6404 4.64038 8.4543 3.65701 9ZM12.9375 10.5C12.9375 11.8462 11.8462 12.9375 10.5 12.9375V14.0625C11.8462 14.0625 12.9375 15.1538 12.9375 16.5H14.0625C14.0625 15.1538 15.1538 14.0625 16.5 14.0625V12.9375C15.1538 12.9375 14.0625 11.8462 14.0625 10.5H12.9375Z"
        fill={color}
      />
    </svg>
  );
}
export function IconSmartAssistantAnimated({ size = "104", color = "white" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 104 104"
      fill="none"
    >
      <path
        d="M58.6666 26.7917C63.154 26.7917 66.7916 23.154 66.7916 18.6667H70.5416C70.5416 23.154 74.1793 26.7917 78.6666 26.7917V30.5417C74.1793 30.5417 70.5416 34.1794 70.5416 38.6667H66.7916C66.7916 34.1794 63.154 30.5417 58.6666 30.5417V26.7917ZM15.3333 48.6667C26.379 48.6667 35.3333 39.7124 35.3333 28.6667H42C42 39.7124 50.9543 48.6667 62 48.6667V55.3334C50.9543 55.3334 42 64.2878 42 75.3334H35.3333C35.3333 64.2878 26.379 55.3334 15.3333 55.3334V48.6667ZM28.2533 52.0001C32.6239 54.4254 36.2414 58.0427 38.6666 62.4134C41.0919 58.0427 44.7094 54.4254 49.08 52.0001C44.7094 49.5747 41.0919 45.9574 38.6666 41.5868C36.2414 45.9574 32.6239 49.5747 28.2533 52.0001ZM69.5 58.6667C69.5 64.6498 64.6496 69.5001 58.6666 69.5001V74.5001C64.6496 74.5001 69.5 79.3504 69.5 85.3334H74.5C74.5 79.3504 79.3503 74.5001 85.3333 74.5001V69.5001C79.3503 69.5001 74.5 64.6498 74.5 58.6667H69.5Z"
        fill={color}
      />
    </svg>
  );
}

export default IconSmartAssistant;
