import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./Styles.module.scss";

function SettingSSOSkeleton({ className }) {
  return (
    <div className={`${styles.container} ${className || ""}`}>
      <div className={`d-flex justify-content-between w-100 ${styles.header_wrapper}`}>
        <div className={styles.header}>
          <h1>
            <Skeleton width={220} height={30} />
          </h1>
          <p>
            <Skeleton width={400} height={15} />
          </p>
        </div>
        <Skeleton width={30} height={30} />
      </div>

      <div className="px-4">
        <div className="form-group row">
          <div className="col-3">
            <Skeleton width={200} height={20} />
          </div>
          <div className="col-6">
            <Skeleton width="100%" height={40} />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-3">
            <Skeleton width={200} height={20} />
          </div>
          <div className="col-6">
            <Skeleton width="100%" height={150} />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-3">
            <Skeleton width={200} height={20} />
          </div>
          <div className="col-6">
            <Skeleton width={100} height={40} count={4} className="mr-2" />
          </div>
        </div>
        <footer className={`d-flex justify-content-between align-items-end ${styles.footer}`}>
          <div className={styles.info}>
            <Skeleton width={500} height={40} />
          </div>
          <Skeleton width={100} height={30} />
        </footer>
      </div>
    </div>
  );
}

export default SettingSSOSkeleton;
