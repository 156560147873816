import IconBell from "../Common/Components/IconsComponent/IconCalender";
import IconCameraLine from "../Common/Components/IconsComponent/IconCameraLine";
import IconCity from "../Common/Components/IconsComponent/IconCity";
import IconLocation from "../Common/Components/IconsComponent/IconLocation";
import IconPlaySquare from "../Common/Components/IconsComponent/IconPlaySquare";
import IconRange from "../Common/Components/IconsComponent/IconRange";
import IconSearch from "../Common/Components/IconsComponent/IconSearch";
import IconRegionAndCity from "../Common/Components/IconsComponent/IconRegionAndCity";
import IconStation from "../Common/Components/IconsComponent/IconStation";
import IconTask from "../Common/Components/IconsComponent/IconTask";
import IconTime from "../Common/Components/IconsComponent/IconTime";
import IconTimeFilter from "../Common/Components/IconsComponent/IconTimeFilter";
import IconUser from "../Common/Components/IconsComponent/IconUser";
import IconOrderType from "../Common/Components/IconsComponent/IconOrderType";
import IconPaymentMode from "../Common/Components/IconsComponent/IconPaymentMode";
import IconExceptionLine from "../Common/Components/IconsComponent/IconExceptionLine";

/** ******************
 * Filter Labels
 ******************** */

// Location Label
const getLocationLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconLocation />{" "}
    </label>{" "}
    {label}{" "}
  </>
);
// City Label
const getCityLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconCity />{" "}
    </label>{" "}
    {label}{" "}
  </>
);
// Region Label
const getRegionLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconRegionAndCity />{" "}
    </label>{" "}
    {label}{" "}
  </>
);
// Recording Label
const getRecordingLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconPlaySquare />{" "}
    </label>{" "}
    {label}{" "}
  </>
);

// Camera Label
const getCameraLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconCameraLine />
    </label>{" "}
    {label}{" "}
  </>
);

// Task Label
const getTaskLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconTask />{" "}
    </label>{" "}
    {label}{" "}
  </>
);

// Time/Date Label
const getDateLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconTime />{" "}
    </label>{" "}
    {label}{" "}
  </>
);
// Time/Date Label
const getTimeLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconTimeFilter />{" "}
    </label>{" "}
    {label}{" "}
  </>
);
// Raised By Label
const getRaisedByLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconUser />{" "}
    </label>{" "}
    {label}{" "}
  </>
);
// Raised By Label
const getScheduleLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconBell />{" "}
    </label>{" "}
    {label}{" "}
  </>
);

// Station icon
const getStationLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconStation />{" "}
    </label>{" "}
    {label}{" "}
  </>
);

// Area Label
const getAreaLabel = (label) => (
  <>
    <label className="mb-0 fw-500">Area:</label> {label}{" "}
  </>
);

// Station Label
const getStationLabel = (label, type) =>
  type ? (
    <>
      <label className="mb-0 fw-500">{type}:&nbsp;</label> {label}{" "}
    </>
  ) : (
    <>
      <label className="mb-0 fw-500">Station: </label> {label}{" "}
    </>
  );
// Station Label
const getMetricLabel = (label) => (
  <>
    <label className="mb-0 fw-500">Metric:&nbsp;</label> {label}{" "}
  </>
);
// Zone label
const getZoneLabel = (label) => (
  <>
    <label className="mb-0 fw-500">Zone:</label> {label}{" "}
  </>
);
// Range Label
const getRangeLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconRange />{" "}
    </label>{" "}
    {label}
  </>
);
const getSearchLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconSearch />
    </label>{" "}
    {label}
  </>
);

const getOrderTypeLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconOrderType />{" "}
    </label>{" "}
    {label}{" "}
  </>
);
const getPaymentModeLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconPaymentMode />{" "}
    </label>{" "}
    {label}{" "}
  </>
);
const getExceptionLabelIcon = (label) => (
  <>
    <label className="mb-0 mr-1">
      <IconExceptionLine />{" "}
    </label>{" "}
    {label}{" "}
  </>
);
export {
  getAreaLabel,
  getCameraLabelIcon,
  getCityLabelIcon,
  getDateLabelIcon,
  getLocationLabelIcon,
  getRaisedByLabelIcon,
  getRangeLabelIcon,
  getRegionLabelIcon,
  getRecordingLabelIcon,
  getScheduleLabelIcon,
  getSearchLabelIcon,
  getStationLabel,
  getStationLabelIcon,
  getTaskLabelIcon,
  getTimeLabelIcon,
  getZoneLabel,
  getOrderTypeLabelIcon,
  getPaymentModeLabelIcon,
  getExceptionLabelIcon,
  getMetricLabel,
};
