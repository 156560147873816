import React, { lazy } from "react";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import LiveMonitoringComponent from "../../Components/LiveMonitoringComponent";

function LiveMonitoring() {
  return (
    <PageContainer initialPageTitle={PAGE_TITLES.taskDetails} withLayout>
      <LiveMonitoringComponent />
    </PageContainer>
  );
}

export default LiveMonitoring;
