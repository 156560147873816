import React, { Suspense, lazy } from "react";
import ErrorPageSkeleton from "../Components/SkeletonComponent/CommonSkeleton/ErrorPageSkeleton";

const Page404Component = lazy(() => import("../Components/ErrorPagesComponents/page404Component"));

const page404 = () => (
  <Suspense fallback={<ErrorPageSkeleton />}>
    <Page404Component />
  </Suspense>
);

export default page404;
