import PropTypes from "prop-types";
import React from "react";

function IconCameraGridList({ color }) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33333 4.66667V7.33333H4.66667V4.66667H7.33333ZM8.66667 4.66667H12V7.33333H8.66667V4.66667ZM7.33333 12H4.66667V8.66667H7.33333V12ZM8.66667 12V8.66667H12V11.3333C12 11.7015 11.7015 12 11.3333 12H8.66667ZM7.33333 0V3.33333H4.66667V0H7.33333ZM8.66667 0H11.3333C11.7015 0 12 0.29848 12 0.666667V3.33333H8.66667V0ZM3.33333 4.66667V7.33333H0V4.66667H3.33333ZM3.33333 12H0.666667C0.29848 12 0 11.7015 0 11.3333V8.66667H3.33333V12ZM3.33333 0V3.33333H0V0.666667C0 0.29848 0.29848 0 0.666667 0H3.33333Z"
        fill={color}
      />
    </svg>
  );
}
IconCameraGridList.propTypes = {
  color: PropTypes.string,
};

IconCameraGridList.defaultProps = {
  color: "#3766e8",
};
export default IconCameraGridList;

// #545454
// Grid icon color #3766E8
