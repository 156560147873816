import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import IconCorrect from "../../../../Common/Components/IconsComponent/IconCorrect";
import ModalBlock from "../../../../Common/Components/Molecule/ModalBlock";
import VerificationModalSkeleton from "../../../../Common/Components/SkeletonComponent/MainModulesSkeleton/DriveThruSkeleton/VerificationModalSkeleton";
import { API_BASE_URL } from "../../../../Constants";
import callApi from "../../../../Services/callApi";
import { colourStyles } from "../../../../Services/colourStyles";
import { showAlert } from "../../../../Services/showAlert";
import StationColumn from "./StationColumn";
import Styles from "./StyleVerificationModal.module.scss";

const pageOptions = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 4, value: 4 },
];

function VehiclesVerificationModal(props) {
  const [allJourneyData, setAllJourneyData] = useState({});
  const [selectedCars, setSelectedCars] = useState([]);
  const [sameCam, setSameCam] = useState([]);
  const [stationsLength, setStationsLength] = useState(0);

  function RenderColumnHead({ stations }) {
    const formattedStations = stations?.flatMap((station, i) => {
      if (sameCam?.length && sameCam[i] === true) {
        return [`${station} IN & OUT`];
      }
      return [`${station} IN`, `${station} OUT`];
    });
    setStationsLength(formattedStations.length);
    return formattedStations ? (
      formattedStations?.map((ele, index) => (
        <div key={index} className={`${Styles.column_container}`}>
          <div className={`${Styles.header_block}`}>
            <h6 className="mb-0">
              {" "}
              {ele}{" "}
              <span>
                <IconCorrect colour={GetColor(ele)} />
              </span>
            </h6>
          </div>
        </div>
      ))
    ) : (
      <></>
    );
  }

  // ----compares if entry and exit cameras of a station are same------//
  const compareStationCamera = (ele) => {
    if (allJourneyData[ele]) {
      if (allJourneyData[ele].entry.length && allJourneyData[ele].exit.length) {
        const entryId = allJourneyData[ele].entry[0].camera;
        const exitId = allJourneyData[ele].exit[0].camera;

        return entryId === exitId;
      }
    }
  };
  useEffect(() => {
    const result = props?.journeyData?.stations?.map((ele) => compareStationCamera(ele));
    setSameCam(result);
  }, [allJourneyData]);
  // o.lp === props?.updateCar?.Vnumber

  const GetColor = (stationType) => {
    const newstationType = stationType.split(" ");

    if (allJourneyData && allJourneyData[stationType[0]]) {
      const type = newstationType[1] === "IN" ? "entry" : "exit";
      const arr = allJourneyData[newstationType[0]][type]?.map((ele, i) => ele?.isSelected);
      if (arr.includes(true) === true) {
        return "#28a745";
      }
      return "#CED4DA";
    }
  };

  const handleSelect = (station, type, id, groupId) => {
    const arr = [];
    selectedCars.map((el) => {
      arr.push(el?._id);
    });
    // if (stationIndex === 0) {
    let newData = JSON.parse(JSON.stringify(allJourneyData));
    newData = {
      ...newData,
      [station]: {
        ...newData[station],
        [type]: newData[station][type].map((ele, index) => {
          if (ele.type === type) {
            if (ele.isSelected === true) {
              return { ...ele, isSelected: false };
            }
            return ele;
          }
        }),
      },
    };

    newData = {
      ...newData,
      [station]: {
        ...newData[station],
        [type]: newData[station][type].map((ele, index) => {
          if (ele.type === type) {
            if (ele._id === id) {
              if (arr.includes(id)) {
                // setPrevId("");
                return { ...ele, isSelected: false };
              }
              // setPrevId(id);
              return { ...ele, isSelected: true };
            }
            return ele;
          }
          return ele;
        }),
      },
    };

    if (compareStationCamera(station)) {
      const entryCar = newData[station][type].find((ele) => ele._id === id);

      newData = {
        ...newData,
        [station]: {
          ...newData[station],
          exit: newData[station].exit.map((ele, index) => ({
            ...ele,
            isSelected: false,
          })),
        },
      };
      if (entryCar.isSelected === true) {
        newData = {
          ...newData,
          [station]: {
            ...newData[station],
            exit: newData[station].exit.map((ele, index) => {
              if (ele?.groupId === groupId) {
                return { ...ele, isSelected: true };
              }

              return ele;
            }),
          },
        };
      }
    }

    setAllJourneyData(newData);
    // }
  };

  useEffect(() => {
    let selected = [];
    if (allJourneyData && Object.keys(allJourneyData).length !== 0) {
      props?.journeyData?.stations?.map((ele) => {
        selected = [...selected, allJourneyData[ele].entry?.find((ele) => ele.isSelected === true)];
        selected = [...selected, allJourneyData[ele].exit.find((ele) => ele.isSelected === true)];
      });
      if (selected && selected?.length) {
        setSelectedCars(selected.filter((ele) => ele !== undefined));
      }
    }
  }, [allJourneyData]);

  useEffect(() => {
    setAllJourneyData(props?.journeyData?.data);
  }, [props?.visibility, props?.journeyData]);
  // -----Adds scroll to the items-------//
  useEffect(() => {
    const addScroll = async () => {
      if (props?.journeyData?.stations && props?.journeyData?.stations?.length) {
        for (const ele of props?.journeyData?.stations) {
          const entryElement = document?.getElementById(`selectedId_${ele}_entry`);
          const exitElement = document?.getElementById(`selectedId_${ele}_exit`);
          await new Promise((resolve) => setTimeout(resolve, 500));
          entryElement?.scrollIntoView({
            behavior: "smooth",
          });
          await new Promise((resolve) => setTimeout(resolve, 500));
          exitElement?.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    };

    addScroll();
  }, [allJourneyData]);

  const updateCarData = () => {
    const arr = [];
    const selectedJouneyId = props?.journeyData?.data[props?.journeyData?.stations[0]].entry.find(
      (ele) => ele.isSelected === true
    )?.journeyId;

    selectedCars.map((el) => {
      arr.push(el?._id);
    });
    const url = `${API_BASE_URL}/customer/verify/journey/${selectedJouneyId}`;
    const data = {
      stations: arr,
    };
    try {
      callApi(
        url,
        {
          method: "POST",
          body: JSON.stringify(data),
        },
        {
          showLoader: true,
          callManifest: false,
          loaderLabel: "Updating selected cars",
        }
      )
        .then((res) => {
          if (res.status === 200) {
            showAlert(res.message);
            props?.onHide();
            props?.getVehiclesData();
          }
        })
        .catch((e) => {
          showAlert(e, "error");
        });
    } catch (e) {
      showAlert(e, "error");
    }
  };
  const headerButtons = useMemo(
    () => [
      {
        name: "Update Journey",
        onClick: updateCarData,
        classes: ["btn btn-primary btn-md"],
        isPrimary: true,
        isHidden: props?.journeyData?.stations?.length < 1,
      },
    ],
    [props?.journeyData, updateCarData]
  );

  const renderPageOptions = useMemo(
    () => (
      <li className={`${"list-inline-item"}`}>
        <Select
          options={pageOptions}
          value={{
            value: props?.selectedLimit?.value,
            label: `Limit: ${props?.selectedLimit?.label}`,
          }}
          onChange={props?.setSelectedLimit}
          className="m-0"
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={colourStyles}
        />
      </li>
    ),
    [props?.setSelectedLimit, props?.selectedLimit]
  );

  return (
    <ModalBlock
      modalSize="3xl"
      isOpen={props?.visibility}
      onHide={() => {
        props?.onHide();
      }}
      className="zIndex-handel"
      modalTitle={`${props?.updateCar?.Vnumber} Journey Update`}
      titleBottomElement={false}
      primaryButton={props?.journeyData?.stations?.length > 1}
      onClickPrimary={() => updateCarData()}
      primaryBtnText="Update Journey"
      modalFooter={false}
      configBlock
      selectedLimit={props?.selectedLimit}
      setSelectedLimit={props?.setSelectedLimit}
    >
      {props?.loading ? (
        <VerificationModalSkeleton height={330} className="mb-4" />
      ) : (
        <div
          className={`container ${Styles.block_column_wrapper} ${
            stationsLength === 2 ? Styles.block_column_wrapper_helper : ""
          }`}
        >
          <div
            className={`${Styles.block_column_flex} ${
              props?.journeyData?.stations?.length === 1 ? "col-md-3" : ""
            }`}
          >
            {/* Container Item */}
            <RenderColumnHead stations={props?.journeyData?.stations} />
          </div>
          {/* <RenderColumn /> */}
          <div
            className={`${Styles.block_column_flex} ${
              props?.journeyData?.stations?.length === 1 &&
              compareStationCamera(props?.journeyData?.stations[0])
                ? "col-md-3"
                : ""
            }`}
          >
            {props?.journeyData?.stations?.map((ele, index) => (
              <>
                {" "}
                <div key={`${ele}_entry_${index}`} className={`${Styles.column_container} `}>
                  <div className={` ${Styles.image_wrapper_list}`}>
                    <StationColumn
                      updateCar={props?.updateCar}
                      station={ele}
                      stationType="entry"
                      data={allJourneyData[ele]?.entry}
                      stationData={allJourneyData[ele]}
                      stationIndex={index}
                      handleSelect={handleSelect}
                      setOpenLightBox={props?.setOpenLightBox}
                      setImageList={props?.setImageList}
                      compareStationCamera={compareStationCamera(ele)}
                      setSelectedStationType={props?.setSelectedStationType}
                      sameCam={sameCam && sameCam.length && !sameCam[index]}
                    />
                  </div>
                </div>
                {sameCam && sameCam.length && !sameCam[index] ? (
                  <div key={`${ele}_exit_${index}`} className={`${Styles.column_container} `}>
                    <ul className={` ${Styles.image_wrapper_list}`}>
                      <StationColumn
                        updateCar={props?.updateCar}
                        data={allJourneyData[ele]?.exit}
                        station={ele}
                        stationIndex={index}
                        stationType="exit"
                        stationData={allJourneyData[ele]}
                        handleSelect={handleSelect}
                        setOpenLightBox={props?.setOpenLightBox}
                        setImageList={props?.setImageList}
                        compareStationCamera={compareStationCamera(ele)}
                        setSelectedStationType={props?.setSelectedStationType}
                        sameCam={sameCam && sameCam.length && !sameCam[index]}
                      />
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>

          {/* END block_column_flex */}
        </div>
      )}
    </ModalBlock>
  );
}

export default VehiclesVerificationModal;
