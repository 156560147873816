import { ADDALERTDATA } from "../constants";

const initialState = null;

export default function alertDataReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADDALERTDATA:
      return action.value;
    default:
      return state;
  }
}
