import React from "react";

function IconCamera() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 17.0401C14.1208 17.0401 15.84 15.3209 15.84 13.2001C15.84 11.0793 14.1208 9.36011 12 9.36011C9.8792 9.36011 8.15997 11.0793 8.15997 13.2001C8.15997 15.3209 9.8792 17.0401 12 17.0401Z"
        fill="#545454"
      />
      <path
        d="M21.6 3.59997H17.796L15.6 1.19995H8.39997L6.20398 3.59997H2.40002C1.07398 3.59997 0 4.67395 0 5.99999V20.4C0 21.726 1.07398 22.8 2.40002 22.8H21.6C22.926 22.8 24 21.726 24 20.4V5.99999C24 4.67395 22.926 3.59997 21.6 3.59997ZM12 19.1999C8.68797 19.1999 5.99996 16.5119 5.99996 13.1999C5.99996 9.88793 8.68797 7.19997 12 7.19997C15.312 7.19997 18 9.88798 18 13.2C18 16.512 15.312 19.1999 12 19.1999Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconCamera;
