import React from "react";

function IconExpandView() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6668 2H14.6668V6H13.3335V3.33333H10.6668V2ZM1.3335 2H5.3335V3.33333H2.66683V6H1.3335V2ZM13.3335 12.6667V10H14.6668V14H10.6668V12.6667H13.3335ZM2.66683 12.6667H5.3335V14H1.3335V10H2.66683V12.6667Z"
        fill="white"
      />
    </svg>
  );
}

export default IconExpandView;
