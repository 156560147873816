import React from "react";
import BlocksComponent from "../../../../Common/Components/Molecule/Block";
import BlockHeader from "../../../../Common/Components/Molecule/Block/BlockHeader";
import DataList from "../../../../Common/Components/Molecule/DataList";
import styles from "../style.module.scss";
import IconIn from "../Assets/IconIn";
import IconOut from "../Assets/IconOut";
import TaskTypeTag from "../../../../Common/Components/Molecule/TaskTypeTag";
import { TASK_TYPES } from "../../../../Constants";
import { getStatsOccupancyClass } from "../Utils";

export default function DetailDataBlock({ monitoringData, checklist, selectedLocation }) {
  const { locationInsight, cameraInsight } = monitoringData;
  const noCameraInsights = cameraInsight.length === 0;
  const currentOccupancyTotal = cameraInsight.reduce((acc, zone) => {
    return zone.entry - zone.exit + acc;
  }, 0);
  const taskDetailsList = [
    {
      dataLabel: "Task Type:",
      dataString: (
        <TaskTypeTag tagType={TASK_TYPES.OCCUPANCY_BASED} tagTypeLabel={"Occupancy Based"} />
      ),
    },
    { dataLabel: "Checklist:", dataString: checklist?.model },
    { dataLabel: "Location:", dataString: selectedLocation?.label },
  ];
  return (
    <div className={styles.DetailsContainer}>
      {" "}
      <BlocksComponent className={`${styles.taskDetails} element-section mb-0 panel-fh`}>
        <div className="element-section mb-0 panel-fh ">
          <BlockHeader>
            <h5 className="element-title-h5">{locationInsight?.metadata?.task}</h5>
          </BlockHeader>
          <tbody className="data-table-list">
            {taskDetailsList.map((data, index) => (
              <DataList
                key={index}
                dataLabel={data.dataLabel}
                dataString={data.dataString}
                dataStringClassName={styles.data_string_bold}
              />
            ))}
          </tbody>
        </div>
      </BlocksComponent>
      <BlocksComponent
        className={` ${getStatsOccupancyClass(
          currentOccupancyTotal,
          locationInsight?.locationOptimalRange
        )}
         element-section mb-0 panel-fh`}
      >
        <>
          <div className={styles.currentTotalHeader}>
            <p className={styles.title}>Total</p>{" "}
            <p className={styles.max}>
              Max Allowed:{" "}
              <span>{noCameraInsights ? "N/A" : locationInsight?.locationOptimalRange}</span>
            </p>
          </div>

          <p className={styles.currentTotal}>{noCameraInsights ? "N/A" : currentOccupancyTotal}</p>
          <p className={styles.currentAvg}>.{/* Last 30 mins average: <span>41</span> */}</p>
        </>
      </BlocksComponent>
      <BlocksComponent className={`${styles.todaysTotalDetails} element-section mb-0 panel-fh`}>
        <>
          <p className={styles.uptoNow}>Today’s Total</p>

          <div className={styles.inOutDataWrapper}>
            <div className={styles.inOutData}>
              <p>
                {" "}
                <IconIn />
                IN
              </p>
              <p>{noCameraInsights ? "N/A" : locationInsight?.entryTotalCount}</p>
            </div>
            <div className={styles.inOutData}>
              <p>
                <IconOut />
                OUT
              </p>
              <p>{noCameraInsights ? "N/A" : locationInsight?.exitTotalCount}</p>
            </div>
          </div>
        </>
      </BlocksComponent>
    </div>
  );
}
