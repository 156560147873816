import { API_BASE_URL, API_CONFIG_SHOWLOADER } from "../Constants";
import callApi from "./callApi";

export default async function fetchCameraOfflineAlertEmailList({ locationId }) {
  const response = await callApi(
    `${API_BASE_URL}/camera/offline/emails/${locationId}`,
    {
      method: "GET",
    },
    API_CONFIG_SHOWLOADER
  );

  if (response.status !== 200) {
    throw response;
  }

  return response;
}
