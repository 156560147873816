import React, { lazy, Suspense } from "react";
import LoginSkeleton from "../Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const SuccessComponent = lazy(
  () => import("../Components/ForgotPasswordComponent/SuccessComponent")
);

function Success(props) {
  return (
    <Suspense fallback={<LoginSkeleton />}>
      <SuccessComponent user={props?.history?.location?.state} />
    </Suspense>
  );
}

export default Success;
