import drawPolygon from "./drawPolygon";

const drawImage = (imageSrc, coordinates, canvasRef, obscureData) => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext("2d");

  const image = new Image();
  image.src = imageSrc;

  const drawImageOnCanvas = () => {
    const canvasAspectRatio = canvas.width / canvas.height;
    const imageAspectRatio = image.width / image.height;

    let drawWidth;
    let drawHeight;
    let drawX;
    let drawY;

    if (canvasAspectRatio > imageAspectRatio) {
      drawWidth = canvas.width;
      drawHeight = canvas.width / imageAspectRatio;
      drawX = 0;
      drawY = (canvas.height - drawHeight) / 2;
    } else {
      drawWidth = canvas.height * imageAspectRatio;
      drawHeight = canvas.height;
      drawX = (canvas.width - drawWidth) / 2;
      drawY = 0;
    }

    if (obscureData) {
      ctx.filter = "blur(6px)";
    } else {
      ctx.filter = "none";
    }

    ctx.drawImage(image, drawX, drawY, drawWidth, drawHeight);

    ctx.filter = "none";
  };

  image.onload = () => {
    const { width, height } = image;
    canvas.width = width;
    canvas.height = height;
    drawPolygon(ctx, coordinates, canvas, image, drawImageOnCanvas);
  };
};

export default drawImage;
