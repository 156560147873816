import React, { lazy, Suspense } from "react";
import LoginSkeleton from "../Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const AddTeamComponent = lazy(() => import("../Components/SignupComponent/AddTeamComponent"));

function AddTeam() {
  return (
    <Suspense
      fallback={<LoginSkeleton width="60vw" height={460} titleHeight={100} titleWidth="60vw" />}
    >
      <AddTeamComponent />
    </Suspense>
  );
}

export default AddTeam;
