import React from "react";

const IconDriveThruPickup = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 17.3332H1.33333C1.11232 17.3332 0.900358 17.2454 0.744078 17.0891C0.587797 16.9328 0.5 16.7208 0.5 16.4998V1.49984C0.5 1.27882 0.587797 1.06686 0.744078 0.910582C0.900358 0.754301 1.11232 0.666504 1.33333 0.666504H14.6667C14.8877 0.666504 15.0996 0.754301 15.2559 0.910582C15.4122 1.06686 15.5 1.27882 15.5 1.49984V16.4998C15.5 16.7208 15.4122 16.9328 15.2559 17.0891C15.0996 17.2454 14.8877 17.3332 14.6667 17.3332ZM13.8333 15.6665V2.33317H2.16667V15.6665H13.8333ZM5.5 3.99984V5.6665C5.5 6.32955 5.76339 6.96543 6.23223 7.43427C6.70107 7.90311 7.33696 8.1665 8 8.1665C8.66304 8.1665 9.29893 7.90311 9.76777 7.43427C10.2366 6.96543 10.5 6.32955 10.5 5.6665V3.99984H12.1667V5.6665C12.1667 6.77157 11.7277 7.83138 10.9463 8.61278C10.1649 9.39418 9.10507 9.83317 8 9.83317C6.89493 9.83317 5.83512 9.39418 5.05372 8.61278C4.27232 7.83138 3.83333 6.77157 3.83333 5.6665V3.99984H5.5Z"
        fill="black"
      />
    </svg>
  );
};

export default IconDriveThruPickup;
