import React, { lazy, Suspense } from "react";
import Helmet from "react-helmet";
import Layout from "./_layouts/Home";
import { checkWobotDomain, PAGE_TITLES } from "../../Services/checkWobotDomain";
import { home } from "../../Constants/MetaDetails";
import HomeSkeleton from "../Components/SkeletonComponent/MainModulesSkeleton/HomeSkeleton";

const HomeComponent = lazy(() => import("../Components/HomeComponent"));

function Home() {
  return (
    <Layout>
      <Helmet>
        <title>{checkWobotDomain(PAGE_TITLES.home)}</title>
        <meta name="description" content={home?.subtitle} />
      </Helmet>
      <Suspense fallback={<HomeSkeleton />}>
        <HomeComponent />
      </Suspense>
    </Layout>
  );
}

export default Home;
