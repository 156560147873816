import {
  ADD_DEVICE_DETAILS,
  ADD_DEVICE_STEPS,
  ADD_NEW_LOCATION,
  CLEAR_DEVICE_FORM_DATA,
  DEVICE_OFFLINE_EMAIL_LIST,
  DISABLED_FIELDS,
  EDIT_OTHER_DETAIL,
  INITIALIZE_FORM_OPTIONS,
  INITIATE_LOCAL_CAMERA_FORM,
  IS_REFRESH,
  REDIRECT_ON_SAVE,
  RESET_SUBMIT_FORM,
  SETUP_WO_CONNECT,
  SET_ADDED_DEVICE_DATA,
  SET_CONNECTED_CAMERAS,
  SET_CONNECTED_CAMERAS_ERROR,
  SET_DEVICE_TYPE,
  SET_EDITING_MODE,
  SET_EDIT_TYPE,
  SET_ERROR,
  SET_FORM_STEP,
  SET_IS_GLOBAL_SUBMIT,
  SET_SUB_STEPS,
  SET_WO_CONNECT_DETAILS,
  SET_WO_CONNECT_VERIFY_DETAILS,
  SHOW_BACK_BUTTON,
  SHOW_RTSP_AUTH_MODAL,
  SUBMIT_NEW_DEVICE_FORM,
  UPDATED_SELECTED_CAMERAS,
  UPDATE_DEVICE_TYPES_SUB_STEP,
  UPDATE_ERROR_FIELDS,
  UPDATE_FORM_OPTIONS,
  UPDATE_OTHER_DETAILS,
  UPDATE_TECHNICAL_DETAILS,
} from ".";

export const initiateInitialFormData = (storeState) => ({
  type: INITIALIZE_FORM_OPTIONS,
  payload: storeState,
});

export const updateFormOptions = (updates) => ({
  type: UPDATE_FORM_OPTIONS,
  payload: updates,
});
export const setIsGlobalSubmit = (state) => ({
  type: SET_IS_GLOBAL_SUBMIT,
  payload: state,
});

export const clearAddNewDeviceFormData = (storeState) => ({
  type: CLEAR_DEVICE_FORM_DATA,
  payload: storeState,
});

export const setAddedDeviceData = (data) => ({
  type: SET_ADDED_DEVICE_DATA,
  payload: data,
});

export const setAddNewDeviceFormStep = (step) => ({
  type: SET_FORM_STEP,
  payload: step,
});

export const setAddDeviceNewSubStep = (subStep) => ({
  type: UPDATE_DEVICE_TYPES_SUB_STEP,
  payload: subStep,
});

export const setAddNewDeviceErrorFields = (errorFields) => ({
  type: UPDATE_ERROR_FIELDS,
  payload: errorFields,
});

export const setNewDeviceType = (deviceType) => ({
  type: SET_DEVICE_TYPE,
  payload: deviceType,
});
export const setErrors = (payload) => ({
  type: SET_ERROR,
  payload,
});
export const setWoConnectDetails = (loading = false, data = {}, error = null) => ({
  type: SET_WO_CONNECT_DETAILS,
  payload: { loading, data, error },
});

export const setWoConnectVerifyDetails = (loading = false, data = {}, error = null) => ({
  type: SET_WO_CONNECT_VERIFY_DETAILS,
  payload: {
    loading,
    isVerified: data?.connectedToDevice,
    error,
  },
});

// payload: { fieldName, fieldValue }
export const updateTechnicalDetails = (payload) => ({
  type: UPDATE_TECHNICAL_DETAILS,
  payload,
});

export const setConnectedCameras = (loading, cameras, error) => ({
  type: SET_CONNECTED_CAMERAS,
  payload: { loading, error, cameras },
});
export const setConnectedCamerasError = (error) => ({
  type: SET_CONNECTED_CAMERAS_ERROR,
  payload: error,
});
export const setSubSteps = (subStep) => ({
  type: SET_SUB_STEPS,
  payload: subStep,
});

/**
 * payload: selectedAll, selectedCameras
 */
export const updateSelectedCameras = (payload) => ({
  type: UPDATED_SELECTED_CAMERAS,
  payload,
});

// payload: { fieldName, fieldValue }
export const updateOtherDetails = (payload) => ({
  type: UPDATE_OTHER_DETAILS,
  payload,
});

export const submitNewDeviceForm = (payload) => ({
  type: SUBMIT_NEW_DEVICE_FORM,
  payload,
});
export const setIsEditOtherDetails = (payload) => ({
  type: EDIT_OTHER_DETAIL,
  payload,
});
export const resetSubmitFormState = () => ({
  type: RESET_SUBMIT_FORM,
});
export const disableFields = (payload) => ({
  type: DISABLED_FIELDS,
  payload,
});
export const setDeviceOfflineAlertEmailList = (payload) => ({
  type: DEVICE_OFFLINE_EMAIL_LIST,
  payload,
});

export const initiateLocalSetup = () => ({
  type: INITIATE_LOCAL_CAMERA_FORM,
});
export const setEditingMode = (payload) => ({
  type: SET_EDITING_MODE,
  payload,
});
export const setupWoConnect = (payload) => ({
  type: SETUP_WO_CONNECT,
  payload,
});
export const addDeviceDetails = (payload) => ({
  type: ADD_DEVICE_DETAILS,
  payload,
});
export const handleToggleAddLocationModal = (payload) => ({
  type: ADD_NEW_LOCATION,
  payload,
});
export const handleRedirectOnSave = (payload) => ({
  type: REDIRECT_ON_SAVE,
  payload,
});
export const handleShowRtspAuthModal = (payload) => ({
  type: SHOW_RTSP_AUTH_MODAL,
  payload,
});
export const handleRefresh = (payload) => ({
  type: IS_REFRESH,
  payload,
});
export const handleShowBackButton = () => ({
  type: SHOW_BACK_BUTTON,
});
export const setEditType = (payload) => ({
  type: SET_EDIT_TYPE,
  payload,
});
export const getPreviousStep = (step) => {
  switch (step) {
    case ADD_DEVICE_STEPS.SELECT_DEVICE_TYPE:
    case ADD_DEVICE_STEPS.ADD_TECHNICAL_DETAILS:
      return ADD_DEVICE_STEPS.SELECT_DEVICE_TYPE;

    case ADD_DEVICE_STEPS.OTHER_DETAILS:
      return ADD_DEVICE_STEPS.ADD_TECHNICAL_DETAILS;

    default:
      return ADD_DEVICE_STEPS.SELECT_DEVICE_TYPE;
  }
};

export const getNextStep = (step) => {
  switch (step) {
    case ADD_DEVICE_STEPS.SELECT_DEVICE_TYPE:
      return ADD_DEVICE_STEPS.ADD_TECHNICAL_DETAILS;

    case ADD_DEVICE_STEPS.ADD_TECHNICAL_DETAILS:
      return ADD_DEVICE_STEPS.OTHER_DETAILS;

    default:
      return step;
  }
};

// Helper function to get locations based on the key
export const getLocations = (key, regions) => {
  const region = regions?.find((r) => r?.id === key);

  if (region) {
    return {
      label: region?.area,
      parent: region?.parent,
      value: region?.id,
    };
  }

  return {};
};

// Helper function to set selected field value in the store
export const setSelectedFieldValue = ({ key, value, dispatch, getState }) => {
  dispatch(
    updateTechnicalDetails({
      fieldName: key,
      fieldValue: value,
      storeState: getState(),
    })
  );
};

// Helper function to set location and region in the store
export const setLocationAndRegion = async ({ city, location, regions, dispatch, getState }) => {
  setSelectedFieldValue({
    key: "region",
    value: getLocations(city, regions),
    dispatch,
    getState,
  });
  setSelectedFieldValue({
    key: "location",
    value: getLocations(location, regions),
    dispatch,
    getState,
  });
};

// Helper function to set camera name in the store
export const setCameraName = ({ name, dispatch, getState }) => {
  setSelectedFieldValue({
    key: "cameraName",
    value: name,
    dispatch,
    getState,
  });
};

// Helper function to set manufacturer name in the store
export const setManufacturerName = ({ manufacturer, dispatch, getState }) => {
  setSelectedFieldValue({
    key: "manufacturerName",
    value: { label: manufacturer, value: manufacturer },
    dispatch,
    getState,
  });
};
