import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";

function PlaybackSkeleton() {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <PageTitleSkeleton />

        <div className="list-inline-item">
          <Skeleton width={500} height={40} />
        </div>

        <div className="panel-body mt-60">
          <div className="skeleton-grid row mb-4">
            <div className="col-md-3">
              <Skeleton width="100%" height={600} />
            </div>
            <div className="col-md-9">
              <Skeleton width="100%" height={600} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlaybackSkeleton;
