import React from "react";

const IconDVR = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.8182 2.18188H2.18184C0.976347 2.18188 0 3.15823 0 4.36367V17.4546C0 18.6601 0.976347 19.6364 2.18184 19.6364H7.6364V21.8183H16.3636V19.6364H21.8182C23.0237 19.6364 23.9891 18.6601 23.9891 17.4546L24 4.36367C24 3.15823 23.0237 2.18188 21.8182 2.18188ZM21.8182 17.4546H2.18184V4.36367H21.8182V17.4546ZM19.6364 7.6364H7.63635V9.81824H19.6364V7.6364ZM19.6364 12.0001H7.63635V14.1819H19.6364V12.0001ZM6.54546 7.6364H4.36362V9.81824H6.54546V7.6364ZM6.54546 12.0001H4.36362V14.1819H6.54546V12.0001Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconDVR;
