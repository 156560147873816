import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { settings } from "../../../Constants/MetaDetails";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";

const CompanyComponent = lazy(() => import("../../Components/CompanyDetailsComponent"));

function CompanyComponentContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.companyDetails}
      meta={settings?.company?.subtitle}
      withLayout={false}
    >
      <CompanyComponent />
    </PageContainer>
  );
}

export default CompanyComponentContainer;
