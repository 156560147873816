import React from "react";

const IconFullArrowRight = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.781 7.33327L7.20503 3.75726L8.14783 2.81445L13.3333 7.99993L8.14783 13.1853L7.20503 12.2425L10.781 8.6666H2.66663V7.33327H10.781Z"
        fill="#3766E8"
      />
    </svg>
  );
};

export default IconFullArrowRight;
