import React from "react";

function IconPublish() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.74985 7.50019L3.74976 14.2501H5.24976L5.24983 9.00019L10.9394 9.00012V13.0608L15.75 8.25012L10.9394 3.43945V7.50012L3.74985 7.50019Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconPublish;
