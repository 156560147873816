import React from "react";

const IconChecklistLine = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66675 4C1.11341 4 0.666748 4.44667 0.666748 5C0.666748 5.55333 1.11341 6 1.66675 6C2.22008 6 2.66675 5.55333 2.66675 5C2.66675 4.44667 2.22008 4 1.66675 4ZM1.66675 0C1.11341 0 0.666748 0.446667 0.666748 1C0.666748 1.55333 1.11341 2 1.66675 2C2.22008 2 2.66675 1.55333 2.66675 1C2.66675 0.446667 2.22008 0 1.66675 0ZM1.66675 8C1.11341 8 0.666748 8.45333 0.666748 9C0.666748 9.54667 1.12008 10 1.66675 10C2.21341 10 2.66675 9.54667 2.66675 9C2.66675 8.45333 2.22008 8 1.66675 8ZM3.66675 9.66667H13.0001V8.33333H3.66675V9.66667ZM3.66675 5.66667H13.0001V4.33333H3.66675V5.66667ZM3.66675 0.333333V1.66667H13.0001V0.333333H3.66675Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconChecklistLine;
