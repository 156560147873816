import React from "react";

const IconAddNew = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3335 11V7H13.3335V11H17.3335V13H13.3335V17H11.3335V13H7.3335V11H11.3335ZM12.3335 22C6.81065 22 2.3335 17.5228 2.3335 12C2.3335 6.47715 6.81065 2 12.3335 2C17.8563 2 22.3335 6.47715 22.3335 12C22.3335 17.5228 17.8563 22 12.3335 22ZM12.3335 20C16.7518 20 20.3335 16.4183 20.3335 12C20.3335 7.58172 16.7518 4 12.3335 4C7.91522 4 4.3335 7.58172 4.3335 12C4.3335 16.4183 7.91522 20 12.3335 20Z"
        fill="#3766E8"
      />
    </svg>
  );
};

export default IconAddNew;
