import { io } from "socket.io-client";
import { getHeaders } from "../../../Services/getHeaders";
import { store } from "../../../Store";

let socket;

const getSocketServerConfig = () => getHeaders();
const socketServerUrl =
  process.env.REACT_APP_SOCKET_BASE_URL || "https://wosocket-staging.wobot.ai";
const getClientSocket = () => {
  const config = getSocketServerConfig();

  if (socket) {
    return socket;
  }
  const userId = store?.getState()?.userData?.user.id;
  const options = {
    path: "/socket",
    auth: {
      token: config.token,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      deviceId: config["device-id"],
      deviceType: "browser",
    },
    query: {
      userId,
      deviceUserType: "web",
      transports: ["websocket", "polling"],
    },
  };

  if (socketServerUrl) {
    socket = io(socketServerUrl, options);
  } else {
    socket = io(options);
  }

  return socket;
};

export default getClientSocket;
