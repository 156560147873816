import moment from "moment";
import { GetDateRangeList } from "./GetDateRangeList";

export const QUERY_PARAMS_KEYS = {
  REGION: "region",
  CITY: "city",
  LOCATION: "location",
  USECASE_TYPE: "usecaseType",
  TASK: "task",
  IS_DIRECT_RECORDING: "isDirectRecording",
  DVR: "dvr",
  STATUS: "status",
  CHECK_LIST: "checklist",
  TASK_TYPE: "taskType",
  MODEL_TASK_TYPE: "modelType",
  ASSIGNEE: "assignee",
  ROLE: "role",
  SCHEDULE: "schedule",
  START_DATE: "startDate",
  END_DATE: "endDate",
  FROM: "from",
  TO: "to",
  CAMERA: "camera",
};

export const checkIsQueryParamsForFilter = (queryParams) => {
  let isQueryParamsForFilter = false;
  for (let key in QUERY_PARAMS_KEYS) {
    if (queryParams.get(QUERY_PARAMS_KEYS[key])) {
      isQueryParamsForFilter = true;
      break;
    }
  }
  return isQueryParamsForFilter;
};

export class FilterQueryParams {
  constructor(
    userData,
    queryParams,
    employeeData,
    scheduleData,
    searchQueryFunction,
    cameraList,
    taskList
  ) {
    this.userData = userData;
    this.queryParams = queryParams;
    this.employeeData = employeeData;
    this.scheduleData = scheduleData;
    this.cameraList = cameraList;
    this.taskList = taskList;
  }

  dateRangeList = GetDateRangeList(Intl.DateTimeFormat()?.resolvedOptions()?.timeZone);

  setSubregionFilter(filterSetter) {
    if (
      this.queryParams.get(QUERY_PARAMS_KEYS.REGION) &&
      this.queryParams.get(QUERY_PARAMS_KEYS.CITY)
    ) {
      filterSetter({
        label: this.userData.manifest.regions.find(
          (e) => this.queryParams.get(QUERY_PARAMS_KEYS.CITY) === e._id
        )?.area,
        value: this.userData.manifest.regions.find(
          (e) => this.queryParams.get(QUERY_PARAMS_KEYS.CITY) === e._id
        )?._id,
        parent: this.userData.manifest.regions.find(
          (e) => this.queryParams.get(QUERY_PARAMS_KEYS.CITY) === e._id
        )?.parent,
      });
    }
  }

  setLocationFilter(filterSetter) {
    if (this.queryParams.get(QUERY_PARAMS_KEYS.LOCATION)) {
      filterSetter({
        label: this.userData.manifest.regions.find(
          (e) => this.queryParams.get(QUERY_PARAMS_KEYS.LOCATION) === e._id
        )?.area,
        value: this.userData.manifest.regions.find(
          (e) => this.queryParams.get(QUERY_PARAMS_KEYS.LOCATION) === e._id
        )?._id,
      });
    }
  }

  setUseCaseTypeFilter(filterSetter) {
    if (this.queryParams.get(QUERY_PARAMS_KEYS.USECASE_TYPE)) {
      filterSetter({
        label: this.userData.manifest.usecaseType.find(
          (e) => this.queryParams.get(QUERY_PARAMS_KEYS.USECASE_TYPE) === e.value
        )?.label,
        value: this.userData.manifest.usecaseType.find(
          (e) => this.queryParams.get(QUERY_PARAMS_KEYS.USECASE_TYPE) === e.value
        )?.value,
      });
    }
  }

  setSelectedTaskFilter(filterSetter) {
    if (!this.queryParams.get(QUERY_PARAMS_KEYS.IS_DIRECT_RECORDING)) {
      if (this.queryParams.get(QUERY_PARAMS_KEYS.TASK) && this.taskList) {
        filterSetter({
          label: this.taskList?.find(
            (e) => this.queryParams.get(QUERY_PARAMS_KEYS.TASK) === e?.value
          )?.label,
          value: this.taskList?.find(
            (e) => this.queryParams.get(QUERY_PARAMS_KEYS.TASK) === e?.value
          )?.value,
        });
      }
    } else {
      filterSetter({
        label: "Recording",
        value: "directRecording",
      });
    }
  }

  setDvrFilter(filterSetter) {
    if (this.queryParams.get(QUERY_PARAMS_KEYS.DVR)) {
      filterSetter({
        label: this.userData.manifest.dvrs.find(
          (dvr) => this.queryParams.get(QUERY_PARAMS_KEYS.DVR) === dvr._id
        )?.dvr,
        value: this.userData.manifest.dvrs.find(
          (dvr) => this.queryParams.get(QUERY_PARAMS_KEYS.DVR) === dvr._id
        )?._id,
      });
    }
  }

  setStatusFilter(filterSetter) {
    if (this.queryParams.get(QUERY_PARAMS_KEYS.STATUS)) {
      filterSetter({
        label: this.queryParams.get(QUERY_PARAMS_KEYS.STATUS),
        value: this.queryParams.get(QUERY_PARAMS_KEYS.STATUS),
      });
    }
  }

  setCheckListFilter(filterSetter) {
    if (this.queryParams.get(QUERY_PARAMS_KEYS.CHECK_LIST)) {
      filterSetter({
        label: this.userData.manifest.checklist.find(
          (checkList) => this.queryParams.get(QUERY_PARAMS_KEYS.CHECK_LIST) === checkList._id
        )?.model,
        value: this.userData.manifest.checklist.find(
          (checkList) => this.queryParams.get(QUERY_PARAMS_KEYS.CHECK_LIST) === checkList._id
        )?._id,
      });
    }
  }

  setTaskTypeFilter(filterSetter) {
    const type =
      this.queryParams.get(QUERY_PARAMS_KEYS.TASK_TYPE) ||
      this.queryParams.get(QUERY_PARAMS_KEYS.MODEL_TASK_TYPE);
    if (type) {
      filterSetter({
        label: this.userData.manifest.modelType.find((taskType) => type === taskType.type)?.label,
        value: this.userData.manifest.modelType.find((taskType) => type === taskType.type)?.type,
      });
    }
  }

  setAssigneeFilter(filterSetter) {
    if (this.queryParams.get(QUERY_PARAMS_KEYS.ASSIGNEE)) {
      filterSetter({
        label: this.employeeData.find(
          (employee) => this.queryParams.get(QUERY_PARAMS_KEYS.ASSIGNEE) === employee.value
        )?.label,
        value: this.employeeData.find(
          (employee) => this.queryParams.get(QUERY_PARAMS_KEYS.ASSIGNEE) === employee.value
        )?.value,
      });
    }
  }

  setRoleFilter(filterSetter) {
    if (this.queryParams.get(QUERY_PARAMS_KEYS.ROLE)) {
      filterSetter({
        label: this.queryParams.get(QUERY_PARAMS_KEYS.ROLE),
        value: this.queryParams.get(QUERY_PARAMS_KEYS.ROLE),
      });
    }
  }

  setScheduleFilter(filterSetter) {
    if (this.queryParams.get(QUERY_PARAMS_KEYS.SCHEDULE)) {
      filterSetter({
        label: this.scheduleData.find(
          (schedule) => this.queryParams.get(QUERY_PARAMS_KEYS.SCHEDULE) === schedule.value
        )?.label,
        value: this.scheduleData.find(
          (schedule) => this.queryParams.get(QUERY_PARAMS_KEYS.SCHEDULE) === schedule.value
        )?.value,
      });
    }
  }

  setDateFilter(dateRangeFilterSetter, selectedDateFilterSetter) {
    if (
      this.queryParams.get(QUERY_PARAMS_KEYS.FROM) &&
      this.queryParams.get(QUERY_PARAMS_KEYS.TO)
    ) {
      const handleDateFilterSetting = (dateFilter) => {
        if (this.queryParams.get(QUERY_PARAMS_KEYS.DATE_FILTER) === "Custom") {
          dateRangeFilterSetter([
            moment(dateFilter.date[0]).format("MMM DD, YYYY"),
            moment(dateFilter.date[1]).format("MMM DD, YYYY"),
          ]);
          selectedDateFilterSetter({
            label: dateFilter.range,
            value: dateFilter.date,
          });
        } else {
          selectedDateFilterSetter({
            label: dateFilter.range,
            value: dateFilter.date,
          });
        }
      };

      if (this.queryParams.get(QUERY_PARAMS_KEYS.DATE_FILTER) === "Custom") {
        let dateFilter = {
          range: "Custom",
          date: [
            this.queryParams.get(QUERY_PARAMS_KEYS.START_DATE) ||
              this.queryParams.get(QUERY_PARAMS_KEYS.FROM),
            this.queryParams.get(QUERY_PARAMS_KEYS.END_DATE) ||
              this.queryParams.get(QUERY_PARAMS_KEYS.TO),
          ],
        };
        handleDateFilterSetting(dateFilter);
      } else {
        const dateFilter = this.dateRangeList.find((filter) => {
          if (filter && filter?.range) {
            return (
              filter.range.toLowerCase() ===
              this.queryParams.get(QUERY_PARAMS_KEYS.DATE_FILTER).toLowerCase()
            );
          }
        });
        handleDateFilterSetting(dateFilter);
      }
    }
  }

  setCameraFilter(filterSetter) {
    if (this.queryParams.get(QUERY_PARAMS_KEYS.CAMERA) && this.cameraList.length > 0) {
      filterSetter({
        label: this.cameraList.find(
          (camera) => this.queryParams.get(QUERY_PARAMS_KEYS.CAMERA) === camera._id
        )?.camera,
        value: this.cameraList.find(
          (camera) => this.queryParams.get(QUERY_PARAMS_KEYS.CAMERA) === camera._id
        )?._id,
      });
    }
  }
}
