import React from "react";

const IconPrev = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7645 11.0368C11.0993 11.3715 11.0993 11.9142 10.7645 12.2489C10.4298 12.5837 9.88709 12.5837 9.55236 12.2489L4.4095 7.10609C4.085 6.7816 4.07365 6.2591 4.38374 5.92081L9.09803 0.777955C9.41791 0.428996 9.96011 0.405422 10.3091 0.725302C10.658 1.04518 10.6816 1.58738 10.3617 1.93634L6.202 6.47422L10.7645 11.0368Z"
        fill="white"
      />
      <path
        d="M6.60682 11.0368C6.94156 11.3715 6.94156 11.9142 6.60682 12.2489C6.27209 12.5837 5.72938 12.5837 5.39464 12.2489L0.251785 7.10609C-0.0727113 6.7816 -0.0840647 6.2591 0.22603 5.92081L4.94032 0.777955C5.26019 0.428996 5.8024 0.405422 6.15135 0.725302C6.50031 1.04518 6.52389 1.58738 6.20401 1.93634L2.04428 6.47422L6.60682 11.0368Z"
        fill="white"
      />
    </svg>
  );
};

export default IconPrev;
