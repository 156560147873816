import React from "react";

function IconException() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2.5H8.25467C8.50713 2.5 8.738 2.64267 8.85093 2.86853L9.33333 3.83333H13.3333C13.7015 3.83333 14 4.13181 14 4.5V11.8333C14 12.2015 13.7015 12.5 13.3333 12.5H9.07867C8.8262 12.5 8.59533 12.3573 8.4824 12.1315L8 11.1667H3.33333V15.1667H2V2.5Z"
        fill="#DC3545"
      />
    </svg>
  );
}

export default IconException;
