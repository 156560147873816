import React from "react";
import Skeleton from "react-loading-skeleton";

function RecorderListSkeleton({ hideHeader }) {
  return (
    <>
      <div hidden={hideHeader} className="list-inline-item w-100 mb-1 grid-section-container py-2">
        <Skeleton width="80px" height="33px" />
      </div>

      <div className="panel-body">
        <Skeleton count={5} height={55} width="100%" className="mb-1" />
      </div>
    </>
  );
}

export default RecorderListSkeleton;
