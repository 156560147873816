import React from "react";
import Skeleton from "react-loading-skeleton";

function DrivethruTableSkeleton() {
  return (
    <div className="panel-body mt-60">
      <div id="table-content">
        <Skeleton count={5} height={50} className="playback-table-skeleton " />
      </div>
    </div>
  );
}

export default DrivethruTableSkeleton;
