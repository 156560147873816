import React from "react";

const IconChecklist = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_40436_27379)">
        <path
          d="M7.33333 2.66699H14V4.00033H7.33333V2.66699ZM7.33333 5.33366H11.3333V6.66699H7.33333V5.33366ZM7.33333 9.33366H14V10.667H7.33333V9.33366ZM7.33333 12.0003H11.3333V13.3337H7.33333V12.0003ZM2 2.66699H6V6.66699H2V2.66699ZM3.33333 4.00033V5.33366H4.66667V4.00033H3.33333ZM2 9.33366H6V13.3337H2V9.33366ZM3.33333 10.667V12.0003H4.66667V10.667H3.33333Z"
          fill="#545454"
        />
      </g>
    </svg>
  );
};

export default IconChecklist;
