import { ADD_ACCESS_LIST } from "../constants";

const initialState = [];

export default function accessListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_ACCESS_LIST:
      return action.value;
    default:
      return state;
  }
}
