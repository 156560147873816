import React from "react";

const IconUndo = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4.30769V0L5.84618 6.15385L12 12.3077V6.76923C16.0616 6.76923 19.3846 10.0923 19.3846 14.1538C19.3846 18.2154 16.0616 21.5385 12 21.5385C7.93848 21.5385 4.61541 18.2154 4.61541 14.1538H2.15387C2.15387 19.5692 6.58464 24 12 24C17.4154 24 21.8462 19.5692 21.8462 14.1538C21.8462 8.73846 17.4154 4.30769 12 4.30769Z"
        fill="#343434"
      />
    </svg>
  );
};

export default IconUndo;
