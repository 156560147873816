import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SettingsSkeleton from "../Common/Components/SkeletonComponent/DetailsSkeleton/SettingsSkeleon";
import WocamDetailSkeleton from "../Common/Components/SkeletonComponent/DetailsSkeleton/WocamDetailSkeleton";
import LiveViewSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/LiveViewSkeleton";
import PlaybackSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/PlaybackSkeleton";
import Layout from "../Common/Containers/_layouts/Home";
import {
  CAMERA_DETAIL,
  ONBOARD_WO_CAM_DEVICE,
  PAGE404,
  PLAYBACK,
  RECORDER_DETAIL,
  VIEW,
  WOCAM,
} from "../Constants";
import AddDeviceSkelton from "./Components/AddDevice/AddDeviceSkelton";
import Wocam from "./Containers/Wocam";

const AddNewDeviceLazyComponent = lazy(() => import("./Containers/AddNewDevice"));
const CameraDetailsLazyComponent = lazy(() => import("./Containers/CameraDetails"));
const PlaybackLazyComponent = lazy(() => import("./Containers/Playback"));
const LiveViewsLazyComponent = lazy(() => import("./Containers/LiveView"));
const RecorderDetailsLazyComponent = lazy(() => import("./Containers/RecorderDetails"));

function OnboardDeviceModule() {
  return (
    <Layout>
      <Switch>
        <Route exact path={ONBOARD_WO_CAM_DEVICE}>
          <Suspense fallback={<AddDeviceSkelton />}>
            <AddNewDeviceLazyComponent />
          </Suspense>
        </Route>
        <Route exact path={CAMERA_DETAIL}>
          <Suspense fallback={<WocamDetailSkeleton />}>
            <CameraDetailsLazyComponent />
          </Suspense>
        </Route>
        <Route exact path={PLAYBACK}>
          <Suspense fallback={<PlaybackSkeleton />}>
            <PlaybackLazyComponent />
          </Suspense>
        </Route>
        <Route exact path={VIEW}>
          <Suspense fallback={<LiveViewSkeleton />}>
            <LiveViewsLazyComponent />
          </Suspense>
        </Route>
        <Route exact path={RECORDER_DETAIL}>
          <Suspense fallback={<SettingsSkeleton />}>
            <RecorderDetailsLazyComponent />
          </Suspense>
        </Route>
        <Route path={WOCAM}>
          <Wocam />
        </Route>
        <Route path="*" render={() => <Redirect to={{ pathname: PAGE404 }} />} />
      </Switch>
    </Layout>
  );
}

export default OnboardDeviceModule;
