import React from "react";

import Skeleton from "react-loading-skeleton";
import Styles from "./StyleDrivethruListSkeleton.module.scss";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";
import DrivethruTableSkeleton from "./DrivethruTableSkeleton";

function DrivethruListSkeleton() {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <PageTitleSkeleton />

        <div className="">
          <Skeleton width={270} height={40} />
        </div>
        <DrivethruTableSkeleton />
      </div>
    </div>
  );
}

export default DrivethruListSkeleton;
