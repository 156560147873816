const PriortyOption = {
  URGENT: "Urgent",
  HIGH: "High",
  NORMAL: "Normal",
  LOW: "Low",
};

const ContactOption = {
  ISSUE_BUG: "Issue / bug",
  QUERY: "Query",
  FEATURE_REQUEST: "Feature request",
};

export const contactOptions = [
  {
    label: ContactOption.ISSUE_BUG,
    value: ContactOption.ISSUE_BUG,
  },
  {
    label: ContactOption.QUERY,
    value: ContactOption.QUERY,
  },
  {
    label: ContactOption.FEATURE_REQUEST,
    value: ContactOption.FEATURE_REQUEST,
  },
];

export const priorityOptions = [
  {
    label: PriortyOption.URGENT,
    value: "1",
  },
  {
    label: PriortyOption.HIGH,
    value: "2",
  },
  {
    label: PriortyOption.NORMAL,
    value: "3",
  },
  {
    label: PriortyOption.LOW,
    value: "4",
  },
];

export const intialRequestFormData = {
  contactFor: undefined,
  subject: "",
  details: "",
  priority: undefined,
  media: "",
  username: "",
  userId: "",
};

export const FIELD_ERROR_MSGS = {
  subject: "Please enter subject",
  details: "Please enter details",
  contactFor: "Please select a request type",
  priority: "Please select a priority",
};

export const getErrorMessage = (key) => FIELD_ERROR_MSGS[key];

export const PriorityOptionsStringMap = {
  1: PriortyOption.URGENT,
  2: PriortyOption.HIGH,
  3: PriortyOption.NORMAL,
  4: PriortyOption.LOW,
};
