import React, { Suspense, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import {
  CUSTOMER_JOURNEY,
  CUSTOMER_JOURNEY_DETAILS,
  CUSTOMER_JOURNEY_LIST,
  CUSTOMER_JOURNEY_LOCATION_DETAILS,
  CUSTOMER_OPERATIONAL_DASHBOARD,
  PAGE404,
} from "../Constants";
import MyTaskSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/taskSkeleton/MyTask";
import DriveThruLocationSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/DriveThruLocationSkeleton";
import Layout from "../Common/Containers/_layouts/Home";

const CustomerJourneyList = lazy(() => import("./Containers/CustomerJourneyList"));
const LocationDetails = lazy(() => import("./Containers/LocationDetails"));
const CustomerOperationalDashboard = lazy(
  () => import("./Containers/CustomerOperationalDashboard")
);
const VehiclesList = lazy(() => import("./Containers/VehiclesList"));
const CustomerJourneyDetails = lazy(() => import("./Containers/CustomerJourneyDetails"));

function CustomerJourneyModule() {
  return (
    <Layout>
      <Switch>
        <Route exact path={CUSTOMER_JOURNEY_DETAILS}>
          <Suspense fallback={<MyTaskSkeleton />}>
            <CustomerJourneyDetails />
          </Suspense>
        </Route>
        <Route exact path={CUSTOMER_JOURNEY}>
          <Suspense fallback={<MyTaskSkeleton />}>
            <VehiclesList />
          </Suspense>
        </Route>
        <Route exact path={CUSTOMER_OPERATIONAL_DASHBOARD}>
          <Suspense fallback={<MyTaskSkeleton />}>
            <CustomerOperationalDashboard />
          </Suspense>
        </Route>
        <Route exact path={CUSTOMER_JOURNEY_LOCATION_DETAILS}>
          <Suspense fallback={<DriveThruLocationSkeleton fullPageSkeleton />}>
            <LocationDetails />
          </Suspense>
        </Route>
        <Route path={CUSTOMER_JOURNEY_LIST}>
          <Suspense fallback={<MyTaskSkeleton />}>
            <CustomerJourneyList />
          </Suspense>
        </Route>
        <Route path="*" render={() => <Redirect to={{ pathname: PAGE404 }} />} />
      </Switch>
    </Layout>
  );
}

export default CustomerJourneyModule;
