import PropTypes from "prop-types";
import React from "react";
import "./tag-style.scss";

/**
 * Reusable Tag Component.
 * @component
 * @param {Object} props - The properties of the Tag component.
 * @param {("default"|"warning"|"success"|"orange"|"purple"|"muted" | "failure" | "block")} [props.color] - The color of the tag.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @param {React.ReactNode} props.children - The content to be displayed inside the tag.
 * @returns {JSX.Element} The Tag component.
 */
function Tag({ color, className, children }) {
  const getColorClass = () => {
    switch (color) {
      case "other":
        return "tag-other";
      case "warning":
        return "tag-warning";
      case "success":
        return "tag-success";
      case "orange":
        return "tag-orange";
      case "yellow":
        return "tag-yellow";
      case "muted":
        return "tag-muted-bg";
      case "purple":
        return "tag-purple";
      case "failure":
        return "tag-failure";
      case "block":
        return "tag-default-block";
      default:
        return "tag-default";
    }
  };

  return (
    <div
      className={`tag-md tag-block d-flex justify-content-center align-items-center ${getColorClass()} ${
        className || ""
      }`}
    >
      {children}
    </div>
  );
}

Tag.propTypes = {
  color: PropTypes.oneOf([
    "default",
    "warning",
    "success",
    "orange",
    "purple",
    "muted",
    "failure",
    "block",
    "other",
  ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
Tag.defaultProps = {
  color: "default",
  className: "",
};

export default Tag;
