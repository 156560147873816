import { number } from "prop-types";
import React from "react";

function IconCopyNew({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_64522_34428)">
        <path
          d="M11.4661 0.666016H3.46615C2.73281 0.666016 2.13281 1.26602 2.13281 1.99935V11.3327H3.46615V1.99935H11.4661V0.666016ZM13.4661 3.33268H6.13281C5.39948 3.33268 4.79948 3.93268 4.79948 4.66602V13.9993C4.79948 14.7327 5.39948 15.3327 6.13281 15.3327H13.4661C14.1995 15.3327 14.7995 14.7327 14.7995 13.9993V4.66602C14.7995 3.93268 14.1995 3.33268 13.4661 3.33268ZM13.4661 13.9993H6.13281V4.66602H13.4661V13.9993Z"
          fill="#545454"
        />
      </g>
      <defs>
        <clipPath id="clip0_64522_34428">
          <rect width="16" height="16" fill="white" transform="translate(0.798828)" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconCopyNew.propTypes = {
  size: number,
};

IconCopyNew.defaultProps = {
  size: 16,
};

export default IconCopyNew;
