import React from "react";

export default function IconNoEvents() {
  return (
    <svg width="90" height="91" viewBox="0 0 90 91" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72.7635 77.313L67.7275 72.2797H53.6634V78.2876H68.683C70.1517 78.2876 71.5384 77.9363 72.7635 77.313Z"
        fill="#CED4DA"
      />
      <path
        d="M71.687 67.8601V54.2562H77.6948V69.2758C77.6948 70.6205 77.4003 71.8965 76.8722 73.0426L71.687 67.8601Z"
        fill="#CED4DA"
      />
      <path
        d="M61.5605 57.7389L57.8657 54.046L58.6951 53.6306V40.146L47.7712 43.9568L43.1092 39.2973L44.5888 39.8176L54.1039 36.4982L44.6517 32.7174L38.8484 35.0387L35.0339 31.2262L43.7313 27.7472C44.3222 27.5109 44.9813 27.5109 45.5721 27.7472L62.0854 34.3525L62.1187 34.366C62.2862 34.4351 62.4432 34.5212 62.588 34.6219C62.9447 34.8695 63.2394 35.2124 63.4289 35.6299C63.4998 35.7857 63.555 35.9495 63.5926 36.1188C63.6367 36.3159 63.6557 36.5139 63.6516 36.7092V55.1611C63.6516 56.0991 63.1219 56.9568 62.2832 57.3769L61.5605 57.7389Z"
        fill="#CED4DA"
      />
      <path
        d="M29.0481 33.6205L27.2314 34.3472C26.5814 34.6005 26.0416 35.1251 25.7921 35.8347C25.6891 36.1275 25.6444 36.4266 25.6519 36.7195V55.1611C25.6519 56.1282 26.2146 57.0071 27.093 57.4118L44.303 65.3422C44.9868 65.6573 45.7768 65.6445 46.45 65.3073L55.9771 60.5354L52.2823 56.8426L47.0637 59.4564V51.6267L42.1072 46.6728V58.873L30.6084 53.5743V40.1554L38.2885 42.8561L29.0481 33.6205Z"
        fill="#CED4DA"
      />
      <path
        d="M22.0099 18.209H35.6398V12.2012H20.6202C19.267 12.2012 17.9836 12.4994 16.8319 13.0337L22.0099 18.209Z"
        fill="#CED4DA"
      />
      <path
        d="M12.5724 17.1534L17.6163 22.1946V36.2326H11.6084V21.213C11.6084 19.7526 11.9558 18.3734 12.5724 17.1534Z"
        fill="#CED4DA"
      />
      <path
        d="M68.683 18.209C70.342 18.209 71.687 19.554 71.687 21.213V36.2326H77.6948V21.213C77.6948 16.2359 73.6601 12.2012 68.683 12.2012H53.6634V18.209H68.683Z"
        fill="#CED4DA"
      />
      <path
        d="M20.6202 72.2797C18.9611 72.2797 17.6163 70.9348 17.6163 69.2758V54.2562H11.6084V69.2758C11.6084 74.2529 15.6431 78.2876 20.6202 78.2876H35.6398V72.2797H20.6202Z"
        fill="#CED4DA"
      />
      <line
        y1="-2.96298"
        x2="110.403"
        y2="-2.96298"
        transform="matrix(0.707292 0.706922 -0.719714 0.694271 1 11.4531)"
        stroke="#CED4DA"
        strokeWidth="5.92595"
      />
    </svg>
  );
}
