import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";
import TabLink from "../TabLink";
import styles from "./Styles.module.scss";

/**
 * `GroupedRoutesTab` is a component that renders a tabbed interface for grouped routes.
 * @typedef { Object } RouteObject
 * @property { string } name - The name of the route.
 * @property { string } link - The link for the route.
 * @property { string } key - The key for the route.
 * @property { bool } hide - To hide the route.
 *
 * @typedef { Object } CountObject
 * @property { string } name - The name same as RouteObject key; associated with the count.
 * @property { number } count - The count value.
 *
 * @param { Object } props - The properties of the component.
 * @param { string } props.currentPath - The current location.
 * @param { Array < RouteObject >} props.tabList - An array of route objects.
 * @param { Array < CountObject >} props.tabDataCounts - An array containing
 * counts related to tab data.
 * @param { Function } [props.handleTabClick] - A function to handle tab click.
 * @param { React.ReactNode } [props.children] - Children to be rendered inside the component.
 * @param { string } [props.containerClass] - Container class to handle the layout.
 * @param { React.ReactNode } [props.additionalTabContent] - Additional content to be
 * displayed next to the tab.
 * @returns { JSX.Element } The JSX representation of the component.
 * * */

function GroupedRoutesTab({
  currentPath,
  tabList,
  tabDataCounts,
  children,
  handleTabClick,
  additionalTabContent,
  containerClass,
}) {
  const onClick = useCallback(handleTabClick, [currentPath]);

  if (tabList?.length <= 1) {
    return children;
  }

  return (
    <>
      <div
        className={`d-flex justify-content-between align-items-center ${styles.tab_container} ${containerClass}`}
      >
        <TabLink
          onClick={onClick}
          currentPath={currentPath}
          tabList={tabList}
          counts={tabDataCounts}
          className={styles.tab_list_container}
        />
        {additionalTabContent}
      </div>
      {children}
    </>
  );
}

GroupedRoutesTab.propTypes = {
  currentPath: PropTypes.string.isRequired,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      hide: PropTypes.bool,
    })
  ).isRequired,
  tabDataCounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,
  children: PropTypes.node.isRequired,
  handleTabClick: PropTypes.func,
  additionalTabContent: PropTypes.node,
  containerClass: PropTypes.string,
};
GroupedRoutesTab.defaultProps = {
  handleTabClick: () => {},
  additionalTabContent: null,
  containerClass: "",
};
export default memo(GroupedRoutesTab);
