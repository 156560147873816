import React from "react";

const IconUsers = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_44424_5259)">
        <path
          d="M1.33301 14.666C1.33301 13.2515 1.89491 11.895 2.89511 10.8948C3.8953 9.89459 5.25185 9.33268 6.66634 9.33268C8.08083 9.33268 9.43738 9.89459 10.4376 10.8948C11.4378 11.895 11.9997 13.2515 11.9997 14.666H10.6663C10.6663 13.6052 10.2449 12.5877 9.49477 11.8376C8.74462 11.0874 7.72721 10.666 6.66634 10.666C5.60548 10.666 4.58806 11.0874 3.83791 11.8376C3.08777 12.5877 2.66634 13.6052 2.66634 14.666H1.33301ZM6.66634 8.66602C4.45634 8.66602 2.66634 6.87602 2.66634 4.66602C2.66634 2.45602 4.45634 0.666016 6.66634 0.666016C8.87634 0.666016 10.6663 2.45602 10.6663 4.66602C10.6663 6.87602 8.87634 8.66602 6.66634 8.66602ZM6.66634 7.33268C8.13967 7.33268 9.33301 6.13935 9.33301 4.66602C9.33301 3.19268 8.13967 1.99935 6.66634 1.99935C5.19301 1.99935 3.99967 3.19268 3.99967 4.66602C3.99967 6.13935 5.19301 7.33268 6.66634 7.33268ZM12.189 9.80135C13.1259 10.2233 13.921 10.9068 14.4787 11.7698C15.0365 12.6328 15.3331 13.6385 15.333 14.666H13.9997C13.9998 13.8953 13.7774 13.141 13.3591 12.4937C12.9408 11.8465 12.3444 11.3338 11.6417 11.0173L12.189 9.80135ZM11.7303 2.27468C12.402 2.55155 12.9763 3.02171 13.3804 3.6255C13.7844 4.2293 13.9999 4.93951 13.9997 5.66602C14 6.5809 13.6581 7.46283 13.0414 8.13857C12.4246 8.8143 11.5775 9.23499 10.6663 9.31802V7.97602C11.1603 7.90527 11.6186 7.67803 11.9739 7.32765C12.3292 6.97728 12.5628 6.52223 12.6404 6.02931C12.7181 5.53638 12.6357 5.03155 12.4053 4.58892C12.1749 4.14629 11.8086 3.78918 11.3603 3.57002L11.7303 2.27468Z"
          fill="#3766E8"
        />
      </g>
      <defs>
        <clipPath id="clip0_44424_5259">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconUsers;
