import { string } from "prop-types";
import React from "react";

function IconFloor({ color }) {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_64996_99463)">
        <path
          d="M13.8128 8.96354L11.33 7.50478L13.8484 5.42372L13.8986 5.33722L13.8984 5.33712L7.22713 1.46779L7.22696 1.46768C7.10223 1.39568 6.94858 1.39567 6.82382 1.46762C6.82379 1.46764 6.82376 1.46766 6.82372 1.46768L0.104467 5.31853C0.104392 5.31857 0.104317 5.31862 0.104243 5.31866C-0.0893251 5.42854 -0.157281 5.67449 -0.0475387 5.86816L-0.0475249 5.86819C-0.0116266 5.9315 0.0407323 5.98396 0.104082 6.01995C0.104214 6.02003 0.104346 6.0201 0.104478 6.02018L2.67106 7.50398L0.104467 8.98782C0.104334 8.9879 0.1042 8.98797 0.104067 8.98805C-0.0893662 9.09798 -0.157247 9.34384 -0.0475387 9.53745L-0.0475249 9.53747C-0.0116015 9.60083 0.0408056 9.65332 0.104215 9.68932C0.104303 9.68937 0.104391 9.68942 0.104478 9.68947L1.84292 10.6913V10.7337L1.89293 10.7626L3.5426 11.7148L3.54312 11.715L6.73882 13.5342C6.73891 13.5343 6.739 13.5343 6.73908 13.5344C6.8638 13.6062 7.01737 13.6062 7.14206 13.5342L7.14224 13.5341L13.8128 9.66518C13.813 9.66509 13.8131 9.665 13.8133 9.66491C14.0067 9.55496 14.0745 9.30914 13.9648 9.11555L13.9648 9.11549C13.9289 9.05232 13.8767 8.99993 13.8135 8.96394C13.8132 8.96381 13.813 8.96367 13.8128 8.96354ZM5.28162 8.09483V8.05246L5.23124 8.02367L1.13619 5.68364L7.0011 2.26442L12.8668 5.68413L7.00075 9.07474L5.28162 8.09483ZM3.47819 7.97074L4.36153 8.46422L1.99252 9.84987L1.13213 9.32575L3.47819 7.97074ZM5.18164 8.94755L6.03628 9.43872L3.65324 10.8093L2.7986 10.3181L5.18164 8.94755ZM5.30891 11.7675L4.45397 11.2761L6.82977 9.90333C6.94281 9.94761 7.07113 9.93891 7.17818 9.87723C7.17827 9.87718 7.17836 9.87713 7.17845 9.87708L10.5246 7.97099L11.21 8.36531L5.30891 11.7675ZM12.8668 9.32386L7.00004 12.7442L6.11766 12.2513L12.0168 8.83255L12.8668 9.32386Z"
          fill="#545454"
          stroke="#545454"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_64996_99463">
          <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconFloor.propTypes = {
  color: string,
};

IconFloor.defaultProps = {
  color: "currentColor",
};

export default IconFloor;
