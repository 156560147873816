export const BASE_URL_TEXT_DATA = "https://insight-search-data.wobot.ai";
export const BASE_URL_VIDEO_DATA = "https://insight-search-video.wobot.ai";
export const VIDEO_THUMBNAIL = "https://wobot-prod-application.s3.ap-south-1.amazonaws.com/";
export const PLAYBACK_URL =
  "/wocam/playback?query=&location=&usecaseType=&startTime=03-21-2024&endTime=03-21-2024&range=Custom&selectedDate=03-21-2024&fromTime=2024-03-21T00:00:00+05:30&toTime=2024-03-21T23:59:59+05:30";
export const TOKEN = "Bearer WJ69FLlEYkqGgrIGqQTdClP9Lih90C781QxYwALOXlFr626V4W5XSic2x25me83D";
export const SEARCH_OPTIONS = {
  VIDEO: "Video",
  CAMERA_AUDIT: "Camera-Audit",
  TASK_DATA: "Task-Data",
  DRIVE_THRU_DATA: "DriveThru-Data",
};
const ALL_FEATURES = [
  SEARCH_OPTIONS.VIDEO,
  SEARCH_OPTIONS.CAMERA_AUDIT,
  SEARCH_OPTIONS.TASK_DATA,
  SEARCH_OPTIONS.DRIVE_THRU_DATA,
];
export const allowedCompanies = {
  WAB: {
    id: "62c6d9804ea055798df01b20",
    features: [SEARCH_OPTIONS.DRIVE_THRU_DATA],
    driveThruEndpoint: "/ask-drivethru-wab",
  },
  DEMO_T: {
    id: "61399ea7cdf27244612328f2",
    features: ALL_FEATURES,
  },
  TEST_EDGE_3: {
    id: "651c0832c1782226c930aa60",
    features: ALL_FEATURES,
  },
  TEST_EDGE_4: {
    id: "651d08b1a798413e9c77f659",
    features: [SEARCH_OPTIONS.DRIVE_THRU_DATA],
    driveThruEndpoint: "/ask-drivethru-wab",
  },
  Pandas: {
    id: "661d2d652b813d804b362766",
    features: [SEARCH_OPTIONS.DRIVE_THRU_DATA],
    driveThruEndpoint: "/ask-drivethru-wab",
  },
};

export const getURLS = (optionType) => {
  if (optionType === SEARCH_OPTIONS.VIDEO) {
    return BASE_URL_VIDEO_DATA;
  }
  return BASE_URL_TEXT_DATA;
};
export const ENDPOINTS = {
  [SEARCH_OPTIONS.VIDEO]: "/search/",
  [SEARCH_OPTIONS.CAMERA_AUDIT]: "/analyze_image",
  [SEARCH_OPTIONS.TASK_DATA]: "/ask-task",
  [SEARCH_OPTIONS.DRIVE_THRU_DATA]: "/ask-drivethrough",
};

export const optionTypePost = [SEARCH_OPTIONS.VIDEO, SEARCH_OPTIONS.CAMERA_AUDIT];

export const searchOptions = [
  { label: "Videos", value: SEARCH_OPTIONS.VIDEO, isDisabled: false },
  {
    label: "Camera Audit",
    value: SEARCH_OPTIONS.CAMERA_AUDIT,
    isDisabled: false,
  },
  { label: "Task Data", value: SEARCH_OPTIONS.TASK_DATA, isDisabled: false },
  {
    label: "DriveThru Data",
    value: SEARCH_OPTIONS.DRIVE_THRU_DATA,
    isDisabled: false,
  },
];
const LOCATION_SELECTOR = {
  FOSTER_CITY_518: "foster-city-518",
  DALLAS_862: "dallas-862",
  HOUSTON_721: "houston-721",
};

export const locationsOptions = [
  {
    label: "Foster City 518 R",
    value: "Retail",
    camera: "65fa9c4f8cacb057487f62a3",
    selector: LOCATION_SELECTOR.FOSTER_CITY_518,
  },
  {
    label: "Dallas 862 K",
    value: "Kitchen",
    camera: "65fc0773dfc8d86023eb506c",
    selector: LOCATION_SELECTOR.DALLAS_862,
  },
  {
    label: "Houston 721 DT",
    value: "DriveThru",
    camera: "65fc0737dfc8d86023eb4766",
    selector: LOCATION_SELECTOR.HOUSTON_721,
  },
];
const driveThruQueries = [
  {
    label: "Location with max traffic on 12th March",
    message: "Which location got max traffic on 12th march?",
  },
  {
    label: "Hour with most traffic",
    message: "In which hour on average do we get most cars?",
  },
  {
    label: "Rank locations by Window Time",
    message: "Rank the locations by their average Window Time",
  },
];
const taskQueries = [
  {
    label: "Weekday with max customers",
    message: "Which weekday receives most customers?",
  },
  {
    label: "Max wait time on 29th Dec",
    message: "What was the maximum customer wait time for 29th December in seconds?",
  },
  {
    label: "Rank locations by customer count",
    message: "Rank the locations by average walk-in count per day?",
  },
];
const videoDTQueries = [
  {
    label: "Pickup truck",
    message: "A photo of a pickup truck",
  },
  {
    label: "Blue car",
    message: "A photo of a blue car",
  },
  {
    label: "Queue forming",
    message: "Photo of cars in a long queue waiting in drive-thru",
  },
];
const videoKitchenQueries = [
  {
    label: "Person without hat",
    message: "Picture of a person without a head hat",
  },
  {
    label: "Person sweeping",
    message: "Sweeping the ground in corner",
  },
  {
    label: "Person without blue gloves",
    message: "Person without blue gloves in hand",
  },
];
const videoRetailsQueries = [
  {
    label: "Crowd in store",
    message: "Crowd in the store",
  },
  {
    label: "Floor sweeping",
    message: "Sweeping the ground",
  },
];

export const queriesTags = {
  [SEARCH_OPTIONS.TASK_DATA]: taskQueries,
  [SEARCH_OPTIONS.DRIVE_THRU_DATA]: driveThruQueries,
  [SEARCH_OPTIONS.VIDEO]: {
    [LOCATION_SELECTOR.DALLAS_862]: videoKitchenQueries,
    [LOCATION_SELECTOR.FOSTER_CITY_518]: videoRetailsQueries,
    [LOCATION_SELECTOR.HOUSTON_721]: videoDTQueries,
  },
};
