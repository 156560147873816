import React from "react";

const IconLocation = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99998 0C3.135 0 0 3.135 0 6.99998C0 12.25 6.99998 20 6.99998 20C6.99998 20 14 12.25 14 6.99998C14 3.135 10.865 0 6.99998 0ZM6.99998 9.50001C5.61998 9.50001 4.5 8.38002 4.5 7.00003C4.5 5.62003 5.61998 4.5 6.99998 4.5C8.37998 4.5 9.49996 5.61998 9.49996 6.99998C9.49996 8.37998 8.37998 9.50001 6.99998 9.50001Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconLocation;
