import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { settings } from "../../../Constants/MetaDetails";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";

const DataOutApi = lazy(() => import("../../Components/IntegrationComponent/DataOutApi"));

const IntegrationComponent = lazy(() => import("../../Components/IntegrationComponent"));
const JoltIntegration = lazy(() => import("../../Components/IntegrationComponent/JoltIntegration"));
const OpenEyeIntegration = lazy(
  () => import("../../Components/IntegrationComponent/OpenEyeIntegration")
);
const SsoIntegration = lazy(() => import("../../Components/IntegrationComponent/SsoIntegration"));

function IntegrationContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.integration}
      meta={settings?.integrations?.subtitle}
      withLayout={false}
    >
      <IntegrationComponent />
    </PageContainer>
  );
}

function JoltIntegrationContainer() {
  return (
    <PageContainer initialPageTitle={PAGE_TITLES.jolt} withLayout={false}>
      <JoltIntegration />
    </PageContainer>
  );
}

function OpenEyeIntegrationContainer() {
  return (
    <PageContainer initialPageTitle="OpenEye Integration | Wobot AI" withLayout={false}>
      <OpenEyeIntegration />
    </PageContainer>
  );
}

function SsoIntegrationContainer() {
  return (
    <PageContainer initialPageTitle={PAGE_TITLES.sso} withLayout={false}>
      <SsoIntegration />
    </PageContainer>
  );
}

function DataOutApiContainer() {
  return (
    <PageContainer initialPageTitle="Data Out API" withLayout={false}>
      <DataOutApi />
    </PageContainer>
  );
}

export {
  DataOutApiContainer,
  IntegrationContainer,
  JoltIntegrationContainer,
  OpenEyeIntegrationContainer,
  SsoIntegrationContainer,
};
