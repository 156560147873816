import React, { lazy, Suspense } from "react";
import LoginSkeleton from "../Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const LoginComponent = lazy(() => import("../Components/LoginComponent"));

function Login() {
  return (
    <Suspense fallback={<LoginSkeleton />}>
      <LoginComponent />
    </Suspense>
  );
}

export default Login;
