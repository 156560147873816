import React from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import NotificationStrip from "../../../Components/Molecule/NotificationStrip";
import { toggleNavbarAction } from "../../../../Store/actions";
import MobileMaintenanceComponent from "../../../Components/ErrorPagesComponents/MobileMaintenance";
import IconWarning from "../../../Components/IconsComponent/IconWarning";
import VerifyEmailStrip from "../../../Components/Molecule/VerifyEmailStrip";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { shouldShowFooter } from "../../../../Services/Service";

function Home(props) {
  const isNavbarVisible = useSelector((state) => state.navbar);
  const showFooter = shouldShowFooter();
  return (
    <>
      <div className="wrapper mobile-visible-none">
        <Sidebar />

        <div id="content" className="">
          {props?.alertData?.length ? (
            <NotificationStrip showNotifyIcon notifyIcon={<IconWarning />} showNotifyContent />
          ) : (
            ""
          )}
          {props.userData?.user?.role === "Owner" ? (
            props.userData?.manifest?.isVerified ? (
              ""
            ) : (
              <VerifyEmailStrip showNotifyContent email={props?.userData?.user?.email} />
            )
          ) : (
            ""
          )}
          <Header />
          {props?.children}
          {showFooter ? <Footer /> : null}
        </div>
        <div
          className={!isNavbarVisible ? "overlay" : ""}
          onClick={() => {
            props.toggleNavbarComponent();
          }}
        />
      </div>
      <MobileMaintenanceComponent />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  toggleNavbarComponent: () => {
    dispatch(toggleNavbarAction());
  },
});
const mapStateToProps = (state) => state;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
