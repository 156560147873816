import React from "react";

function IconTimerBold() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9091 14.1212V17.606L18.1468 18.5385L18.6714 17.2798L17.2727 16.697V14.1212H15.9091Z"
        fill="#545454"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5909 3.81818H17.5C18.5 3.81818 19.3182 4.63636 19.3182 5.63636V11.8206C20.9485 12.7637 22.0455 14.5265 22.0455 16.5455C22.0455 19.5579 19.6034 22 16.5909 22C14.9755 22 13.524 21.2977 12.5253 20.1818H4.77273C3.76363 20.1818 2.95454 19.3636 2.95454 18.3636V5.63636C2.95454 4.63636 3.76363 3.81818 4.77273 3.81818H5.68182V2H7.5V3.81818H14.7727V2H16.5909V3.81818ZM17.5 9.27273V11.1663C17.2044 11.1167 16.9006 11.0909 16.5909 11.0909C13.5784 11.0909 11.1364 13.533 11.1364 16.5455C11.1364 17.183 11.2457 17.7949 11.4467 18.3636H4.77273V9.27273H17.5ZM4.77273 7.45455H17.5V5.63636H4.77273V7.45455ZM16.5909 20.1818C18.5992 20.1818 20.2273 18.5538 20.2273 16.5455C20.2273 14.5371 18.5992 12.9091 16.5909 12.9091C14.5826 12.9091 12.9545 14.5371 12.9545 16.5455C12.9545 18.5538 14.5826 20.1818 16.5909 20.1818Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconTimerBold;
