import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";
import { CLEAR_REDIRECT_URL_AFTER_LOGIN } from "../../../Store/constants";

function LoginRoutesComponent(props) {
  const history = useHistory();
  const { userData, redirectUrlAfterLogin, clearRedirectUrlAfterLogin } = props;

  useEffect(() => {
    if (userData && !!Object.keys(userData).length) {
      // Redirect to previous path after login
      if (redirectUrlAfterLogin) {
        history.replace(redirectUrlAfterLogin);
        clearRedirectUrlAfterLogin();
      }
    }
  }, [userData, redirectUrlAfterLogin, clearRedirectUrlAfterLogin, history]);

  if (userData && !!Object.keys(userData).length) {
    // if no previous path redirect to home
    return <Redirect to="/home" />;
  }

  return <Route {...props} />;
}

const mapStateToProps = (state) => ({
  userData: state?.userData,
  redirectUrlAfterLogin: state?.redirectUrlAfterLogin,
});

const mapDispatchToProps = (dispatch) => ({
  clearRedirectUrlAfterLogin: () => dispatch({ type: CLEAR_REDIRECT_URL_AFTER_LOGIN }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginRoutesComponent);
