import React from "react";

function IconTimeFilter({width=16, height=16, fill="#545454"}) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 23.0769C5.8824 23.0769 0.923096 18.1176 0.923096 12C0.923096 5.8824 5.8824 0.923096 12 0.923096C18.1176 0.923096 23.0769 5.8824 23.0769 12C23.0769 18.1176 18.1176 23.0769 12 23.0769ZM12 20.8616C16.8941 20.8616 20.8616 16.8941 20.8616 12C20.8616 7.10592 16.8941 3.13848 12 3.13848C7.10592 3.13848 3.13848 7.10592 3.13848 12C3.13848 16.8941 7.10592 20.8616 12 20.8616ZM13.1077 12H17.5385V14.2154H10.8923V6.46156H13.1077V12Z"
        fill={fill}
      />
    </svg>
  );
}

export default IconTimeFilter;
