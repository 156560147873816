import { TOGGLE_LOADER } from "../constants";

const initialState = {
  label: "",
  value: false,
};

export default function loaderReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_LOADER:
      return action.value;
    default:
      return state;
  }
}
