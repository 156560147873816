import React from "react";

function IconOtherPayment() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_59265_2551)">
        <path
          d="M0.999695 4.5V8L2.99969 11L0.999695 14V17.5H20.9997V14L18.9997 11L20.9997 8V4.5H0.999695Z"
          fill="#9747FF"
          fillOpacity="0.1"
        />
        <path
          d="M15.6354 11.5273C13.4281 11.5801 13.4244 14.8156 15.631 14.8743C17.8383 14.8207 17.8413 11.5867 15.6354 11.5273ZM15.0597 13.203C15.0605 12.4521 16.2023 12.4491 16.2067 13.2001C16.2037 13.9488 15.0656 13.9517 15.0597 13.203Z"
          fill="#9747FF"
        />
        <path
          d="M11.2347 10.4727C13.4413 10.4169 13.4413 7.18147 11.2347 7.12573C9.02733 7.1822 9.0288 10.4155 11.2347 10.4727ZM11.2347 8.22647C11.9849 8.2294 11.9849 9.37047 11.2347 9.3734C10.4845 9.36973 10.4852 8.23087 11.2347 8.22647Z"
          fill="#9747FF"
        />
        <path
          d="M17.006 7.43462C16.7911 7.21902 16.4413 7.21829 16.2257 7.43315L9.85304 13.7758C9.63377 13.9862 9.62717 14.3346 9.83764 14.5531C10.0525 14.7775 10.4126 14.7782 10.6289 14.5553L17.0023 8.21342C17.2186 7.99929 17.2201 7.65095 17.006 7.43462Z"
          fill="#9747FF"
        />
        <path
          d="M6.49147 7.72729C6.18787 7.72729 5.94147 7.97369 5.94147 8.27729V9.6875C5.94147 9.9911 6.18787 10.2375 6.49147 10.2375C6.79507 10.2375 7.04147 9.9911 7.04147 9.6875V8.27729C7.04147 7.97369 6.79507 7.72729 6.49147 7.72729Z"
          fill="#9747FF"
        />
        <path
          d="M6.49147 11.7634C6.18787 11.7634 5.94147 12.0098 5.94147 12.3134V13.7229C5.94147 14.0265 6.18787 14.2729 6.49147 14.2729C6.79507 14.2729 7.04147 14.0265 7.04147 13.7229V12.3134C7.04147 12.0098 6.79507 11.7634 6.49147 11.7634Z"
          fill="#9747FF"
        />
        <path
          d="M20.8112 9.18285C21.4324 8.84992 21.8188 8.20092 21.8166 7.49618V5.61005C21.8152 4.55038 20.9572 3.69238 19.8975 3.69092H2.10244C1.04277 3.69238 0.184772 4.55038 0.183305 5.61005V7.49472C0.181105 8.19945 0.567572 8.84845 1.18871 9.18139C2.19264 9.71892 2.57104 10.9685 2.03351 11.9725C1.84137 12.3311 1.54731 12.6251 1.18871 12.8173C0.566839 13.1509 0.180372 13.7999 0.183305 14.5054V16.3901C0.184772 17.4497 1.04277 18.3077 2.10244 18.3092H19.8975C20.9572 18.3077 21.8152 17.4497 21.8166 16.3901V14.5054C21.8188 13.8007 21.4324 13.1517 20.8112 12.8187C19.371 12.0979 19.382 9.90372 20.8112 9.18285ZM18.9999 12.4917C19.294 13.0409 19.7442 13.4912 20.2935 13.7853C20.5568 13.9275 20.7196 14.2033 20.7166 14.5025V16.3871C20.7159 16.8396 20.35 17.2055 19.8975 17.2063H7.03997C7.00991 16.7296 7.22917 15.8173 6.48997 15.799C5.75004 15.8166 5.97004 16.7333 5.93997 17.2092H2.10244C1.64997 17.2085 1.28404 16.8425 1.28331 16.3901V14.5054C1.28037 14.2062 1.44317 13.9305 1.70644 13.7882C3.24571 12.9639 3.82431 11.0477 3.00004 9.50845C2.70597 8.95918 2.25571 8.50892 1.70644 8.21485C1.44317 8.07258 1.28037 7.79685 1.28331 7.49765V5.61005C1.28404 5.15758 1.64997 4.79165 2.10244 4.79092H5.93997C5.97004 5.26832 5.74931 6.18279 6.48997 6.20112C7.22991 6.18352 7.00991 5.26685 7.03997 4.79092H19.8975C20.35 4.79165 20.7159 5.15758 20.7166 5.61005V7.49472C20.7196 7.79392 20.5568 8.06965 20.2935 8.21192C18.7542 9.03618 18.1749 10.9524 18.9999 12.4917Z"
          fill="#9747FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_59265_2551">
          <rect width="22" height="22" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconOtherPayment;
