import React, { lazy, Suspense } from "react";
import LoginSkeleton from "../Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const LoginSsoComponent = lazy(() => import("../Components/LoginComponent/ssoLogin"));

function LoginSso() {
  return (
    <Suspense fallback={<LoginSkeleton height={300} />}>
      <LoginSsoComponent />
    </Suspense>
  );
}

export default LoginSso;
