import React from "react";

const IconTimer = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0.666992H6V2.00033H10V0.666992ZM7.33333 9.33366H8.66667V5.33366H7.33333V9.33366ZM12.6867 4.92699L13.6333 3.98033C13.3467 3.64033 13.0333 3.32033 12.6933 3.04033L11.7467 3.98699C10.7133 3.16033 9.41333 2.66699 8 2.66699C4.68667 2.66699 2 5.35366 2 8.66699C2 11.9803 4.68 14.667 8 14.667C11.32 14.667 14 11.9803 14 8.66699C14 7.25366 13.5067 5.95366 12.6867 4.92699ZM8 13.3337C5.42 13.3337 3.33333 11.247 3.33333 8.66699C3.33333 6.08699 5.42 4.00033 8 4.00033C10.58 4.00033 12.6667 6.08699 12.6667 8.66699C12.6667 11.247 10.58 13.3337 8 13.3337Z"
        fill="#7E7E7E"
      />
    </svg>
  );
};

export default IconTimer;
