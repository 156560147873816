import PropTypes from "prop-types";
import React from "react";
import { DebounceInput } from "react-debounce-input";
import { DEBOUNCE_INPUT_TIME } from "../../../../Constants";
import { showAlert } from "../../../../Services/showAlert";
import { validateSearch } from "../../../../Services/validation";
import IconSearch from "../../IconsComponent/IconSearch";

const alertMessage = "Characters including ( \\ ? * ( ) [ ) are not allowed.";

function SearchFilter({ value, handleUpdateValue, setLoader }) {
  const handleOnChange = (e) => {
    const inputValue = e.target.value;
    if (setLoader !== null && typeof setLoader === "function") {
      setLoader(true);
    }
    handleUpdateValue(inputValue);
  };

  const handleInput = (e) => {
    const inputValue = e.target.value;
    if (!validateSearch(inputValue)) {
      e.target.value = "";
      showAlert(alertMessage, "warning");
    }
  };
  return (
    <div className="search-filter w-100">
      <DebounceInput
        className="search form-control"
        autoFocus
        placeholder="Search"
        debounceTimeout={DEBOUNCE_INPUT_TIME}
        onChange={handleOnChange}
        onInput={handleInput}
        value={value}
      />
      <IconSearch />
    </div>
  );
}

// Prop type validations
SearchFilter.propTypes = {
  value: PropTypes.string.isRequired,
  handleUpdateValue: PropTypes.func.isRequired,
  setLoader: PropTypes.func,
};
SearchFilter.defaultProps = {
  setLoader: null,
};
export default SearchFilter;
