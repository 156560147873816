import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "wobotui";
import IconErrorDisplay from "../../IconsComponent/IconErrorDisplay";
import IconWobotName from "../../IconsComponent/IconWobotName";
import classes from "./ErrorDisplay.module.scss";

function ErrorDisplay({ error, resetErrorBoundary }) {
  const history = useHistory();

  console.log("Error occurred:", error);

  const handleGoBack = () => {
    history.replace("/");
    resetErrorBoundary();
  };
  return (
    <div className={classes.error_display_container}>
      <div>
        <IconWobotName />
      </div>
      <div className={classes.info_container}>
        <div>
          <IconErrorDisplay />
        </div>
        <div className={classes.info_message_container}>
          <div className={classes.message}>
            <h2 className={classes.title}>Oops!</h2>
            <h3 className={classes.subtitle}>Something went wrong</h3>
            {/* <pre>{error?.message || ""}</pre> */}
            <p className={classes.description}>
              We're having some technical issues on our end.
              <br /> You can try reloading the page. <br /> If that doesn't work, go back or report
              the issue to us.
            </p>
          </div>
          <div className={classes.action}>
            <Button btnSize="small" type="button" onClick={handleGoBack}>
              Go Back
            </Button>
            <Button
              btnSize="small"
              type="button"
              variant="ghost"
              onClick={() => window.location.reload()}
            >
              Re-load
            </Button>
            <Button btnSize="small" type="button" variant="ghost">
              Report
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorDisplay;
