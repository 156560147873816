import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "wobotui";
import { API_BASE_URL, HOME } from "../../../../../Constants";
import Service, { loginService } from "../../../../../Services/Service";
import callApi from "../../../../../Services/callApi";
import { showAlert } from "../../../../../Services/showAlert";
import { validateEmail } from "../../../../../Services/validation";
import { addAccessListAction, addUserDataToStoreAction } from "../../../../../Store/actions";
import IconArrowDown from "../../../../Components/IconsComponent/IconArrowDown";
import IconCompany from "../../../../Components/IconsComponent/IconCompany";
import IconMobile from "../../../../Components/IconsComponent/IconMobile";
import DownloadAppModal from "../ModalComponents/DownloadAppModal";
import MultiCompanyAccessModal from "../ModalComponents/MultiCompanyAccessModal";
import styles from "./Navbar.module.scss";

function NavbarComponent(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [userEmail, setUserEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [textChange, setTextChange] = useState(true);

  const [showCompanies, setShowCompanies] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const NavLinkList = [
    {
      icon: <IconMobile />,
      title: "Download App",
      subMenu: [],
    },
  ];

  const handleShowCompanies = () => {
    setShowCompanies(true);
  };

  const handleSwitchCompany = useCallback(
    async (company) => {
      if (!company?.selected) {
        try {
          const response = await callApi(
            `${API_BASE_URL}/user/switch-company`,
            {
              method: "POST",
              body: JSON.stringify({ company: company?._id }),
            },
            {
              showLoader: true,
              callManifest: false,
              loaderLabel: `Switching to ${company?.name}`,
            }
          );
          if (response.status === 200) {
            // it will set user data
            await loginService(
              { ...response, hideLoader: true },
              {
                isDemoAccount: false,
                history,
                next: true,
              }
            );
            // Redirect Home after successfully switched
            history.push(HOME);
          }
        } catch (error) {
          showAlert(error, "Error");
        } finally {
          setShowCompanies(false);
        }
      }
    },
    [history, pathname, show]
  );
  // API CALL
  const onSubmitEmail = async (email) => {
    setIsEmailValid(validateEmail(email));
    if (email) {
      if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        // if (emailArray && emailArray.length) {
        try {
          await callApi(`${API_BASE_URL}/download-app`, {
            method: "POST",
            body: JSON.stringify({
              email,
            }),
          }).then((res) => {
            if (res.status === 200) {
              showAlert("Email sent successfully");
              handleClose(false);
            }
          });
        } catch (e) {
          showAlert(e, "error");
        } finally {
          setUserEmail("");
        }
      } else {
        showAlert("Please add valid email address", "error");
      }
    } else {
      showAlert("Please add an email address", "error");
    }
  };
  // Download App
  function renderNavItems() {
    return NavLinkList.map((item, index) => {
      if (
        item.title === "Download App" &&
        (Service.checkHost("airtel.com") || Service.checkHost("openeye"))
      )
        return <></>;
      return (
        <li key={item.title} className="list-inline-item">
          <button className="btn btn-textIcon btn-sm" onClick={handleShow}>
            <span>
              {item.icon} {item.title}
            </span>
          </button>
        </li>
      );
    });
  }
  const isHasCompanies = props?.userData?.user?.companies?.length > 1;
  return (
    <ul className={styles.navLinksWrapper}>
      {/* {renderNavItems()} */}
      <li className={`list-inline-item ${!isHasCompanies ? "cursor-default" : ""}`}>
        <Button
          variant="ghost-outlined"
          btnSize="small"
          onClick={handleShowCompanies}
          iconLeft={<IconCompany />}
          iconRight={isHasCompanies ? <IconArrowDown /> : null}
          disabled={!isHasCompanies}
        >
          <span className={`${styles.nav_company_name}`}>
            {props?.userData?.manifest?.company?.name ?? "-"}
          </span>
        </Button>
      </li>

      {showCompanies && (
        <MultiCompanyAccessModal
          showCompanies={showCompanies}
          setShowCompanies={setShowCompanies}
          handleSwitchCompany={handleSwitchCompany}
          companies={props?.userData?.user?.companies}
        />
      )}
      {show && (
        <DownloadAppModal
          show={show}
          setUserEmail={setUserEmail}
          handleClose={handleClose}
          setTextChange={setTextChange}
          textChange={textChange}
          userData={props?.userData}
          userEmail={userEmail}
          isEmailValid={isEmailValid}
          setIsEmailValid={setIsEmailValid}
          onSubmitEmail={onSubmitEmail}
        />
      )}
    </ul>
  );
}
const mapStateToProps = (state) => ({ userData: state?.userData });
const mapDispatchToProps = (dispatch) => ({
  addUserDataToStore: (data) => {
    dispatch(addUserDataToStoreAction(data));
  },
  addAccessListAction: (data) => {
    dispatch(addAccessListAction(data));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(NavbarComponent);
