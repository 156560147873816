import React from "react";

const IconEmailVerified = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.9997 0C51.0452 0 61.0451 4.47721 68.284 11.7155C75.5228 18.9538 80 28.9544 80 39.9993C80 51.0456 75.5228 61.0456 68.284 68.2839C61.0451 75.5221 51.0452 80 39.9997 80C28.9542 80 18.9543 75.5228 11.7161 68.2845C4.47783 61.0462 0 51.0456 0 39.9993C0 28.9544 4.47718 18.9538 11.7161 11.7155C18.9549 4.47721 28.9542 0 39.9997 0ZM22.4972 43.7363C21.6424 42.9629 21.576 41.6426 22.3501 40.7878C23.1235 39.9329 24.4438 39.8665 25.2986 40.64L34.0492 48.5749L54.5848 27.0671C55.3811 26.2298 56.7059 26.1973 57.5425 26.9928C58.3791 27.7891 58.4123 29.1133 57.6161 29.9499L35.6722 52.9329L35.6696 52.9303C34.8916 53.7487 33.598 53.8014 32.7562 53.0397L22.4972 43.7363ZM65.3185 14.681C58.8387 8.20182 49.887 4.19336 39.9997 4.19336C30.1117 4.19336 21.16 8.20117 14.6809 14.6803C8.2011 21.1602 4.19398 30.1113 4.19398 39.9993C4.19398 49.8874 8.20176 58.8392 14.6809 65.319C21.16 71.7982 30.1117 75.806 39.9997 75.806C49.887 75.806 58.8387 71.7982 65.3185 65.319C71.7982 58.8392 75.8047 49.888 75.8047 39.9993C75.8054 30.112 71.7982 21.1602 65.3185 14.681Z"
        fill="#53D501"
      />
    </svg>
  );
};

export default IconEmailVerified;
