import React from "react";
import { useSelector } from "react-redux";
import "./style.scss";

function LoaderComponent() {
  const { value: isLoaderVisible, label: loaderLabel } = useSelector(
    (state) => state?.loader || {}
  );

  if (!isLoaderVisible) {
    return null;
  }

  return (
    <div
      className="loader-body"
      style={{
        position: "fixed",
        display: "block",
        zIndex: 1060,
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        opacity: "0.9",
        top: 0,
        left: 0,
        right: 0,
        textAlign: "center",
        overflow: "hidden",
      }}
    >
      <div className="page-loader-wrapper content">
        <div className="loader">
          <div className="loaderBar" />
        </div>
        <p className="loaderLabel mt-4 text-muted">{loaderLabel}</p>
      </div>
    </div>
  );
}

export default LoaderComponent;
