import React from "react";

const IconEditNew = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0614 9.5758L14.6472 8.16158L5.3335 17.4753V18.8895H6.74771L16.0614 9.5758ZM17.4756 8.16158L18.8898 6.74737L17.4756 5.33316L16.0614 6.74737L17.4756 8.16158ZM7.57614 20.8895H3.3335V16.6469L16.7685 3.21184C17.1591 2.82131 17.7922 2.82131 18.1827 3.21184L21.0112 6.04026C21.4017 6.43079 21.4017 7.06395 21.0112 7.45448L7.57614 20.8895Z"
        fill="#7E7E7E"
      />
    </svg>
  );
};

export default IconEditNew;
