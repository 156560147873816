import { TOGGLE_NAVBAR } from "../constants";

const initialState = true;

export default function navbarReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_NAVBAR:
      return !state;
    default:
      return state;
  }
}
