import React from "react";

function IconLiveView({ size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0552 12C21.0552 6.97759 16.9788 2.92201 11.9701 2.92201V0.666687C18.2547 0.666687 23.3333 5.74957 23.3333 12C23.3333 18.2505 18.2547 23.3334 11.9701 23.3334V21.078C16.9788 21.078 21.0552 17.0224 21.0552 12Z"
        fill="#545454"
      />
      <path d="M9.08488 7.46417L17.037 12L9.08488 16.5359V7.46417Z" fill="#545454" />
      <path
        d="M0.666666 13.134H2.96143C3.06367 13.9731 3.27952 14.7669 3.5976 15.5153L1.62092 16.6492C1.12107 15.5606 0.791628 14.3813 0.666666 13.134Z"
        fill="#545454"
      />
      <path
        d="M7.31239 22.3304L8.44841 20.3573C9.19819 20.6748 9.9934 20.8903 10.8341 20.9923V23.2829C9.5958 23.1582 8.40297 22.8293 7.31239 22.3304Z"
        fill="#545454"
      />
      <path
        d="M10.8341 0.717063V3.00767C9.9934 3.10973 9.19819 3.32518 8.44841 3.64269L7.31239 1.66959C8.40297 1.17065 9.58444 0.841799 10.8341 0.717063Z"
        fill="#545454"
      />
      <path
        d="M1.62092 7.35075L3.5976 8.48471C3.27952 9.23313 3.06367 10.0269 2.96143 10.866H0.666666C0.791628 9.61868 1.12107 8.43935 1.62092 7.35075Z"
        fill="#545454"
      />
      <path
        d="M5.34707 2.80356L6.4831 4.78799C5.8242 5.28694 5.24483 5.86526 4.74498 6.5343L2.76831 5.38899C3.484 4.3911 4.34738 3.52929 5.34707 2.80356Z"
        fill="#545454"
      />
      <path
        d="M2.76831 18.611L4.74498 17.477C5.24483 18.1347 5.8242 18.7131 6.49446 19.212L5.34707 21.1851C4.35874 20.4707 3.484 19.5976 2.76831 18.611Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconLiveView;
