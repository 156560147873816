import React from "react";

const IconCreditCard = () => {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.333 0.166016H1.66634C0.926341 0.166016 0.339674 0.759349 0.339674 1.49935L0.333008 9.49935C0.333008 10.2393 0.926341 10.8327 1.66634 10.8327H12.333C13.073 10.8327 13.6663 10.2393 13.6663 9.49935V1.49935C13.6663 0.759349 13.073 0.166016 12.333 0.166016ZM12.333 9.49935H1.66634V5.49935H12.333V9.49935ZM12.333 2.83268H1.66634V1.49935H12.333V2.83268Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconCreditCard;
