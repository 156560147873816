import moment from "moment";
import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";

import { connect, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import ZeroStateComponent from "../../../Analytics/Components/TicketsReportComponent/ZeroStateComponent";
import PageTitle from "../../../Common/Components/Molecule/PageTitle";
import SkeletonGrid from "../../../Common/Components/SkeletonComponent/DetailsSkeleton/SkeletonGrid";
import TaskTypeEventSkeleton from "../../../Common/Components/SkeletonComponent/DetailsSkeleton/TaskTypeEventSkeleton";
import {
  API_BASE_URL,
  API_CONFIG_NO_MANIFEST_CALL,
  API_CONFIG_SHOWLOADER,
  DEBOUNCE_INPUT_TIME,
  DRIVE_LIST,
} from "../../../Constants";
import getQueryVariable from "../../../Services/getQueryVariable";

import DateFilterComponent from "../../../Common/Components/DateFilter/DateFilterComponent";
import IconArrowNextRight from "../../../Common/Components/IconsComponent/IconArrowNextRight";
import IconDownload from "../../../Common/Components/IconsComponent/IconDownload";
import IconDrivethru from "../../../Common/Components/IconsComponent/IconDrivethru";
import IconSearch from "../../../Common/Components/IconsComponent/IconSearch";
import { GetDateRangeList } from "../../../Services/GetDateRangeList";
import { getLocationLabelIcon } from "../../../Services/LabelFilterIcons";
import callApi, { handleLoader } from "../../../Services/callApi";
import { validateSearch } from "../../../Services/validation";

import ConfirmModal from "../../../Common/Components/Molecule/ModalBlock/ConfirmModal";
import ServerPaginationComponent from "../../../Common/Components/ServerPaginationComponent";

import { driveThru } from "../../../Constants/MetaDetails";
import { colourStyles } from "../../../Services/colourStyles";
import { FilterQueryParams } from "../../../Services/filterWithQueryParams";
import { getDefaultDate } from "../../../Services/getDefaultDate";
import { getHeaders } from "../../../Services/getHeaders";
import { showAlert } from "../../../Services/showAlert";
import CarCard from "../LocationDetailsComponent/CarCard";
import VehiclesVerificationModal from "../VehiclesListComponent/VehiclesVerificationModal";
import style from "./LatestCars.module.scss";

function CustomerJourneysComponent(props) {
  const [visibility, setVisibility] = useState(false);
  const [page, setPage] = useState(0);
  const defaultLimit = { label: 1, value: 1 };
  const [selectedLimit, setSelectedLimit] = useState(defaultLimit);
  const [limit, setLimit] = useState(20);
  const [vehicleLoading, setVehicleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [journeyData, setJourneyData] = useState([]);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [locationFilterList, setLocationFilterList] = useState([]);
  const [carsLoading, setCarsLoading] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const selectedDateRange = getQueryVariable("range");
  const history = useHistory();
  const location = useLocation();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [updateCar, setUpdateCar] = useState({});
  const [modalVisibility, setModalVisibility] = useState(false);
  const [deleteJourneyId, setDeleteJourneyId] = useState("");
  const [selectedRow, setSelectedRow] = useState({});

  const [query, setQuery] = useState({
    query: "",
    sortBy: "",
    sort: 1,
    customQuery: "",
    selectedDateRange: "",
  });
  const [selectedLocationFilter, setSelectedLocationFilter] = useState(null);
  const [showDateRange, setShowDateRange] = useState(false);

  const dateRangeList = GetDateRangeList(Intl.DateTimeFormat()?.resolvedOptions()?.timeZone);
  const defaultDate = getDefaultDate(dateRangeList);

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: queryParams.get("from")
        ? new Date(`${queryParams.get("from")}`)
        : new Date(defaultDate.date[0]),
      endDate: queryParams.get("to")
        ? new Date(`${queryParams.get("to")}`)
        : new Date(defaultDate.date[1]),
      key: "selection",
    },
  ]);
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    label: "Custom",
    value: [
      queryParams.get("from") ? queryParams.get("from") : defaultDate.date[0],
      queryParams.get("to") ? queryParams.get("to") : defaultDate.date[1],
    ],
  });

  const handleModalClose = () => setShow(false);
  const handleModalShow = () => setShow(true);
  const userData = useSelector((state) => state.userData);
  const defaultOptionValue = { label: "All Locations", value: "" };
  const getCustomDateValue = () => {
    const from = queryParams.get("from");
    const to = queryParams.get("to");

    const result = {
      label: "Custom",
      value: [from, to],
    };
    return result;
  };
  const getVehiclesData = async (dateSelected, locationId, query, limit = 10, page = 0) => {
    const startDate = moment(dateSelected?.value[0]).format("YYYY-MM-DD");
    const endDate = moment(dateSelected?.value[1]).format("YYYY-MM-DD");
    setVehicleLoading(true);
    let url = `${API_BASE_URL}/customer/journey/list/${limit}/${page}?from=${startDate}&to=${endDate}&location=${locationId}`;

    if (query) {
      url += `&sort=${query.sort}&sortBy=${query.sortBy}&query=${query.query}`;
    }

    try {
      const response = await callApi(
        url,
        {
          method: "GET",
        },
        API_CONFIG_SHOWLOADER
      );
      if (response.status === 200) {
        setVehiclesData(response.data);
      }
    } catch (e) {
      showAlert(e, "error");
    } finally {
      setVehicleLoading(false);
    }
  };
  const getJourneyData = async (formattedDate, journeyId, locationId) => {
    // const startDate = moment(selectedDateFilter.value[0]).format("YYYY-MM-DD");
    // const endDate = moment(selectedDateFilter.value[1]).format("YYYY-MM-DD");
    setLoading(true);
    const url = `${API_BASE_URL}/customer/journey/verifier/list?journeyId=${journeyId}&location=${locationId}&date=${formattedDate}&limit=${selectedLimit.value}`;
    // let url =
    //   "https://api-app-staging.wobot.ai/app/v1/verify/journey/list?journeyId=63ff3404497df9b104370b01&location=64217dafbaef2002c0f5f305&date=2023-03-16&limit=10";

    try {
      const response = await callApi(
        url,
        {
          method: "GET",
        },
        API_CONFIG_NO_MANIFEST_CALL
      );
      if (response.status === 200) {
        let newStations = response?.data?.stations;

        newStations = newStations
          .sort((a, b) => a.orderNo - b.orderNo)
          .map((station) => station?.station);
        setJourneyData({
          data: response?.data?.data,
          stations: newStations,
        });
      }
    } catch (e) {
      showAlert(e, "error");
    } finally {
      setLoading(false);
    }
  };
  const deleteHandler = async () => {
    const url = `${API_BASE_URL}/journey/delete/${deleteJourneyId}`;
    const call = () => {
      getVehiclesData(selectedDateFilter, selectedLocationFilter, query, limit, page);
      setModalVisibility(false);
      setDeleteJourneyId("");
    };
    try {
      const data = await callApi(url, {
        method: "DELETE",
      });
      if (data) {
        call();
        showAlert("Journey has been deleted");
      }
    } catch (e) {
      call();
      showAlert(e, "error");
    }
  };
  const onImageClose = () => {
    setOpenLightBox(false);

    setImageIndex(0);
  };

  const setFilterOptionByUrlQueryParams = () => {
    const filterController = new FilterQueryParams(userData, queryParams);

    if (userData && userData.manifest) {
      filterController.setLocationFilter(setSelectedLocationFilter);
    }
  };

  const handleSearchData = (selectedDate, locationFilter, paramQuery) => {
    if (locationFilter) {
      const startDate = moment(selectedDate?.value[0])?.format("YYYY-MM-DD");
      const endDate = moment(selectedDate?.value[1])?.format("YYYY-MM-DD");
      const searchFields = {
        location: locationFilter?.value,
        sort: paramQuery?.sort,
        sortBy: paramQuery?.sortBy,
        query: paramQuery?.query,
      };

      let queryString = "";
      for (const key in searchFields) {
        if (searchFields[key]) queryString += `${key}=${searchFields[key]}&`;
      }

      const newQuery = {
        ...paramQuery,
        customQuery: `${queryString}from=${startDate}&to=${endDate}`,
        historyQuery: `${queryString}location=${locationFilter?.value}&from=${selectedDate?.value[0]}&to=${selectedDate?.value[1]}`,
      };

      setQuery(newQuery);

      history.push({
        pathname: location.pathname,
        search: newQuery.historyQuery,
      });

      getVehiclesData(selectedDate, locationFilter?.value, paramQuery, limit, page);
    }

    // getDriveThruData(limit, 1, newQuery);
  };

  const onDateChange = (ranges) => {
    const { selection } = ranges;

    const dateValue = {
      label: "Custom",
      value: [
        moment(selection.startDate).format("MM-DD-YYYY"),
        moment(selection.endDate).format("MM-DD-YYYY"),
      ],
    };
    setSelectedDateFilter(dateValue);
    handleSearchData(dateValue, selectedLocationFilter, query);
  };
  const initializeFields = () => {
    if (userData) {
      const newLocationList = [defaultOptionValue];
      userData.manifest.regions.map((element) => {
        if (element.type === "location") {
          const locationObj = {
            label: element.area,
            value: element._id,
          };
          newLocationList.push(locationObj);
        }
      });
      setLocationFilterList(newLocationList);

      const defaultDate = dateRangeList.find((element) => element.range === "Last 7 Days");
      if (search) {
        setFilterOptionByUrlQueryParams();
        const queryLocation = newLocationList.find(
          (el) => el.value === queryParams.get("location")
        );
        const queryDate = getCustomDateValue();
        const newQuery = {
          query: queryParams.get("query") || "",
          customQuery: queryParams.get("customQuery") || "",
          sort: queryParams.get("sort") || 1,
          sortBy: queryParams.get("sortBy") || "",
        };
        handleSearchData(queryDate, queryLocation || defaultOptionValue, newQuery);
      } else {
        const newSelectedDate = {
          label: defaultDate.range,
          value: defaultDate.date,
        };
        setSelectedDateFilter(newSelectedDate);
        setSelectedLocationFilter(defaultOptionValue);
        handleSearchData(newSelectedDate, defaultOptionValue, query);
      }

      if (!queryParams.get("location")) {
        setSelectedLocationFilter(defaultOptionValue);
      }
    }
  };

  const downloadCSV = async (dateFilter, locationFilter) => {
    try {
      handleLoader(false, "Downloading Report");
      showAlert("Your report download will begin shortly");
      const response = await fetch(
        `${API_BASE_URL}/download/drivethru/journey?location=${locationFilter.value}&from=${moment(
          dateFilter.value[0],
          ["MM-DD-YYYY", "YYYY-MM-DD"]
        ).format("YYYY-MM-DD")}&to=${moment(dateFilter.value[1], [
          "MM-DD-YYYY",
          "YYYY-MM-DD",
        ]).format("YYYY-MM-DD")}`,
        { headers: getHeaders() }
      );
      const tmp = await response.text();
      if (tmp) {
        const url = window.URL.createObjectURL(new Blob([tmp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "DriveThru_List.csv");
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      showAlert(e, "error");
    } finally {
      handleLoader(false, "");
    }
  };

  useEffect(() => {
    initializeFields();
  }, []);

  useEffect(() => {
    handleSearchData(selectedDateFilter, selectedLocationFilter, query);
  }, [page, limit]);

  return (
    <>
      {vehicleLoading && false ? (
        <TaskTypeEventSkeleton />
      ) : (
        <div className="wobot-panel-main">
          <div className="main-body-wrapper">
            {/* Page Header & Metadata */}
            <PageTitle
              pageTitle="Car Journeys"
              showSubTitle
              subTitle={driveThru.CarJourneys.subtitle}
              breadcrumb={[
                {
                  name: "DriveThru",
                  link: `${DRIVE_LIST}?from=${selectedDateFilter?.value[0]}&to=${selectedDateFilter?.value[1]}`,
                },
                {
                  name: "Location",
                  link: `${`/drivethru/location-detail/${
                    selectedLocationFilter?.value || "All"
                  }?from=${selectedDateFilter?.value[0]}&to=${selectedDateFilter?.value[1]}`}`,
                },
                { name: "Car Journeys" },
              ]}
            >
              <li className="list-inline-item">
                <div className="search-filter w-100">
                  <DebounceInput
                    className="search form-control"
                    placeholder="Search"
                    autoFocus
                    debounceTimeout={DEBOUNCE_INPUT_TIME}
                    onChange={(e) => {
                      if (validateSearch(e.target.value)) {
                        const currValue = e.target.value;
                        // setApiConfig({
                        //   showLoader: false,
                        //   callManifest: false,
                        //   loaderLabel: "",
                        // });
                        setQuery({ ...query, query: currValue });
                        handleSearchData(selectedDateFilter, selectedLocationFilter, {
                          ...query,
                          query: currValue,
                        });
                        setPage(0);
                      } else {
                        e.target.value = "";
                        showAlert(
                          "Characters including ( \\ ? * ( ) [ ) are not allowed.",
                          "warning"
                        );
                      }
                    }}
                    onInput={(e) => {
                      if (!validateSearch(e.target.value)) {
                        e.target.value = "";
                        setQuery({ ...query, query: "" });
                        showAlert(
                          "Characters including ( \\ ? * ( ) [ ) are not allowed.",
                          "warning"
                        );
                      }
                    }}
                    value={query.query}
                  />
                  <IconSearch />
                </div>
              </li>
            </PageTitle>
            {/* END */}

            <div className={`${style.switch_block}${" mb-4"}`}>
              <div className={`${style.switch_card} ${"border-blue"}`}>
                <div className={`${style.content_block}`}>
                  <div className={`${style.icon_block_round} ${"icon-bg-primary"}`}>
                    <div className={`${style.icon_wrapper}`}>
                      <IconDrivethru />
                    </div>
                  </div>
                  <div className={`${style.card_title}`}>
                    <h4 className={`${"mb-0"}`}>{vehiclesData.total} Vehicles</h4>
                  </div>
                  <button className={`${"btn p-0"} ${style.arrowBtn}`}>
                    <span>
                      <IconArrowNextRight />
                    </span>
                  </button>
                </div>
              </div>
            </div>

            {/* Page Filter */}

            <div className="d-flex justify-content-between w-100 align-items-end">
              {" "}
              <div>
                <li className="list-inline-item" key="daterange">
                  <div>
                    <DateFilterComponent
                      selectionRange={selectionRange}
                      setSelectionRange={setSelectionRange}
                      onDateChange={onDateChange}
                      showDateRange={showDateRange}
                      startDate={moment(queryParams.get("from")).format("MMM DD, YYYY")}
                      endDate={moment(queryParams.get("to")).format("MMM DD, YYYY")}
                      setShowDateRange={setShowDateRange}
                    />
                  </div>
                </li>
                <li className={`${"list-inline-item"}`}>
                  <div>
                    <Select
                      styles={colourStyles}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={{
                        label: getLocationLabelIcon(selectedLocationFilter?.label),
                        value: selectedLocationFilter?.value,
                      }}
                      options={locationFilterList}
                      className={`${"react-select"} ${style.locationFilter}`}
                      onChange={(newValue) => {
                        if (selectedLocationFilter?.value !== newValue?.value) {
                          setSelectedLocationFilter(newValue);
                          handleSearchData(selectedDateFilter, newValue, query);
                        }
                      }}
                    />
                  </div>
                </li>
              </div>
              {vehiclesData?.data?.length > 0 ? (
                <li className="list-inline-item ">
                  <button
                    type="button"
                    onClick={() => downloadCSV(selectedDateFilter, selectedLocationFilter)}
                    className="btn btn-textIcon"
                  >
                    <span>
                      <IconDownload /> Download Report
                    </span>
                  </button>
                </li>
              ) : (
                <></>
              )}
            </div>

            {carsLoading ? (
              <div className="col-12  ">
                <div className="vehicleLoading-cameras">
                  {Array(3)
                    .fill()
                    .map((e, index) => (
                      <SkeletonGrid key={index} />
                    ))}
                </div>
              </div>
            ) : vehiclesData?.data?.length > 0 ? (
              <div className={`infinite-block ${style.cars_container}`}>
                <div className="grid-block">
                  <div className="row">
                    {vehiclesData?.data?.map((car) => (
                      <CarCard
                        setUpdateCar={setUpdateCar}
                        setSelectedRow={setSelectedRow}
                        getJourneyData={(formattedDate, journeyId, locationId) => {
                          getJourneyData(formattedDate, journeyId, locationId);
                        }}
                        showModal={() => handleModalShow()}
                        userData={props.userData}
                        data={car}
                        setDeleteJourneyId={setDeleteJourneyId}
                        setModalVisibility={setModalVisibility}
                      />
                    ))}
                  </div>

                  {vehiclesData?.data?.length > 0 ? (
                    <div className={`${style.cars_container_pagination}`}>
                      <ServerPaginationComponent
                        limit={limit}
                        setLimit={setLimit}
                        page={page}
                        setPage={setPage}
                        data={vehiclesData?.data ? vehiclesData?.data : []}
                        limitOptions
                        callApi
                        totalAmount={vehiclesData?.total}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {/* </InfiniteScroll> */}
              </div>
            ) : (
              <ZeroStateComponent />
            )}
          </div>

          {/* {openLightBox && (
            <Lightbox
              mainSrc={imageList[imageIndex]}
              nextSrc={imageIndex < imageList.length - 1}
              prevSrc={imageIndex > 0}
              onCloseRequest={() => onImageClose()}
              onMovePrevRequest={() => {
                if (imageIndex > 0) setImageIndex(imageIndex - 1);
              }}
              onMoveNextRequest={() => {
                if (imageIndex < imageList.length - 1)
                  setImageIndex(imageIndex + 1);
              }}
            />
          )} */}
          {/* Verification Modal */}
          <VehiclesVerificationModal
            modalSize="xl"
            visibility={show}
            loading={loading}
            selectedLimit={selectedLimit}
            setSelectedLimit={setSelectedLimit}
            getVehiclesData={() => {
              getVehiclesData(selectedDateFilter, selectedLocationFilter.value, query, limit, page);
            }}
            setImageList={setImageList}
            updateCar={updateCar}
            journeyData={journeyData}
            onHide={() => {
              setSelectedLimit(defaultLimit);
              handleModalClose();
            }}
            setOpenLightBox={setOpenLightBox}
          />

          <ConfirmModal
            visibility={modalVisibility}
            secondaryBtn="Cancel"
            confirmButtonText="Yes, Delete"
            actionCondition="delete"
            className="confirm-message d-block"
            onYes={deleteHandler}
            onNo={() => {
              setModalVisibility(false);
            }}
            onHide={() => {
              setModalVisibility(false);
            }}
            modalTitle="Delete Journey?"
            modalSubTitle="All the data will be erased for the selected journey and will
            no longer be visible"
          />
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => state;

export default connect(mapStateToProps, null)(CustomerJourneysComponent);
