import React from "react";

const IconHome = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 17.9983C18 18.2635 17.8946 18.5179 17.7071 18.7054C17.5196 18.893 17.2652 18.9983 17 18.9983H1C0.734784 18.9983 0.48043 18.893 0.292893 18.7054C0.105357 18.5179 2.4071e-07 18.2635 2.4071e-07 17.9983V7.48832C-0.000105484 7.33593 0.0346172 7.18554 0.101516 7.04862C0.168415 6.9117 0.26572 6.79188 0.386 6.69832L8.386 0.476319C8.56154 0.339764 8.7776 0.265625 9 0.265625C9.2224 0.265625 9.43846 0.339764 9.614 0.476319L17.614 6.69832C17.7343 6.79188 17.8316 6.9117 17.8985 7.04862C17.9654 7.18554 18.0001 7.33593 18 7.48832V17.9983ZM16 16.9983V7.97632L9 2.53232L2 7.97632V16.9983H16ZM4 12.9983H14V14.9983H4V12.9983Z"
        fill="white"
      />
    </svg>
  );
};

export default IconHome;
