import React from "react";

const IconAddCamera = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3335 3.33301C13.7935 3.33301 14.1668 3.70634 14.1668 4.16634V7.66634L18.511 4.62467C18.6993 4.49301 18.9593 4.53884 19.0918 4.72801C19.1402 4.79801 19.1668 4.88134 19.1668 4.96634V15.033C19.1668 15.263 18.9802 15.4497 18.7502 15.4497C18.6643 15.4497 18.581 15.423 18.511 15.3747L14.1668 12.333V15.833C14.1668 16.293 13.7935 16.6663 13.3335 16.6663H1.66683C1.20683 16.6663 0.833496 16.293 0.833496 15.833V4.16634C0.833496 3.70634 1.20683 3.33301 1.66683 3.33301H13.3335ZM12.5002 4.99967H2.50016V14.9997H12.5002V4.99967ZM6.66683 6.66634H8.3335V9.16634H10.8335V10.833H8.33266L8.3335 13.333H6.66683L6.666 10.833H4.16683V9.16634H6.66683V6.66634ZM17.5002 7.36717L14.1668 9.70051V10.2988L17.5002 12.6322V7.36634V7.36717Z"
        fill="#3766E8"
      />
    </svg>
  );
};

export default IconAddCamera;
