import PropTypes from "prop-types"; // Import PropTypes
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./StyleTabLink.module.scss";

const renderCount = (count) => (count ? `(${count})` : null);
const renderText = (name, link, isActive, count) => {
  if (isActive || !link) {
    return (
      <span className={`${!link ? (isActive ? "cursor-default" : "cursor-pointer") : ""}`}>
        {name} {renderCount(count)}
      </span>
    );
  }
  return (
    <NavLink to={link}>
      {name} {renderCount(count)}
    </NavLink>
  );
};

function TabItem({ name, link, count, isActive, onClick }) {
  return (
    <li
      className={`list-inline-item   ${isActive ? styles.active_List : ""}`}
      onClick={() => !isActive && !link && onClick()}
    >
      {renderText(name, link, isActive, count)}
    </li>
  );
}

/**
 * TabLink component for rendering a list of tabs.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.tabList - The list of tab objects.
 * @param {string} props.currentPath - The current URL path.
 * @param {number} props.count - Total data counts.
 */
function TabLink({ tabList, currentPath, counts, onClick, borderBottom = false, className }) {
  return (
    <div
      className={`${styles.tab_container} ${className} ${
        borderBottom && styles.tab_container_extra
      }`}
    >
      <ul className="list-inline mb-0">
        {tabList?.map((item) => {
          if (item.hide) {
            return <span />;
          }
          return (
            <TabItem
              key={item.key}
              name={item.name}
              link={item.link}
              count={
                item.link
                  ? counts?.find((route) => route.name === item.key)?.count || 0
                  : item.counts
              }
              isActive={item.link ? currentPath === item.link : currentPath === item.name}
              onClick={item.link ? onClick : item.onClick}
            />
          );
        })}
      </ul>
    </div>
  );
}

TabLink.propTypes = {
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentPath: PropTypes.string.isRequired,
  counts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

TabLink.defaultProps = {
  onClick: () => {},
  className: "",
};

export default TabLink;
