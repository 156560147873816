import PropTypes from "prop-types";
import React from "react";
import IconClose from "../../../Common/Components/IconsComponent/IconClose";
import styles from "../Styles.module.scss";

function RenderSelectedFile({ handleRemoveFile, selectedFiles }) {
  return (
    <div
      className={`${styles["file-upload"]} ${
        selectedFiles ? styles["file-upload-preview-open"] : ""
      }`}
    >
      {selectedFiles ? (
        <div
          rel="button"
          className={styles["image-wrapper"]}
          onClick={() => handleRemoveFile(selectedFiles.name)}
        >
          <img src={URL.createObjectURL(selectedFiles)} alt={`Selected ${selectedFiles.name}`} />
          <IconClose size="12" />
        </div>
      ) : null}
    </div>
  );
}
RenderSelectedFile.propTypes = {
  handleRemoveFile: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedFiles: PropTypes.array.isRequired,
};
export default RenderSelectedFile;
