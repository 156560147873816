import React from "react";

const IconTicketing = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66663 7.91667V3.33333C1.66663 3.11232 1.75442 2.90036 1.9107 2.74408C2.06698 2.5878 2.27895 2.5 2.49996 2.5H17.5C17.721 2.5 17.9329 2.5878 18.0892 2.74408C18.2455 2.90036 18.3333 3.11232 18.3333 3.33333V7.91667C17.7808 7.91667 17.2509 8.13616 16.8602 8.52686C16.4695 8.91756 16.25 9.44747 16.25 10C16.25 10.5525 16.4695 11.0824 16.8602 11.4731C17.2509 11.8638 17.7808 12.0833 18.3333 12.0833V16.6667C18.3333 16.8877 18.2455 17.0996 18.0892 17.2559C17.9329 17.4122 17.721 17.5 17.5 17.5H2.49996C2.27895 17.5 2.06698 17.4122 1.9107 17.2559C1.75442 17.0996 1.66663 16.8877 1.66663 16.6667V12.0833C2.21916 12.0833 2.74906 11.8638 3.13977 11.4731C3.53047 11.0824 3.74996 10.5525 3.74996 10C3.74996 9.44747 3.53047 8.91756 3.13977 8.52686C2.74906 8.13616 2.21916 7.91667 1.66663 7.91667ZM3.33329 6.64C3.9593 6.95025 4.48619 7.42921 4.85455 8.02288C5.22291 8.61656 5.41809 9.30133 5.41809 10C5.41809 10.6987 5.22291 11.3834 4.85455 11.9771C4.48619 12.5708 3.9593 13.0498 3.33329 13.36V15.8333H16.6666V13.36C16.0406 13.0498 15.5137 12.5708 15.1454 11.9771C14.777 11.3834 14.5818 10.6987 14.5818 10C14.5818 9.30133 14.777 8.61656 15.1454 8.02288C15.5137 7.42921 16.0406 6.95025 16.6666 6.64V4.16667H3.33329V6.64ZM7.49996 7.5H12.5V9.16667H7.49996V7.5ZM7.49996 10.8333H12.5V12.5H7.49996V10.8333Z"
        fill="#7E7E7E"
      />
    </svg>
  );
};

export default IconTicketing;
