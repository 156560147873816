import React from "react";

const IconQuestation = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0C5.37001 0 0 5.37001 0 12C0 18.6299 5.37001 23.9999 12 23.9999C18.6299 23.9999 24 18.63 24 12C24 5.36996 18.63 0 12 0ZM13.2 20.4H10.8V18H13.2V20.4ZM15.678 11.106L14.604 12.21C13.74 13.074 13.2 13.8 13.2 15.6H10.8V15C10.8 13.674 11.34 12.474 12.204 11.604L13.698 10.092C14.13 9.66003 14.4 9.06001 14.4 8.40003C14.4 7.07405 13.326 6.00001 12 6.00001C10.6739 6.00001 9.59996 7.07399 9.59996 8.40003H7.19999C7.19999 5.74801 9.34801 3.6 12 3.6C14.6519 3.6 16.7999 5.74801 16.7999 8.39997C16.8 9.45601 16.374 10.41 15.678 11.106Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconQuestation;
