import React, { useEffect, useMemo, useRef, useState } from "react";
import Avatar from "react-avatar";
import { DebounceInput } from "react-debounce-input";
import { DEBOUNCE_INPUT_TIME } from "../../../../../Constants";
import IconCorrect from "../../../../Components/IconsComponent/IconCorrect";
import IconSearch from "../../../../Components/IconsComponent/IconSearch";
import ModalBlock from "../../../../Components/Molecule/ModalBlock";
import styles from "../NavbarComponent/Navbar.module.scss";

function MultiCompanyAccessModal({
  showCompanies,
  setShowCompanies,
  handleSwitchCompany,
  companies,
}) {
  const [searchInput, setSearchInput] = useState("");
  const inputRef = useRef(null);

  const handleSearchInputChange = (e) => setSearchInput(e.target.value);

  const filteredAndSortedCompanies = useMemo(() => {
    const lowercasedInput = searchInput.toLowerCase();
    const filteredCompanies = companies.filter(
      (company) =>
        company.name.toLowerCase().includes(lowercasedInput) ||
        company.email.toLowerCase().includes(lowercasedInput)
    );
    return filteredCompanies.sort((a, b) => {
      if (a.selected === b.selected) return 0;
      return a.selected ? -1 : 1;
    });
  }, [companies, searchInput]);

  useEffect(() => {
    if (showCompanies && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showCompanies]);

  return (
    <ModalBlock
      modalSize="sm"
      isOpen={showCompanies}
      onHide={() => {
        setShowCompanies(false);
      }}
      centered
      isHeightMaxContent
      dialogBodyClassName="bg-white pt-0"
      headerTitle="Switch Company"
      headerSubtitle="Select the company you want to access"
    >
      <div className={`search-filter pb-2 my-1 ${styles.searchContainer}`}>
        <DebounceInput
          inputRef={inputRef}
          className="search form-control"
          autoFocus
          placeholder="Search"
          debounceTimeout={DEBOUNCE_INPUT_TIME}
          onChange={handleSearchInputChange}
          value={searchInput}
        />
        <IconSearch />
      </div>
      <div
        id="scrollableDiv"
        className={`${styles.boxWrapper} element-modal-content config-table vertical-scroll visible-scroll`}
      >
        <ul className={`${styles.companies_list}`}>
          {filteredAndSortedCompanies?.length > 0 ? (
            filteredAndSortedCompanies?.map((company) => (
              <li
                key={company._id}
                className={`fs-14  ${
                  company?.selected ? `${styles.active} primary-color fw-500 ` : "fw-400"
                } `}
                onClick={() => handleSwitchCompany(company)}
              >
                <Avatar
                  maxInitials={2}
                  name={company?.name}
                  size="32"
                  round
                  className="avatar-size "
                  src={company?.logo}
                />
                <div className={styles.companyInfo}>
                  <div className={styles.companyName}>
                    <span className={`${styles.companyName} line_clamp`}>{company.name}</span>
                    {company?.selected ? <IconCorrect colour="#3766E8" /> : null}
                  </div>
                  <span className={`${styles.companyEmail} line_clamp`}>{company.email}</span>
                </div>
              </li>
            ))
          ) : (
            <div className={styles.zeroState}>There are no records to display</div>
          )}
        </ul>
      </div>
    </ModalBlock>
  );
}

export default MultiCompanyAccessModal;
