import { string } from "prop-types";
import React from "react";

function IconNVR({ color }) {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_61660_2315)">
        <path
          d="M7.81944 7.18834C7.81944 7.91275 7.22871 8.5 6.5 8.5C5.77129 8.5 5.18056 7.91275 5.18056 7.18834C5.18056 6.46393 5.77129 5.87668 6.5 5.87668C7.22871 5.87668 7.81944 6.46393 7.81944 7.18834Z"
          fill={color}
        />

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 5.09866C2 3.9396 2.94518 3 4.11111 3H19.8889C21.0548 3 22 3.9396 22 5.09866V9.29598C22 10.455 21.0548 11.3946 19.8889 11.3946H4.11111C2.94518 11.3946 2 10.455 2 9.29598V5.09866ZM4 5H20V9.29598H4V5Z"
          fill={color}
        />

        <path
          d="M6.54015 18.1345C7.26886 18.1345 7.85959 17.5473 7.85959 16.8229C7.85959 16.0985 7.26886 15.5112 6.54015 15.5112C5.81144 15.5112 5.2207 16.0985 5.2207 16.8229C5.2207 17.5473 5.81144 18.1345 6.54015 18.1345Z"
          fill={color}
        />

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 14.704C2 13.545 2.94518 12.6054 4.11111 12.6054H19.8889C21.0548 12.6054 22 13.545 22 14.704V18.9013C22 20.0604 21.0548 21 19.8889 21H4.11111C2.94518 21 2 20.0604 2 18.9013V14.704ZM4 14.704H20V19H4V14.704Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
IconNVR.propTypes = {
  color: string,
};

IconNVR.defaultProps = {
  color: "currentColor",
};
export default IconNVR;
