import React from "react";

const IconAirplane = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9487 2.97177L15.2324 15.5256C15.0275 16.4117 14.493 16.6322 13.7336 16.2148L9.59484 13.2259L7.59779 15.1082C7.37679 15.3248 7.19195 15.5059 6.76602 15.5059L7.06337 11.3751L14.7341 4.58235C15.0677 4.29095 14.6618 4.1295 14.2158 4.4209L4.7328 10.2725L0.650295 9.02031C-0.237731 8.7486 -0.253804 8.15004 0.835133 7.73263L16.8035 1.70378C17.5429 1.43207 18.1898 1.86523 17.9487 2.97177Z"
        fill="white"
      />
    </svg>
  );
};

export default IconAirplane;
