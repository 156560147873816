import React from "react";
import Skeleton from "react-loading-skeleton";
// import "./style.scss";
function CameraGridSkeleton({ hideHeader }) {
  return (
    <>
      <div
        hidden={hideHeader}
        className="list-inline-item w-100 mb-1 grid-section-container py-2 px-4 bg-white"
      >
        <Skeleton width="80px" height="33px" />
      </div>

      <div className="panel-body bg-white p-2">
        <div className="grid-section-container">
          <div className="skeleton-grid row mb-4 px-2">
            {Array(4)
              .fill()
              .map((e, index) => (
                <div className="mb-2  col-xl-3 col-lg-4" key={index}>
                  <Skeleton width="100%" height={260} />
                </div>
              ))}
          </div>
          <div className="skeleton-grid row px-2">
            {Array(4)
              .fill()
              .map((e, index) => (
                <div className="mb-2  col-xl-3 col-lg-4" key={index}>
                  <Skeleton width="100%" height={260} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CameraGridSkeleton;
