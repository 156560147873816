import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "wobotui";
import IconClose from "../../IconsComponent/IconClose";
import styles from "./Styles.module.scss";

const smallSizeModalUnits = ["sm", "smd"];

const renderButtons = (buttonList) => {
  if (!buttonList?.length) {
    return null;
  }
  const isPrimaryButton = (button) => (button.isPrimary ? 1 : 0);
  const sortedButtons = [...buttonList].sort((a, b) => isPrimaryButton(a) - isPrimaryButton(b));
  return (
    <>
      {sortedButtons.map(({ name, onClick, icon, isDisabled, isHidden = false, isPrimary,classes = [] }) => {
        if (isHidden) {
          return null;
        }
        return (
          <li key={name} className="list-inline-item">
            <Button
              type="button"
              btnSize="small"
              variant={isPrimary ? "primary" : "tertiary"}
              onClick={onClick}
              disabled={isDisabled}
              className={(classes || []).join(",")}
            >
              <span>
                {typeof icon === "function" ? icon() : icon} {name}
              </span>
            </Button>
          </li>
        );
      })}
    </>
  );
};
/**
 * Modal component.
 *
 * @param {Object} props - The component's props.
 * @param {("sm"|"md"|"lg"|"xl"|"2xl"|"3xl"|"4xl"|"5xl"|"6xl"|"fullScreen")} [props.modalSize] -
 *  The size of the modal.
 * @param {boolean} props.isOpen - Controls the visibility of the modal.
 * @param {Function} props.onHide - Callback when the modal is closed.
 * @param {boolean} props.fullscreen - To show modal in full width.
 * @param {boolean} props.centered - To show the modal in center.
 * @param {string} props.dialogClassName - Additional CSS classes for the modal dialog.
 * @param {string} props.headerTitle - The title of the modal.
 * @param {string} props.headerTitleSubText - Text to show next to title.
 * @param {string} props.headerSubtitle - Text for the bottom element.
 * @param {string} props.headerChildren - Items to show on header .
 * @param {ReactNode} props.children - The content to display.
 * @param {boolean} props.isFooter - Whether to display the modal footer.
 * @param {boolean} props.isHeightMaxContent - If true modal's height will be based on the content else full height will be applied.

 * @param {Object} props.buttons - An object containing header and footer buttons.
 * @param {Button} props.buttons.headerButtons - Buttons to display in the header.
 * @param {Button} props.buttons.footerButtons - Buttons to display in the footer.
 * @typedef {Object[]} Button
 * @property {string} Button.name - The name of the button.
 * @property {Function} Button.onClick - Callback function when the button is clicked.
 * @property {Node} Button.icon - (Optional) icon component to display alongside the button.
 * @property {string[]} Button.classes - Array of CSS classes for styling the button.
 * @property {boolean} Button.isPrimary - If primary, align to the right.
 * @property {boolean} Button.isDisabled -
 * @property {boolean} Button.isHidden -
 *
 */

function ModalBlock(props) {
  const {
    modalSize,
    fullscreen,
    centered,
    isOpen,
    onHide,
    dialogClassName,
    headerTitle,
    headerTitleSubText,
    headerSubtitle,
    buttons,
    children,
    isFooter,
    dialogBodyClassName,
    headerChildren,
    isHeightMaxContent,
  } = props;
  const buttonMap = {
    header: buttons?.headerButtons || [],
    footer: buttons?.footerButtons || [],
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      size={modalSize}
      fullscreen={fullscreen}
      centered={centered}
      show={isOpen}
      onHide={onHide}
      dialogClassName={`checklist-modal config-modal ${styles.modal} ${
        centered ? styles["modal-dialog-centered"] : ""
      } ${styles[`custom-${modalSize}-modal`]} ${
        isHeightMaxContent ? styles["max-content-height"] : ""
      } ${dialogClassName}`}
    >
      <Modal.Header
        className={`border-0 mb-0 align-items-top d-flex align-items-start ${styles.header}`}
      >
        <div className="modal-header-block">
          <div className="header-title justify-content-start mb-1 flex-1">
            <h5
              className={`mb-0 title-tag fw-500 ${
                smallSizeModalUnits.includes(modalSize) ? "fs-20" : "fs-22"
              }`}
            >
              {headerTitle}
            </h5>
            {headerTitleSubText && (
              <span className="ml-2 fs-14 text-other">{headerTitleSubText}</span>
            )}
          </div>
          {headerSubtitle && <p className="mb-0 fs-14 fw-400 text-other">{headerSubtitle}</p>}
        </div>
        {/* Header buttons */}
        <div className="modal-header-action">
          <ul className="list-inline mb-0 d-flex align-items-center">
            {headerChildren && headerChildren}
            {renderButtons(buttonMap.header)}
            <li className={`${"list-inline-item"}`}>
              <button
                type="button"
                className={`${"btn btn-single-icon btn-lg"} ${styles.close_btn}`}
                onClick={onHide}
              >
                <span>
                  <IconClose className="mr-0" />
                </span>
              </button>
            </li>
          </ul>
        </div>
      </Modal.Header>
      <Modal.Body className={`${styles.body} ${dialogBodyClassName}`}>{children}</Modal.Body>
      {isFooter && (
        <Modal.Footer className="pure-white-bg p-4">
          <ul className="lint-inline">{renderButtons(buttonMap.footer)}</ul>
        </Modal.Footer>
      )}
    </Modal>
  );
}
ModalBlock.propTypes = {
  modalSize: PropTypes.oneOf([
    "sm",
    "md",
    "lg",
    "xl",
    "2xl",
    "3xl",
    "4xl",
    "5xl",
    "6xl",
    "fullScreen",
  ]),
  isOpen: PropTypes.bool.isRequired,
  fullscreen: PropTypes.bool,
  centered: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  dialogClassName: PropTypes.string,
  dialogBodyClassName: PropTypes.string,
  headerTitle: PropTypes.string.isRequired,
  headerTitleSubText: PropTypes.string,
  headerSubtitle: PropTypes.string.isRequired,
  headerChildren: PropTypes.node,
  buttons: PropTypes.shape({
    headerButtons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        classes: PropTypes.arrayOf(PropTypes.string).isRequired,
        isPrimary: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        icon: PropTypes.node,
        isDisabled: PropTypes.bool,
        isHidden: PropTypes.bool,
      })
    ).isRequired,
    footerButtons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        classes: PropTypes.arrayOf(PropTypes.string).isRequired,
        isPrimary: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        icon: PropTypes.node,
      })
    ).isRequired,
  }),
  children: PropTypes.node.isRequired,
  isFooter: PropTypes.bool,
  isHeightMaxContent: PropTypes.bool,
};
ModalBlock.defaultProps = {
  modalSize: "4xl",
  fullscreen: false,
  centered: false,
  dialogClassName: "",
  dialogBodyClassName: "",
  headerTitleSubText: "",
  headerChildren: null,
  buttons: {},
  isFooter: false,
  isHeightMaxContent: false,
};
export default ModalBlock;
