import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";
import "./styles.scss";

function PersonSuiteSkeleton({ fullPageSkeleton }) {
  return (
    <div className={fullPageSkeleton ? "m-20" : ""}>
      <PageTitleSkeleton />
      <div className="">
        <Skeleton width={700} height={40} />
      </div>
      <div className="metrics_card">
        {Array(4)
          .fill()
          .map((e) => (
            <div key={e} className="metrics_skeleton">
              <Skeleton height={20} width={100} className="mb-3" />
              <Skeleton height={30} width={70} className="mb-3" />
              <Skeleton height={18} width={120} />
            </div>
          ))}
      </div>
      <div className="store_overview">
        <Skeleton height={320} width="100%" className="mt-4" />
      </div>
      <div className="insights">
        <div className="store_overview">
          <Skeleton height={320} width="100%" className="mt-4" />
        </div>
        <div className="store_overview">
          <Skeleton height={320} width="100%" className="mt-4" />
        </div>
        <div className="store_overview">
          <Skeleton height={320} width="100%" className="mt-4" />
        </div>
      </div>
    </div>
  );
}

export default PersonSuiteSkeleton;
PersonSuiteSkeleton.propTypes = {
  fullPageSkeleton: PropTypes.bool,
};

PersonSuiteSkeleton.defaultProps = {
  fullPageSkeleton: false,
};
