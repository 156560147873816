function IconGoLast(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={props?.color ? props.color : "black"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
      <path fill="none" d="M0 0h24v24H0V0z"></path>
    </svg>
  );
}

export default IconGoLast;
