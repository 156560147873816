import React from "react";

const IconLive = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99998 8.67481C8.89499 8.67481 7.99996 9.5698 7.99996 10.6748C7.99996 11.7799 8.89499 12.6748 9.99998 12.6748C11.105 12.6748 12 11.7798 12 10.6748C12 9.56975 11.105 8.67481 9.99998 8.67481ZM16 10.6748C16 7.35978 13.315 4.67479 9.99998 4.67479C6.68498 4.67479 3.99998 7.35978 3.99998 10.6748C3.99998 12.8948 5.205 14.8248 6.99496 15.8648L8.00498 14.1248C6.80999 13.4298 6 12.1548 6 10.6748C6 8.46477 7.79001 6.6748 9.99998 6.6748C12.2099 6.6748 14 8.46481 14 10.6748C14 12.1548 13.19 13.4298 11.995 14.1198L13.005 15.8598C14.795 14.8248 16 12.8948 16 10.6748ZM9.99998 0.674805C4.47501 0.674805 0 5.14982 0 10.6748C0 14.3698 2.01 17.5898 4.995 19.3198L5.99498 17.5898C3.60998 16.2098 1.99997 13.6298 1.99997 10.6748C1.99997 6.2548 5.57995 2.67477 9.99998 2.67477C14.42 2.67477 18 6.2548 18 10.6748C18 13.6298 16.39 16.2098 14.005 17.5948L15.005 19.3248C17.99 17.5898 20 14.3698 20 10.6748C20 5.14982 15.52 0.674805 9.99998 0.674805Z"
        fill="white"
      />
    </svg>
  );
};

export default IconLive;
