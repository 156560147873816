import React from "react";

function IconCameraTwo() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 3.75H12.6225L11.25 2.25H6.75L5.3775 3.75H3C2.175 3.75 1.5 4.425 1.5 5.25V14.25C1.5 15.075 2.175 15.75 3 15.75H15C15.825 15.75 16.5 15.075 16.5 14.25V5.25C16.5 4.425 15.825 3.75 15 3.75ZM15 14.25H3V5.25H6.0375L6.48 4.7625L7.41 3.75H10.59L11.52 4.7625L11.9625 5.25H15V14.25ZM9 6C6.93 6 5.25 7.68 5.25 9.75C5.25 11.82 6.93 13.5 9 13.5C11.07 13.5 12.75 11.82 12.75 9.75C12.75 7.68 11.07 6 9 6ZM9 12.15C7.6725 12.15 6.6 11.0775 6.6 9.75C6.6 8.4225 7.6725 7.35 9 7.35C10.3275 7.35 11.4 8.4225 11.4 9.75C11.4 11.0775 10.3275 12.15 9 12.15Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconCameraTwo;
