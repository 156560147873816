import React from "react";

const IconRightArrow = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.58984 16.59L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.59Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export default IconRightArrow;
