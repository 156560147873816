import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { settings } from "../../../Constants/MetaDetails";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";

const StationComponent = lazy(() => import("../../Components/StationComponent"));

function StationComponentContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.station}
      meta={settings?.station?.subtitle}
      withLayout={false}
    >
      <StationComponent />
    </PageContainer>
  );
}

export default StationComponentContainer;
