import React from "react";

function WobotLogoSvg() {
  return (
    <svg
      width="140"
      height="31"
      viewBox="0 0 140 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.6125 21.8384H19.7111C19.7111 21.8384 19.1776 21.9732 18.6442 21.0797L5.49176 0.546432C5.49176 0.546432 5.20837 0.0744027 4.57492 0.023828C3.94147 -0.0267466 3.52473 0.512716 3.42471 0.765589L0.207446 6.58167C0.207446 6.58167 -0.259307 7.15485 0.207446 8.03148L13.7933 30.1832C13.7933 30.1832 14.1267 30.9418 14.7768 30.9755C15.4269 31.0092 23.9952 30.9755 23.9952 30.9755C23.9952 30.9755 24.6286 31.0261 25.0787 30.2337C25.5288 29.4414 28.7294 23.6253 28.7294 23.6253C28.7294 23.6253 29.2962 21.8721 27.6125 21.8384Z"
        fill="#3766E8"
      />
      <path
        d="M48.1997 21.8384H40.2982C40.2982 21.8384 39.7648 21.9732 39.2313 21.0797L26.0956 0.546432C26.0956 0.546432 25.8122 0.0744027 25.1787 0.023828C24.5453 -0.0267466 24.1285 0.512716 24.0285 0.765589L20.8113 6.58167C20.8113 6.58167 20.3445 7.15485 20.8113 8.03148L34.3971 30.1832C34.3971 30.1832 34.7305 30.9418 35.3806 30.9755C36.0308 31.0092 44.599 30.9755 44.599 30.9755C44.599 30.9755 45.2325 31.0261 45.6825 30.2337C46.1326 29.4414 49.3332 23.6253 49.3332 23.6253C49.3332 23.6253 49.8833 21.8721 48.1997 21.8384Z"
        fill="#3766E8"
      />
      <path
        d="M43.9989 0.630724L40.8816 6.34566C40.8816 6.34566 40.2482 7.15485 40.6816 7.87975C41.115 8.60466 48.4831 20.5908 48.4831 20.5908C48.4831 20.5908 48.8331 21.3832 49.6333 21.3663C50.4167 21.3326 50.6168 20.8774 50.8835 20.3548C51.1502 19.8491 54.1174 14.4039 54.1174 14.4039C54.1174 14.4039 54.5175 13.7801 54.0508 13.0721C53.584 12.3472 46.0993 0.630724 46.0993 0.630724C46.0993 0.630724 45.7659 -0.0773209 44.9324 0.00697016C44.2489 0.0912612 43.9989 0.630724 43.9989 0.630724Z"
        fill="#3766E8"
      />
      <path
        d="M98.5923 15.6177C94.3916 15.6177 90.9909 19.0567 90.9909 23.305C90.9909 27.5533 94.3916 30.9924 98.5923 30.9924C102.793 30.9924 106.194 27.5533 106.194 23.305C106.194 19.0567 102.776 15.6177 98.5923 15.6177ZM98.5923 29.8629C95.0083 29.8629 92.1078 26.9295 92.1078 23.305C92.1078 19.6805 95.0083 16.7472 98.5923 16.7472C102.176 16.7472 105.077 19.6805 105.077 23.305C105.077 26.9127 102.176 29.8629 98.5923 29.8629Z"
        fill="#3766E8"
      />
      <path
        d="M65.0194 15.6177C60.8187 15.6177 57.418 19.0567 57.418 23.305C57.418 27.5533 60.8187 30.9924 65.0194 30.9924C69.2202 30.9924 72.6209 27.5533 72.6209 23.305C72.6042 19.0567 69.2036 15.6177 65.0194 15.6177ZM65.0194 29.8629C61.4355 29.8629 58.5349 26.9295 58.5349 23.305C58.5349 19.6805 61.4355 16.7472 65.0194 16.7472C68.6034 16.7472 71.504 19.6805 71.504 23.305C71.504 26.9127 68.6034 29.8629 65.0194 29.8629Z"
        fill="#3766E8"
      />
      <path
        d="M82.206 15.6177C79.5221 15.6177 77.1717 17.0338 75.8215 19.1579V11.2345C75.8215 10.8974 75.5547 10.6276 75.2213 10.6276C74.8879 10.6276 74.6212 10.8974 74.6212 11.2345V30.3686C74.6212 30.7058 74.8879 30.9755 75.2213 30.9755C75.5547 30.9755 75.8215 30.7058 75.8215 30.3686V27.4353C77.1717 29.5594 79.5221 30.9755 82.206 30.9755C86.4067 30.9755 89.8074 27.5364 89.8074 23.2882C89.7907 19.0567 86.3901 15.6177 82.206 15.6177ZM82.206 29.8629C78.9887 29.8629 76.3382 27.5027 75.8215 24.4008V22.2092C76.3382 19.1073 79.0054 16.7472 82.206 16.7472C85.79 16.7472 88.6905 19.6805 88.6905 23.305C88.6905 26.9127 85.79 29.8629 82.206 29.8629Z"
        fill="#3766E8"
      />
      <path
        d="M135.633 23.2882C135.633 19.0399 132.232 15.6008 128.031 15.6008C123.83 15.6008 120.43 19.0399 120.43 23.2882C120.43 27.5364 123.83 30.9755 128.031 30.9755C130.782 30.9755 133.182 29.492 134.516 27.2667V30.4192C134.516 30.7226 134.766 30.9755 135.066 30.9755C135.366 30.9755 135.616 30.7226 135.616 30.4192V24.0299C135.616 23.9793 135.599 23.9288 135.583 23.8782C135.616 23.6928 135.633 23.4905 135.633 23.2882ZM128.031 29.8629C124.447 29.8629 121.547 26.9295 121.547 23.305C121.547 19.6805 124.447 16.7472 128.031 16.7472C131.615 16.7472 134.516 19.6805 134.516 23.305C134.516 26.9127 131.615 29.8629 128.031 29.8629Z"
        fill="#3766E8"
      />
      <path
        d="M113.478 15.7357H111.095V11.0154C111.095 10.6782 110.828 10.4085 110.495 10.4085C110.161 10.4085 109.894 10.6782 109.894 11.0154V15.7525H107.511C107.227 15.7525 106.994 15.9886 106.994 16.2751C106.994 16.5617 107.227 16.7977 107.511 16.7977H109.894V30.3855C109.894 30.7226 110.161 30.9924 110.495 30.9924C110.828 30.9924 111.095 30.7226 111.095 30.3855V16.7809H113.478C113.762 16.7809 113.995 16.5449 113.995 16.2583C113.995 15.9717 113.762 15.7357 113.478 15.7357Z"
        fill="#3766E8"
      />
      <path
        d="M139.8 30.9755H138.666C138.65 30.9755 138.633 30.9586 138.633 30.9418V15.82C138.633 15.8031 138.65 15.7863 138.666 15.7863H139.8C139.817 15.7863 139.833 15.8031 139.833 15.82V30.9418C139.833 30.9586 139.817 30.9755 139.8 30.9755Z"
        fill="#3766E8"
      />
      <path
        d="M139.233 13.1227C139.657 13.1227 140 12.7755 140 12.3472C140 11.9189 139.657 11.5717 139.233 11.5717C138.81 11.5717 138.466 11.9189 138.466 12.3472C138.466 12.7755 138.81 13.1227 139.233 13.1227Z"
        fill="#3766E8"
      />
      <path
        d="M117.212 30.9924C117.636 30.9924 117.979 30.6452 117.979 30.2169C117.979 29.7886 117.636 29.4414 117.212 29.4414C116.789 29.4414 116.446 29.7886 116.446 30.2169C116.446 30.6452 116.789 30.9924 117.212 30.9924Z"
        fill="#3766E8"
      />
    </svg>
  );
}

export default WobotLogoSvg;
