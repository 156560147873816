import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

function SignupSkeleton({ rowReverse, width, height, bannerWidth }) {
  return (
    <div className={`d-flex ${rowReverse ? "flex-row-reverse" : "flex-row"}`}>
      <div>
        <Skeleton width={bannerWidth} height="100vh" />
      </div>
      <div className="d-flex justify-content-center align-items-center w-100">
        <Skeleton width={width} height={height} />
      </div>
    </div>
  );
}

export default SignupSkeleton;

SignupSkeleton.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  rowReverse: PropTypes.bool,
  bannerWidth: PropTypes.string,
};

SignupSkeleton.defaultProps = {
  height: 440,
  width: 400,
  rowReverse: false,
  bannerWidth: "40vw",
};
