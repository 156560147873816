import React from "react";

function IconErrorDisplay() {
  return (
    <svg
      width="240"
      height="222"
      viewBox="0 0 240 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="120" cy="157.167" rx="115.131" ry="60.2149" fill="#F5F5F5" />
      <path
        d="M25.6852 140.398L115.854 82.432C118.313 80.8516 121.468 80.8542 123.924 82.4386L213.771 140.405C218.412 143.399 218.297 150.224 213.558 153.06L123.711 206.84C121.358 208.248 118.422 208.25 116.067 206.846L25.8977 153.066C21.1456 150.232 21.0308 143.39 25.6852 140.398Z"
        fill="#CED4DA"
      />
      <path
        d="M211.646 130.848L125.644 81.2047C123.854 80.3072 121.879 79.8398 119.876 79.8398C117.874 79.8398 115.899 80.3072 114.108 81.2047L28.1068 130.848C27.4517 131.141 26.8865 131.603 26.4694 132.187C26.0524 132.771 25.7986 133.455 25.7344 134.17V140.427C25.7966 141.146 26.0492 141.835 26.4662 142.424C26.8831 143.013 27.4494 143.481 28.1068 143.778L114.108 193.363C115.893 194.278 117.87 194.756 119.876 194.756C121.882 194.756 123.86 194.278 125.644 193.363L211.646 143.778C212.303 143.481 212.87 143.013 213.287 142.424C213.704 141.835 213.956 141.146 214.018 140.427V134.17C213.954 133.455 213.701 132.771 213.283 132.187C212.866 131.603 212.301 131.141 211.646 130.848Z"
        fill="#4B75EA"
      />
      <path
        opacity="0.1"
        d="M214.018 134.171V140.429C213.956 141.148 213.704 141.837 213.287 142.426C212.87 143.015 212.303 143.482 211.646 143.78L125.644 193.364C123.881 194.324 121.898 194.804 119.891 194.758V79.842C121.893 79.8038 123.872 80.2732 125.644 81.2062L211.646 130.85C212.301 131.142 212.866 131.604 213.283 132.188C213.7 132.772 213.954 133.457 214.018 134.171Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M119.951 79.8426V194.758C117.935 194.805 115.941 194.324 114.168 193.365L28.1662 143.78C27.5088 143.483 26.9425 143.016 26.5255 142.427C26.1085 141.838 25.8559 141.148 25.7937 140.429V134.172C25.858 133.457 26.1117 132.773 26.5288 132.189C26.9458 131.605 27.5111 131.143 28.1662 130.85L114.168 81.2068C115.948 80.2689 117.938 79.7994 119.951 79.8426Z"
        fill="black"
      />
      <path
        d="M114.167 187.136L28.1658 137.522C27.4714 137.283 26.869 136.833 26.4426 136.235C26.0162 135.636 25.7871 134.92 25.7871 134.186C25.7871 133.451 26.0162 132.735 26.4426 132.137C26.869 131.539 27.4714 131.089 28.1658 130.85L114.167 81.2355C115.955 80.3289 117.931 79.8564 119.935 79.8564C121.94 79.8564 123.916 80.3289 125.703 81.2355L211.705 130.85C212.399 131.089 213.002 131.539 213.428 132.137C213.854 132.735 214.084 133.451 214.084 134.186C214.084 134.92 213.854 135.636 213.428 136.235C213.002 136.833 212.399 137.283 211.705 137.522L125.703 187.136C123.914 188.038 121.939 188.508 119.935 188.508C117.932 188.508 115.956 188.038 114.167 187.136Z"
        fill="#4B75EA"
      />
      <path
        opacity="0.5"
        d="M114.167 187.136L28.1658 137.522C27.4714 137.283 26.869 136.833 26.4426 136.235C26.0162 135.636 25.7871 134.92 25.7871 134.186C25.7871 133.451 26.0162 132.735 26.4426 132.137C26.869 131.539 27.4714 131.089 28.1658 130.85L114.167 81.2355C115.955 80.3289 117.931 79.8564 119.935 79.8564C121.94 79.8564 123.916 80.3289 125.703 81.2355L211.705 130.85C212.399 131.089 213.002 131.539 213.428 132.137C213.854 132.735 214.084 133.451 214.084 134.186C214.084 134.92 213.854 135.636 213.428 136.235C213.002 136.833 212.399 137.283 211.705 137.522L125.703 187.136C123.914 188.038 121.939 188.508 119.935 188.508C117.932 188.508 115.956 188.038 114.167 187.136Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M119.95 79.8415C117.935 79.8074 115.945 80.2871 114.167 81.2354L28.1658 130.849C27.4714 131.089 26.869 131.539 26.4426 132.137C26.0162 132.735 25.7871 133.451 25.7871 134.186C25.7871 134.92 26.0162 135.636 26.4426 136.234C26.869 136.832 27.4714 137.283 28.1658 137.522L114.167 187.136C115.948 188.074 117.938 188.543 119.95 188.5V79.8415Z"
        fill="black"
      />
      <path
        d="M173.005 127.914L134.986 13.7098V13.5319C134.196 11.6059 132.73 10.0345 130.864 9.11316C127.514 7.39568 123.804 6.5 120.039 6.5C116.275 6.5 112.565 7.39568 109.215 9.11316C107.357 10.0545 105.887 11.6183 105.063 13.5319L67.0151 127.766C63.5157 137.7 68.4089 148.525 81.843 156.146C103.017 168.364 137.299 168.364 158.444 156.146C171.611 148.584 176.504 137.849 173.005 127.914Z"
        fill="#4B75EA"
      />
      <path
        d="M145.899 46.4506L140.502 30.2289C140.933 31.6724 140.939 33.2096 140.518 34.6562C140.097 36.1027 139.268 37.3972 138.13 38.3842C137.132 39.4022 135.994 40.2712 134.749 40.9643C130.176 43.2829 125.122 44.4913 119.995 44.4913C114.868 44.4913 109.814 43.2829 105.241 40.9643C104.002 40.2626 102.865 39.3945 101.861 38.3842C100.728 37.3869 99.9055 36.0857 99.4903 34.6349C99.075 33.1841 99.0846 31.6447 99.5179 30.1992L94.0908 46.4802C93.6562 47.8404 93.5371 49.2817 93.7427 50.6948C93.9482 52.1078 94.473 53.4555 95.2771 54.6355C96.7949 56.9056 98.827 58.7853 101.208 60.1218C103.285 61.264 105.483 62.1692 107.762 62.8205C115.681 65.0748 124.072 65.0748 131.991 62.8205C134.279 62.1692 136.487 61.264 138.574 60.1218C140.958 58.789 142.991 56.9085 144.506 54.6355C145.355 53.472 145.923 52.1273 146.165 50.707C146.407 49.2867 146.316 47.8297 145.899 46.4506Z"
        fill="#FAFAFA"
      />
      <path
        d="M167.666 111.929L162.269 95.6777C163.134 98.3241 163.237 101.161 162.566 103.863C161.76 106.948 160.177 109.776 157.969 112.077C155.769 114.42 153.23 116.42 150.436 118.008C133.622 127.736 106.338 127.736 89.5236 118.008C86.7211 116.42 84.1721 114.42 81.9613 112.077C79.7723 109.767 78.2016 106.941 77.3944 103.863C76.7055 101.172 76.8084 98.3405 77.6909 95.7074L72.2639 111.929V112.077C71.3896 114.714 71.1857 117.527 71.6708 120.262C72.768 126.431 77.424 132.451 85.6387 137.196C104.618 148.168 135.401 148.168 154.381 137.196C162.595 132.451 167.221 126.431 168.348 120.262C168.841 117.472 168.606 114.602 167.666 111.929Z"
        fill="#FAFAFA"
      />
      <path
        d="M156.783 79.3093L151.356 63.0579C152.244 65.7399 152.064 68.6611 150.852 71.2132C149.078 74.7029 146.272 77.561 142.815 79.3982L142.578 79.5762C135.566 83.0736 127.829 84.8718 119.993 84.8255C112.157 84.7791 104.442 82.8894 97.4716 79.3093L97.2048 79.1313C93.7481 77.2941 90.9418 74.436 89.1681 70.9463C87.9713 68.3904 87.791 65.4749 88.6639 62.791L83.2665 79.0424C82.3627 81.706 82.3627 84.5934 83.2665 87.257C84.4539 90.462 86.5071 93.2749 89.1977 95.3827C90.5546 96.4918 92.0031 97.4838 93.5275 98.3483C98.0252 100.827 102.891 102.569 107.94 103.508C115.943 105.05 124.166 105.05 132.169 103.508C137.221 102.58 142.088 100.837 146.582 98.3483C148.12 97.4911 149.579 96.4987 150.941 95.3827C153.624 93.2674 155.675 90.4569 156.872 87.257C157.674 84.6635 157.642 81.8841 156.783 79.3093Z"
        fill="#FAFAFA"
      />
      <path
        opacity="0.1"
        d="M119.951 6.50337C116.179 6.43754 112.453 7.33574 109.126 9.113C107.268 10.0543 105.798 11.6182 104.974 13.5318L66.9262 127.766C63.4269 137.7 68.3201 148.525 81.7541 156.146C93.536 162.417 106.725 165.571 120.069 165.31H119.743C111.484 163.882 104.109 159.286 99.1897 152.5C94.2705 145.714 92.1961 137.275 93.4088 128.981L109.245 15.2814L119.951 6.50337Z"
        fill="black"
      />
      <path
        d="M109.127 21.5983C103.195 18.1582 103.195 12.5532 109.127 9.11316C112.476 7.39568 116.187 6.5 119.951 6.5C123.715 6.5 127.426 7.39568 130.775 9.11316C136.706 12.5532 136.706 18.1582 130.775 21.5983C127.426 23.3158 123.715 24.2114 119.951 24.2114C116.187 24.2114 112.476 23.3158 109.127 21.5983Z"
        fill="#4B75EA"
      />
      <path
        opacity="0.5"
        d="M109.127 21.5983C103.195 18.1582 103.195 12.5532 109.127 9.11316C112.476 7.39568 116.187 6.5 119.951 6.5C123.715 6.5 127.426 7.39568 130.775 9.11316C136.706 12.5532 136.706 18.1582 130.775 21.5983C127.426 23.3158 123.715 24.2114 119.951 24.2114C116.187 24.2114 112.476 23.3158 109.127 21.5983Z"
        fill="white"
      />
    </svg>
  );
}

export default IconErrorDisplay;
