import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PAGE404, POS_ORDER_DETAILS, POS_ORDER_LIST } from "../Constants";

import Layout from "../Common/Containers/_layouts/Home";
import PosListSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/PosListSkeleton/PosListSkeleton";
import PosOrderDetailsSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/PosOrderDetailsSkeleton/PosOrderDetailsSkeleton";

const PosOrderDetails = lazy(() => import("./Containers/PosOrderDetails"));
const PosOrderList = lazy(() => import("./Containers/PosOrderList"));

function LossPreventionModule() {
  return (
    <Layout>
      <Switch>
        <Route exact path={POS_ORDER_LIST}>
          <Suspense fallback={<PosListSkeleton />}>
            <PosOrderList />
          </Suspense>
        </Route>
        <Route exact path={POS_ORDER_DETAILS}>
          <Suspense fallback={<PosOrderDetailsSkeleton fullPageSkeleton />}>
            <PosOrderDetails />
          </Suspense>
        </Route>
        <Route path="*" render={() => <Redirect to={{ pathname: PAGE404 }} />} />
      </Switch>
    </Layout>
  );
}

export default LossPreventionModule;
