import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";

function TaskTypeSkeleton() {
  return (
    <>
      <PageTitleSkeleton />
      <div className="list-inline-item w-100 mb-4">
        <Skeleton height={30} width="800px" />
      </div>
    </>
  );
}

export default TaskTypeSkeleton;
