import React, { lazy, Suspense } from "react";
import { analytics } from "../../Constants/MetaDetails";
import { checkWobotDomain, PAGE_TITLES } from "../../Services/checkWobotDomain";
import AnalyticsSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/AnalyticsSkeleton";
import PageContainer from "../../Common/Containers/_layouts/PageContainer";

const TasksReportComponent = lazy(
  () => import("../Components/TicketsReportComponent/TasksReportComponent")
);

function TasksReport(props) {
  return (
    <PageContainer
      initialPageTitle={checkWobotDomain(PAGE_TITLES.analytics)}
      meta={
        props?.module === "event" ? `${analytics?.events?.title}` : `${analytics?.tickets?.title}`
      }
    >
      <Suspense fallback={<AnalyticsSkeleton key="loader" />}>
        <TasksReportComponent />
      </Suspense>
    </PageContainer>
  );
}

export default TasksReport;
