import React from "react";

const IconUser = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99935 1.50033C6.73268 1.50033 7.33268 2.10033 7.33268 2.83366C7.33268 3.56699 6.73268 4.16699 5.99935 4.16699C5.26602 4.16699 4.66602 3.56699 4.66602 2.83366C4.66602 2.10033 5.26602 1.50033 5.99935 1.50033ZM5.99935 8.16699C7.79935 8.16699 9.86602 9.02699 9.99935 9.50033H1.99935C2.15268 9.02032 4.20602 8.16699 5.99935 8.16699ZM5.99935 0.166992C4.52602 0.166992 3.33268 1.36033 3.33268 2.83366C3.33268 4.30699 4.52602 5.50033 5.99935 5.50033C7.47268 5.50033 8.66602 4.30699 8.66602 2.83366C8.66602 1.36033 7.47268 0.166992 5.99935 0.166992ZM5.99935 6.83366C4.21935 6.83366 0.666016 7.72699 0.666016 9.50033V10.8337H11.3327V9.50033C11.3327 7.72699 7.77935 6.83366 5.99935 6.83366Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconUser;
