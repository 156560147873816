import React from "react";
import Skeleton from "react-loading-skeleton";
import BlocksComponent from "../../../../Molecule/Block";
import PageTitleSkeleton from "../../../../Molecule/PageTitleSkeleton";
const MainTaskDetailSkeleton = () => {
  return (
    <div className="wobot-panel-main ">
      <div className="main-body-wrapper ">
        <PageTitleSkeleton />
        <div>
          <Skeleton className={"mb-5"} height={10} width={300} />
          <Skeleton className={"mb-5"} height={10} width={300} />
        </div>
        <div>
          <Skeleton className={"mb-5"} height={20} width={300} />
        </div>
        <div className="row mt-5">
          {/*First Block*/}
          {Array(3)
            .fill()
            .map((e, index) => (
              <div className={"col-xl-4 col-lg-6 col-md-6"} key={index}>
                <BlocksComponent>
                  <div className={"element-section mb-0 panel-fh"}>
                    <div className={"element-content-wrapper"}>
                      <div className={"d-flex align-self-center"}>
                        <div className={"element-content-block"}>
                          <h6 className={"text-primary mb-1"}>
                            <Skeleton height={20} width={100} />
                          </h6>
                          <p className={"mb-0 fw-500"}>
                            <Skeleton />
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <tbody className={"data-table-list"}> */}
                    <Skeleton
                      count={4}
                      // width={300}
                      style={{ marginBottom: "15px" }}
                    />
                    {/* </tbody> */}
                  </div>
                </BlocksComponent>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MainTaskDetailSkeleton;
