import { ADD_AUTH_TOKEN } from "../constants";

const initialState = null;

export default function authTokenReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_AUTH_TOKEN:
      return action.value;
    default:
      return state;
  }
}
