import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getLocationLabelIcon, getSearchLabelIcon } from "../../../Services/LabelFilterIcons";
import { colourStyles } from "../../../Services/colourStyles";
import { locationsOptions, SEARCH_OPTIONS } from "../../Resources";
import { setSelectedOptions } from "../../State/assistantAction";
import { getCurrentCompanyWithTheirAIFeatures, getSearchOptionsForCompany } from "../../Utils";
import styles from "../Styles.module.scss";

function SearchOptions() {
  const { smartAssistant: { selectedOptions = {}, isAssistantOpen = false } = {} } =
    useSelector((state) => state) || {};
  const dispatch = useDispatch();

  const handleChangeOption = useCallback(
    (option) => {
      dispatch(setSelectedOptions(option));
    },
    [dispatch]
  );

  const accessedOptions = useMemo(
    () => getSearchOptionsForCompany(getCurrentCompanyWithTheirAIFeatures()?.id),
    [isAssistantOpen]
  );
  useEffect(() => {
    if (accessedOptions.length && isAssistantOpen) {
      dispatch(
        setSelectedOptions({
          search: accessedOptions[0],
          location: locationsOptions[0],
        })
      );
    }
  }, [accessedOptions, isAssistantOpen]);

  return (
    <div className={styles["options-container"]}>
      <Select
        styles={colourStyles}
        value={{
          label: getSearchLabelIcon(selectedOptions?.search?.label),
          value: selectedOptions?.search?.value || "",
        }}
        placeholder="Search"
        className="react-select view-select"
        options={accessedOptions}
        components={{
          IndicatorSeparator: () => null,
        }}
        onChange={(option) => {
          handleChangeOption({
            search: option,
          });
        }}
      />
      {selectedOptions?.search?.value === SEARCH_OPTIONS.VIDEO && (
        <Select
          styles={colourStyles}
          value={{
            label: getLocationLabelIcon(selectedOptions?.location?.label),
            value: selectedOptions?.location?.value || "",
          }}
          className="react-select view-select"
          options={locationsOptions}
          components={{
            IndicatorSeparator: () => null,
          }}
          onChange={(option) => {
            handleChangeOption({
              location: option,
            });
          }}
        />
      )}
    </div>
  );
}

export default memo(SearchOptions);
