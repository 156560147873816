import React, { lazy, Suspense } from "react";
import { checkWobotDomain, PAGE_TITLES } from "../../Services/checkWobotDomain";
import TaskListSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/taskSkeleton/TaskListSkeleton";
import PageContainer from "../../Common/Containers/_layouts/PageContainer";

const TaskListComponent = lazy(() => import("../Components/TaskComponents/TaskListComponent"));

function TaskList() {
  return (
    <PageContainer initialPageTitle={checkWobotDomain(PAGE_TITLES.taskList)}>
      <Suspense fallback={<TaskListSkeleton />}>
        <TaskListComponent />
      </Suspense>
    </PageContainer>
  );
}

export default TaskList;
