import React from "react";
import Skeleton from "react-loading-skeleton";
import "../style.scss";

function PosStatsSkeleton() {
  return (
    <>
      <div className="stats-container w-100 mt-4 mb-2">
        <Skeleton height={180} />
        <Skeleton height={180} />
        <Skeleton height={180} />
      </div>
      <div className="d-flex justify-content-end mb-1 mr-3">
        <Skeleton width={200} height={40} />
      </div>
    </>
  );
}

export default PosStatsSkeleton;
