import { ADDDEVICEINFO } from "../constants";

const initialState = null;

export default function deviceInfoReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADDDEVICEINFO:
      return action.value;
    default:
      return state;
  }
}
