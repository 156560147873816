// HeatmapModalContext.js
import React, { createContext, useState, useMemo, useEffect, useRef } from "react";
import { updateURL } from "../Utils";
import { getAllQueryVariables } from "../../../../../Services/getQueryVariable";

const HeatmapContext = createContext();

export function HeatmapProvider({ children }) {
  const { floorId, location: locationId } = getAllQueryVariables();
  const cameraRef = useRef(null);
  const floorMapRef = useRef(null);
  const [uploadModalVisiblity, setUploadModalVisibility] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState({ id: "" });
  const [selectedCamera, setSelectedCamera] = useState({});
  const [selectedCameras, setSelectedCameras] = useState([
    {
      isSelected: true,
      floorMapBgUrl:
        "https://staging-app-input-gcp.wobot.ai/testcase/Frames/09de6e1c-4dda-4dcb-a489-e508df34ad93.jpeg",
      cameraBgUrl:
        "https://staging-app-input-gcp.wobot.ai/testcase/Frames/09de6e1c-4dda-4dcb-a489-e508df34ad93.jpeg",
      cameraRoi: [],
      position: [],
    },
    {
      isSelected: false,
      floorMapBgUrl:
        "https://staging-app-input-gcp.wobot.ai/testcase/Frames/09de6e1c-4dda-4dcb-a489-e508df34ad93.jpeg",
      cameraBgUrl:
        "https://staging-app-input-gcp.wobot.ai/testcase/Frames/09de6e1c-4dda-4dcb-a489-e508df34ad93.jpeg",
      cameraRoi: [],
      position: [
        [0.6539115871320057, 0.26610591900311525],
        [0.7366519478397044, 0.5851090342679127],
        [0.4036967613773989, 0.6349532710280373],
        [0.2531691171983326, 0.60371753918419],
        [0.2820783998552394, 0.9127518070969626],
        [0.47846214755905436, 0.8509449535144081],
        [0.3289313751957435, 0.5478919940128505],
      ],
    },
  ]);
  const [floormapFile, setFloormapFile] = useState({});

  const [currentFloorConfig, setCurrentFloorConfig] = useState({ config: [] });

  useEffect(() => {
    if (floorId !== selectedFloor?.id) {
      updateURL(selectedFloor.id);
    }
  }, [selectedFloor]);

  const contextValue = useMemo(
    () => ({
      selectedCameras,
      setSelectedCameras,
      uploadModalVisiblity,
      setUploadModalVisibility,
      selectedFloor,
      setSelectedFloor,
      currentFloorConfig,
      setCurrentFloorConfig,
      cameraRef,
      floorMapRef,
      selectedCamera,
      setSelectedCamera,
      floormapFile,
      setFloormapFile,
      locationId,
    }),
    [
      selectedCameras,
      uploadModalVisiblity,
      selectedFloor,
      currentFloorConfig,
      cameraRef,
      floorMapRef,
      selectedCamera,
      floormapFile,
      locationId,
    ]
  );

  return <HeatmapContext.Provider value={contextValue}>{children}</HeatmapContext.Provider>;
}

export default HeatmapContext;
