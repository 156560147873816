import moment from "moment";
import { driveThru, walkIn, carWash } from "../../Constants/MetaDetails";
import { store } from "../../Store";

const CAR_WASH = "Car Wash";

const createStationData = (stationData) => {
  if (stationData) {
    const data = JSON.parse(JSON.stringify(stationData));
    Object.values(data).forEach((arr) =>
      arr.unshift({
        label: "All",
        value: "all",
        isSelected: !arr.some((el) => el.isSelected),
      })
    );
    return data;
  }
};
export const getDriveThruConfig = () => {
  const {
    userData: { manifest },
  } = store.getState();
  return manifest?.driveThruConfig;
};

export const carWashConfig = () => {
  const {
    userData: { manifest },
  } = store.getState();
  const isCarWash = manifest?.driveThruConfig?.label === CAR_WASH;
  const label = manifest?.driveThruConfig?.label;

  return {
    isCarWash,
    label,
  };
};

export default createStationData;
export const getGoalColor = (goalScore) => {
  if (goalScore < 40) {
    return "red-failure-text";
  }
  if (goalScore > 70) {
    return "green-success-text";
  }
  return "yellow-text";
};

export const getPageDetail = (type, isPersonSuite, isCarWash, includeLocation = false) => {
  let detail;

  if (isPersonSuite) {
    detail = walkIn;
  } else if (isCarWash) {
    detail = carWash;
  } else {
    detail = driveThru;
  }

  let selectedDetail = detail;

  if (includeLocation && detail.location) {
    selectedDetail = detail.location;
  }

  switch (type) {
    case "title":
      return selectedDetail.title || "";
    case "subtitle":
      return detail.subtitle || "";
    case "breadcrumb":
      return selectedDetail.breadcrumb || "";
    default:
      return "";
  }
};

export const getShiftInfo = (data) => {
  if (!data) return "-";
  let { startTime, endTime, shift } = data;
  if (!startTime || !endTime || !shift) return "-";
  startTime = moment(startTime, "HH:mm").format("hh:mm A");
  endTime = moment(endTime, "HH:mm").format("hh:mm A");
  return `${shift} (${startTime} - ${endTime})`;
};
