function IconStation(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3577 10.9091C6.35355 10.9091 5.53952 11.7231 5.53952 12.7273V14.5455C5.53952 15.5496 6.35355 16.3636 7.3577 16.3636H14.6304C15.6346 16.3636 16.4486 15.5496 16.4486 14.5455V12.7273C16.4486 11.7231 15.6346 10.9091 14.6304 10.9091H7.3577ZM7.3577 12.7273H14.6304V14.5455H7.3577V12.7273Z"
        fill="#545454"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.787182 6.75028C0.500562 7.70568 1.03459 8.6661 1.90636 8.98251C1.90425 9.01837 1.90317 9.05451 1.90317 9.09091V18.1818C1.90317 19.186 2.7172 20 3.72136 20H18.2668C19.271 20 20.085 19.186 20.085 18.1818V9.09091C20.085 9.03877 20.0828 8.98716 20.0785 8.93614C20.9072 8.56843 21.3825 7.60333 21.0566 6.67209L19.1475 1.21754C18.8923 0.488296 18.204 0 17.4314 0H4.16505C3.36212 0 2.65426 0.526669 2.42355 1.29573L0.787182 6.75028ZM17.4314 1.81818L4.16505 1.81818L2.52868 7.27273L19.3405 7.27273L17.4314 1.81818ZM3.72136 18.1818V9.09091H18.2668V18.1818H3.72136Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconStation;
