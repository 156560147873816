import PropTypes from "prop-types";
import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../../Molecule/PageTitleSkeleton";

function SettingsModuleWrapperSkeleton({ children }) {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper crumb-cursor-type">
        <div className="body_header">
          <PageTitleSkeleton />
        </div>
        <div className="body_container">
          <div className="content_sidebar">
            <Skeleton width={200} count={7} height={50} />
          </div>
          <div className="body_content row">{children}</div>
        </div>
      </div>
    </div>
  );
}

SettingsModuleWrapperSkeleton.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

SettingsModuleWrapperSkeleton.defaultProps = {
  children: undefined,
};

export default SettingsModuleWrapperSkeleton;
