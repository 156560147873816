import React, { useCallback, useEffect, useState } from "react";

import { connect } from "react-redux";

import PageTitle from "../../../Common/Components/Molecule/PageTitle";
import { CHECKLIST, COMPLIANCE_RUN, LIVE_MONITORING } from "../../../Constants";

import style from "./style.module.scss";
import { getLocationLabelIcon } from "../../../Services/LabelFilterIcons";
import { colourStyles } from "../../../Services/colourStyles";
import DateFilterComponent from "../../../Common/Components/DateFilter/DateFilterComponent";
import moment from "moment";
import Select from "react-select";
import FilterComponent from "./Components/FilterComponent";
import DetailDataBlock from "./Components/DetailDataBlock";
import ZoneDataComponent from "./Components/ZoneDataComponent";
import useCompanyLocationSocket from "../../../Common/Sockets/Hooks/useCompanyLocationSocket";
import useCompanyLocationRoomSocket from "../../../Common/Sockets/Hooks/useCompanyLocationRoomSocket";
import useHandleFilter from "./Hooks/useHandleFilter";
import { getAllQueryVariables } from "../../../Services/getQueryVariable";
import useGetMonitoringData from "./Hooks/useGetMonitoringData";
import { store } from "../../../Store";
import { getTaskDetailsLink, updateStateData } from "./Utils";
import LiveMonitoringSkeleton from "../../../Common/Components/SkeletonComponent/DetailsSkeleton/LiveMonitoringSkeleton";
import DataSkeletonComponent from "../../../Common/Components/SkeletonComponent/DetailsSkeleton/LiveMonitoringSkeleton/DataSkeletonComponent";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import ActionBlock from "../../../Common/Components/Molecule/ActionBlock";
import { GetDateRangeList } from "../../../Services/GetDateRangeList";
import { getDefaultDate } from "../../../Services/getDefaultDate";

function LiveMonitoringComponent(props) {
  const { taskId, LocationId: locationId } = getAllQueryVariables();
  const storeCompanyId = store?.getState()?.userData?.manifest?.id;
  const dateRangeList = GetDateRangeList(Intl.DateTimeFormat()?.resolvedOptions()?.timeZone);
  const defaultDateRange = getDefaultDate(dateRangeList);
  const { loadingLocationData, selectedLocation, setSelectedLocation, locationData, checklist } =
    useHandleFilter();
  const { monitoringData, loadingMonitoringData, setMonitoringData } =
    useGetMonitoringData(selectedLocation);
  const updateData = useCallback(
    (update) => {
      const cameraId = update.data.camera;
      setMonitoringData((prevState) => {
        let updatedState = prevState;
        update?.data?.incidentInfo?.forEach((element) => {
          updatedState = updateStateData(updatedState, cameraId, element.group, element.value);
        });

        return updatedState;
      });
    },
    [setMonitoringData]
  );
  useCompanyLocationSocket({
    handleDataReducer: updateData,
  });
  useCompanyLocationRoomSocket({
    selectedLocationId: locationId,
    companyId: storeCompanyId,
  });

  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <PageTitle
          pageTitle="Live Occupancy"
          showSubTitle={false}
          breadcrumb={[
            { name: "Tasks", link: CHECKLIST },
            {
              name: "Details",
              link: getTaskDetailsLink(taskId, locationId, defaultDateRange),
            },
            { name: "Live Occupancy" },
          ]}
        >
          {checklist && checklist._id && checklist.referenceId ? (
            <ActionBlock showActionList={false} showActionBtn>
              <li className="list-inline-item ">
                <Link
                  className="btn btn-primary btn-md btn-text-icon"
                  to={getTaskDetailsLink(taskId, locationId, defaultDateRange)}
                >
                  <span>View Analytics</span>
                </Link>
              </li>
            </ActionBlock>
          ) : null}
        </PageTitle>

        {!loadingLocationData ? (
          <div className="container-wrapper mt-4">
            <FilterComponent
              locationData={locationData}
              setSelectedLocation={setSelectedLocation}
              selectedLocation={selectedLocation}
            />
          </div>
        ) : (
          <Skeleton width={200} height={40} />
        )}

        {!loadingMonitoringData && !loadingLocationData ? (
          <>
            <DetailDataBlock
              monitoringData={monitoringData}
              checklist={checklist}
              selectedLocation={selectedLocation}
            />
            <ZoneDataComponent zoneData={monitoringData?.cameraInsight} />
          </>
        ) : (
          <DataSkeletonComponent />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, null)(LiveMonitoringComponent);
