import moment from "moment";
import styles from "../style.module.scss";

export const modifyLocationData = (data) =>
  data?.map((location) => ({ label: location.area, value: location._id }));

export function updateURL(locationId) {
  const params = new URLSearchParams(window.location.search);

  // Set the locationId field with the provided value
  if (locationId) {
    params.set("LocationId", locationId);
  }

  const queryString = params.toString();
  const baseUrl = window.location.pathname;
  const updatedURL = `${baseUrl}?${queryString}`;
  window.history.replaceState({}, "", updatedURL);
}

export function updateStateData(stateData, cameraId, type, value) {
  const updatedCameraInsight = stateData.cameraInsight.map((ci) => {
    if (ci.camera._id === cameraId) {
      if (type === "entry") {
        ci.entry += value;
      } else if (type === "exit") {
        ci.exit += value;
      }
    }
    return ci;
  });
  const updatedLocationInsight = {
    ...stateData.locationInsight,
  };
  if (type === "entry") {
    updatedLocationInsight.entryTotalCount += value;
  } else if (type === "exit") {
    updatedLocationInsight.exitTotalCount += value;
  }

  const updatedStateData = {
    ...stateData,
    cameraInsight: updatedCameraInsight,
    locationInsight: updatedLocationInsight,
  };

  return updatedStateData;
}

export const getStatsOccupancyClass = (liveOccupancy, maxAllowed) => {
  const currPer = Math.round((liveOccupancy / maxAllowed) * 100);
  if (currPer > 150) {
    return styles.above150CurrentTotalDetails;
  }
  if (currPer > 100) {
    return styles.above100CurrentTotalDetails;
  }
  if (currPer > 75) {
    return styles.above75CurrentTotalDetails;
  }
  return styles.currentTotalDetails;
};

export const getOccupancyClass = (liveOccupancy, maxAllowed) => {
  // const currPer = Math.round((liveOccupancy / maxAllowed) * 100);
  // if (currPer > 150) {
  //   return styles.above150CurrentOccupancy;
  // }
  // if (currPer > 100) {
  //   return styles.above100CurrentOccupancy;
  // }
  // if (currPer > 75) {
  //   return styles.above75CurrentOccupancy;
  // }
  return styles.currentOccupancy;
};

export const getTaskDetailsLink = (taskId, locationId, defaultDateRange) => {
  const formattedFromDate = moment(defaultDateRange?.date[0], "MM-DD-YYYY").format("YYYY-MM-DD");
  const formattedToDate = moment(defaultDateRange?.date[1], "MM-DD-YYYY").format("YYYY-MM-DD");

  return `/tasks/details/${taskId}?modelType=occupancy-based&location=${locationId}&camera=&schedule=&raisedBy=&rangeLabel=&from=${formattedFromDate}&to=${formattedToDate}&range=Last%207%20Days`;
};
