import React from "react";

const IconNext = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.06078 1.96323C4.72604 1.6285 4.72604 1.08579 5.06078 0.751051C5.39551 0.416316 5.93823 0.416316 6.27296 0.751051L11.4158 5.89391C11.7403 6.2184 11.7517 6.7409 11.4416 7.07919L6.72729 12.222C6.40741 12.571 5.86521 12.5946 5.51625 12.2747C5.16729 11.9548 5.14371 11.4126 5.46359 11.0637L9.62332 6.52578L5.06078 1.96323Z"
        fill="white"
      />
      <path
        d="M0.903063 1.96323C0.568327 1.6285 0.568327 1.08579 0.903063 0.751051C1.2378 0.416316 1.78051 0.416316 2.11525 0.751051L7.2581 5.89391C7.5826 6.2184 7.59395 6.7409 7.28386 7.07919L2.56957 12.222C2.24969 12.571 1.70749 12.5946 1.35853 12.2747C1.00957 11.9548 0.986 11.4126 1.30588 11.0637L5.46561 6.52578L0.903063 1.96323Z"
        fill="white"
      />
    </svg>
  );
};

export default IconNext;
