import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DriveThruLocationSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/DriveThruLocationSkeleton";
import MyTaskSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/taskSkeleton/MyTask";
import {
  DETECTION_DETAILS,
  DRIVETHRU_DETAILS,
  DRIVETHRU_VECHICLES,
  DRIVE_LIST,
  DRIVE_LOCATION_DETAILS,
  PAGE404,
} from "../Constants";
import Detection from "./Containers/Detection";
import JourneyDetailsSkeleton from "../Common/Components/SkeletonComponent/DetailsSkeleton/JourneyDetailsSkeleton";
import Layout from "../Common/Containers/_layouts/Home";
import DrivethruListSkeleton from "../Common/Components/SkeletonComponent/MainModulesSkeleton/DriveThruSkeleton";

const DrivethruList = lazy(() => import("./Containers/DrivethruList"));
const LocationDetails = lazy(() => import("./Containers/LocationDetails"));
const VehiclesList = lazy(() => import("./Containers/VehiclesList"));
const DrivethruDetails = lazy(() => import("./Containers/DrivethruDetails"));

function DriveThruModule() {
  return (
    <Layout>
      <Switch>
        <Route path={DETECTION_DETAILS}>
          <Suspense fallback={<DriveThruLocationSkeleton fullPageSkeleton />}>
            <Detection />
          </Suspense>
        </Route>
        <Route exact path={DRIVETHRU_DETAILS}>
          <Suspense fallback={<JourneyDetailsSkeleton />}>
            <DrivethruDetails />
          </Suspense>
        </Route>
        <Route exact path={DRIVETHRU_VECHICLES}>
          <Suspense fallback={<MyTaskSkeleton />}>
            <VehiclesList />
          </Suspense>
        </Route>
        <Route exact path={DRIVE_LOCATION_DETAILS}>
          <Suspense fallback={<DriveThruLocationSkeleton fullPageSkeleton />}>
            <LocationDetails />
          </Suspense>
        </Route>
        <Route path={DRIVE_LIST}>
          <Suspense fallback={<DrivethruListSkeleton />}>
            <DrivethruList />
          </Suspense>
        </Route>

        <Route path="*" render={() => <Redirect to={{ pathname: PAGE404 }} />} />
      </Switch>
    </Layout>
  );
}

export default DriveThruModule;
