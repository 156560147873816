import {
  ADD_DEVICE_DETAILS,
  ADD_DEVICE_STEPS,
  ADD_NEW_LOCATION,
  CAMERA_TYPES,
  CHANNELS_LIST,
  CLEAR_DEVICE_FORM_DATA,
  DEVICE_OFFLINE_EMAIL_LIST,
  DEVICE_TYPES,
  DEVICE_TYPES_SUB_STEP,
  DISABLED_FIELDS,
  EDIT_OTHER_DETAIL,
  INITIALIZE_FORM_OPTIONS,
  INITIATE_LOCAL_CAMERA_FORM,
  IS_REFRESH,
  REDIRECT_ON_SAVE,
  RESET_SUBMIT_FORM,
  SCAN_FOR,
  SETUP_WO_CONNECT,
  SET_ADDED_DEVICE_DATA,
  SET_CONNECTED_CAMERAS,
  SET_CONNECTED_CAMERAS_ERROR,
  SET_DEVICE_TYPE,
  SET_EDITING_MODE,
  SET_EDIT_TYPE,
  SET_ERROR,
  SET_FORM_STEP,
  SET_IS_GLOBAL_SUBMIT,
  SET_SUB_STEPS,
  SET_WO_CONNECT_DETAILS,
  SET_WO_CONNECT_VERIFY_DETAILS,
  SHOW_BACK_BUTTON,
  SHOW_RTSP_AUTH_MODAL,
  SUBMIT_NEW_DEVICE_FORM,
  UPDATED_SELECTED_CAMERAS,
  UPDATE_FORM_OPTIONS,
  UPDATE_OTHER_DETAILS,
  UPDATE_TECHNICAL_DETAILS,
  smartStreamerBitRate,
  smartStreamerDetectionMode,
  smartStreamerFramerate,
  smartStreamerOutPutQuality,
  smartStreamerResolution,
} from ".";
import {
  getFormattedRegionsOptions,
  getRegionsForParentRegion,
} from "../../Services/addDeviceForm";
import Service from "../utils/Service";

const defaultTechnicalDetails = {
  region: "",
  location: "",
  cameraType: CAMERA_TYPES.RTSP,
  scanFor: SCAN_FOR.CAMERA,
  cameraName: "",
  manufacturerName: "",
  streamType: { value: "main", label: "Mainstream" },
  publicURL: "",
  enterCredentials: false,
  ipAddress: "",
  port: "",
  nvrUsername: "",
  nvrPassword: "",
  channelId: "",
  nvrChannelId: "",
  openEyeDevice: "",
  isProxyStream: false,
};

const defaultOtherDetails = {
  streamSettings: {},
  miniOfflineDuration: "",
  subscribeUsers: [],
  runAITaskOnOE: false,
  deviceMiniOfflineDuration: "",
  deviceSubscribeUsers: [],
  smartStreamerEnabled: false,
  smartStreamer: {
    detectionMode: [smartStreamerDetectionMode[0]],
    resolution: [smartStreamerResolution[1]],
    framerate: [smartStreamerFramerate[smartStreamerFramerate.length - 2]],
    quality: [smartStreamerOutPutQuality[smartStreamerOutPutQuality.length - 1]],
    bitrate: [smartStreamerBitRate[1]],
    backgroundRedaction: true,
  },
};

const initialValue = {
  isValid: false,
  step: ADD_DEVICE_STEPS.SELECT_DEVICE_TYPE,
  subSteps: [],
  deviceType: undefined,
  submitForm: false,
  isGlobalSubmit: true,
  editType: undefined,
  showBackButton: false,
  errorFields: {},
  options: {},
  connectedCameras: {
    loading: true,
    error: null,
    total: 0,
    selectedCameras: [],
    newSelectedCameras: [],
    cameras: [],
  },
  disabledFields: {},
  technicalDetails: defaultTechnicalDetails,
  otherDetails: defaultOtherDetails,
  addedDeviceData: null,
  isAddLocation: false,
  redirectOnSave: "",
  inEditingMode: false,
  isEditOtherDetails: false,
  isSetupWoConnect: false,
  isRtspAuthModalVisible: false,
  isRtspFetchedInEditing: false,
  deviceDetails: null,
  isRefresh: false,
  woConnectDetails: {
    loading: false,
    error: null,
    data: {},
  },
  woConnectVerifyDetails: {
    loading: false,
    error: null,
    isVerified: false,
  },
};

const getManufacturerList = (state) => state?.userData?.manifest?.manufacturer || [];
export const getRegions = (state) => state?.userData?.manifest?.regions || [];
const getValue = (selection) => (selection ? selection?.value : undefined);

const validateTechnicalFields = ({ otherDetails }) => {
  const { miniOfflineDuration, deviceMiniOfflineDuration } = otherDetails;
  const errorFields = {};
  const otherCameraSettings = Service.validateOtherSettings({
    durationKey: "miniOfflineDuration",
    usersKey: "subscribeUsers",
    duration: miniOfflineDuration,
    // users: subscribeUsers,
  });

  const otherDeviceSettings = Service.validateOtherSettings({
    durationKey: "deviceMiniOfflineDuration",
    usersKey: "deviceSubscribeUsers",
    duration: deviceMiniOfflineDuration,
    // users: deviceSubscribeUsers,
  });
  Object.assign(errorFields, otherCameraSettings, otherDeviceSettings);
  return {
    isValid: !Object.keys(errorFields).length,
    errorFields,
  };
};

const validateFormWhenStepChanges = ({ newDevice, newStep: nextStep }) => {
  switch (nextStep) {
    case ADD_DEVICE_STEPS.SELECT_DEVICE_TYPE: {
      return {
        isValid: true,
        errorFields: {},
      };
    }

    case ADD_DEVICE_STEPS.ADD_TECHNICAL_DETAILS: {
      const {
        deviceType,
        technicalDetails: { openEyeDevice },
      } = newDevice;

      const isValid = !!deviceType;
      const openEyeCloudCamera = deviceType === DEVICE_TYPES.OPEN_EYE_CLOUD_CAMERA;

      let nextSubStep = null;

      if (openEyeCloudCamera) {
        if (getValue(openEyeDevice)) {
          nextSubStep = DEVICE_TYPES_SUB_STEP.ADD_CAMERAS;
        } else {
          nextSubStep = DEVICE_TYPES_SUB_STEP.OPEN_EYE_DEVICE;
        }
      }

      if (isValid) {
        return { isValid, errorFields: {} };
      }

      return {
        isValid,
        errorFields: {
          deviceType: "Select device type",
        },
      };
    }

    case ADD_DEVICE_STEPS.OTHER_DETAILS: {
      return validateTechnicalFields(newDevice);
    }

    default:
      return {
        isValid: false,
      };
  }
};

export const handleAddDeviceFormSubmit = (newDevice) => {
  switch (newDevice.step) {
    case ADD_DEVICE_STEPS.SELECT_DEVICE_TYPE: {
      const nextStep = ADD_DEVICE_STEPS.ADD_TECHNICAL_DETAILS;
      // const { isValid, errorFields } = validateFormWhenStepChanges({
      //   newDevice,
      //   newStep: nextStep,
      // });

      // if (!isValid) {
      //   return {
      //     ...newDevice,
      //     errorFields,
      //   };
      // }

      // const { isValid: isNextFormValid } = validateFormWhenStepChanges({
      //   newDevice,
      //   newStep: nextStep,
      // });

      return {
        ...newDevice,
        step: nextStep,
        isGlobalSubmit: true,
        isValid: true,
        errorFields: {},
      };
    }

    case ADD_DEVICE_STEPS.ADD_TECHNICAL_DETAILS: {
      const nextStep = ADD_DEVICE_STEPS.OTHER_DETAILS;

      const { isValid } = validateFormWhenStepChanges({
        newDevice,
        newStep: nextStep,
      });

      return {
        ...newDevice,
        step: nextStep,
        nextSubStep: null,
        isValid,
        submitForm: true,
      };
    }

    case ADD_DEVICE_STEPS.OTHER_DETAILS: {
      const nextStep = ADD_DEVICE_STEPS.OTHER_DETAILS;
      const { isValid, errorFields } = validateFormWhenStepChanges({
        newDevice,
        newStep: nextStep,
      });

      if (!isValid) {
        return {
          ...newDevice,
          errorFields,
        };
      }
      return {
        ...newDevice,
        submitForm: true,
      };
    }

    default:
      return newDevice;
  }
};

const getInitialFormOptions = (state) => {
  const {
    technicalDetails: { region, manufacturerName } = {},
    inEditingMode,
    deviceType,
  } = state.newDevice || {};
  const manufacturer = getManufacturerList(state);
  const manufacturerOptions =
    manufacturer?.map((manufacturerKey) => ({
      label: manufacturerKey,
      value: manufacturerKey,
    })) || [];
  const manufacturerList =
    inEditingMode && deviceType === DEVICE_TYPES.CLOUD_CAMERA
      ? [manufacturerName, ...manufacturerOptions]
      : manufacturerOptions;
  const regions = getRegions(state);
  const regionsOptions = getFormattedRegionsOptions(regions);
  const locationOptions = getRegionsForParentRegion(regions, getValue(region));
  const offlineEmailIds = [];
  const openEyeDeviceOptions = [];

  return {
    manufacturerOptions: manufacturerList,
    regionsOptions,
    locationOptions,
    openEyeDeviceOptions,
    channelsListOptions: CHANNELS_LIST,
    offlineEmailIds,
  };
};

const getUpdatedOptions = ({ fieldName, fieldValue, state }) => {
  const regions = getRegions(state);
  const updatedOptions = {};
  if (fieldName === "region") {
    updatedOptions.locationOptions = getRegionsForParentRegion(regions, getValue(fieldValue));
  }

  return updatedOptions;
};

const getUpdatedDependentFields = ({ fieldName }) => {
  const updates = {};

  if (fieldName === "region") {
    updates.location = [];
  }

  return updates;
};

const getUpdatedStateFields = ({ fieldName }) => {
  let updates = {};

  if (fieldName === "region") {
    updates = { ...updates };
  }

  if (fieldName === "location") {
    updates.connectedCameras = initialValue.connectedCameras;
  }

  return updates;
};

const handleDisabledBackButton = (inEditingMode, isEditOtherDetails, step, deviceType) =>
  inEditingMode &&
  !isEditOtherDetails &&
  (step === ADD_DEVICE_STEPS.OTHER_DETAILS ||
    (step === ADD_DEVICE_STEPS.ADD_TECHNICAL_DETAILS &&
      deviceType === DEVICE_TYPES.STAND_ALONE_CAMERA));

const handleEnabledBackButton = (step, subSteps) =>
  step === ADD_DEVICE_STEPS.ADD_TECHNICAL_DETAILS && subSteps.length === 0;

export default function newDeviceReducer(state = initialValue, { type, payload } = {}) {
  switch (type) {
    case INITIALIZE_FORM_OPTIONS: {
      return {
        ...state,
        options: getInitialFormOptions(payload),
      };
    }
    case UPDATE_FORM_OPTIONS: {
      return {
        ...state,
        options: {
          ...state.options,
          ...payload,
        },
      };
    }
    case SET_IS_GLOBAL_SUBMIT: {
      return {
        ...state,
        isGlobalSubmit: payload,
      };
    }
    case SET_FORM_STEP: {
      const { isValid, errorFields } = validateFormWhenStepChanges({
        newDevice: state,
        newStep: payload,
      });
      if (isValid) {
        return {
          ...state,
          step: payload,
          isValid,
          submitForm: false,
          isGlobalSubmit:
            payload === ADD_DEVICE_STEPS.SELECT_DEVICE_TYPE ? true : state.isGlobalSubmit,
        };
      }

      return {
        ...state,
        errorFields,
        submitForm: false,
      };
    }

    case SET_DEVICE_TYPE:
      if (payload === DEVICE_TYPES.STAND_ALONE_CAMERA) {
        return {
          ...state,
          deviceType: payload,
          otherDetails: {
            ...state.otherDetails,
            smartStreamerEnabled: true, // By default, smart streamer is enabled for standalone camera
          },
          isValid: true,
          errorFields: {},
        };
      }
      return {
        ...state,
        deviceType: payload,
        isValid: true,
        errorFields: {},
      };

    case UPDATE_TECHNICAL_DETAILS: {
      const { fieldName, fieldValue, storeState } = payload;
      const updatedDependentOptions = getUpdatedOptions({
        fieldName,
        fieldValue,
        state: storeState,
      });
      const updatedDependentFields = getUpdatedDependentFields({
        fieldName,
        fieldValue,
        state,
      });

      const updateStateFields = getUpdatedStateFields({
        fieldName,
        fieldValue,
        state,
      });

      const updatedNewDeviceData = {
        ...state,
        options: {
          ...state.options,
          ...updatedDependentOptions,
        },
        technicalDetails: {
          ...state.technicalDetails,
          ...updatedDependentFields,
          [fieldName]: fieldValue,
        },
        ...updateStateFields,
      };
      return {
        ...updatedNewDeviceData,
      };
    }

    case SET_WO_CONNECT_VERIFY_DETAILS: {
      return {
        ...state,
        woConnectVerifyDetails: payload,
      };
    }

    case SET_WO_CONNECT_DETAILS: {
      return {
        ...state,
        woConnectDetails: payload,
      };
    }

    case SET_CONNECTED_CAMERAS: {
      const { cameras, error = null, loading = false } = payload;

      if (loading) {
        return {
          ...state,
          connectedCameras: {
            ...state.connectedCameras,
            loading,
          },
        };
      }

      if (error) {
        return {
          ...state,
          connectedCameras: {
            ...state.connectedCameras,
            error,
          },
        };
      }
      const selectedCameras = cameras?.filter(({ added }) => added).map(({ id }) => id) || [];
      const copiedErrorFields = { ...(state.errorFields || {}) };
      if (selectedCameras.length) {
        delete copiedErrorFields.selectedCameras;
      }

      return {
        ...state,
        errorFields: copiedErrorFields,
        isValid: true,
        connectedCameras: {
          loading,
          error,
          cameras,
          total: cameras?.length,
          selectedCameras,
          newSelectedCameras: [],
        },
      };
    }
    case SET_CONNECTED_CAMERAS_ERROR: {
      return {
        ...state,
        connectedCameras: {
          ...state.connectedCameras,
          error: payload,
        },
      };
    }

    case UPDATED_SELECTED_CAMERAS: {
      const copiedErrorFields = { ...(state.errorFields || {}) };
      if (payload.length) {
        delete copiedErrorFields.selectedCameras;
      }

      return {
        ...state,
        errorFields: copiedErrorFields,
        isValid: true,
        connectedCameras: {
          ...state.connectedCameras,
          newSelectedCameras: payload,
        },
      };
    }

    case UPDATE_OTHER_DETAILS: {
      const { fieldName, fieldValue } = payload;
      const updateStateFields = getUpdatedStateFields({
        fieldName,
        fieldValue,
        state,
      });

      const updatedNewDeviceData = {
        ...state,
        otherDetails: {
          ...state.otherDetails,
          [fieldName]: fieldValue,
        },
        ...updateStateFields,
      };
      const { isValid, errorFields } = validateTechnicalFields(updatedNewDeviceData);

      if (isValid) {
        return {
          ...updatedNewDeviceData,
          isValid,
          errorFields,
        };
      }

      return {
        ...updatedNewDeviceData,
        isValid: true,
        errorFields: {
          ...state.errorFields,
          [fieldName]: errorFields[fieldName],
        },
      };
    }

    case CLEAR_DEVICE_FORM_DATA:
      return {
        ...initialValue,
        options: getInitialFormOptions({
          ...payload,
          newDevice: initialValue,
        }),
      };

    case SUBMIT_NEW_DEVICE_FORM: {
      if (!payload) {
        return {
          ...state,
          submitForm: false,
        };
      }

      const { step: nextStep, submitForm, ...rest } = handleAddDeviceFormSubmit(state, payload);

      return {
        ...rest,
        step: submitForm ? state.step : nextStep,
        submitForm,
        nextStep,
      };
    }
    case EDIT_OTHER_DETAIL: {
      return {
        ...state,
        isEditOtherDetails: payload,
      };
    }
    case RESET_SUBMIT_FORM: {
      return {
        ...state,
        submitForm: false,
        step: state.nextStep,
        nextStep: null,
      };
    }

    case SET_ADDED_DEVICE_DATA: {
      return {
        ...state,
        addedDeviceData: payload,
      };
    }
    case DEVICE_OFFLINE_EMAIL_LIST: {
      return {
        ...state,
        options: {
          ...state.options,
          offlineEmailIds: payload,
        },
      };
    }

    case INITIATE_LOCAL_CAMERA_FORM: {
      return {
        ...state,
        step: ADD_DEVICE_STEPS.ADD_TECHNICAL_DETAILS,
        deviceType: DEVICE_TYPES.LOCAL_CAMERA,
      };
    }
    case SETUP_WO_CONNECT: {
      let deviceTypeChange = state.deviceType;
      if (state.step === ADD_DEVICE_STEPS.OTHER_DETAILS) {
        if (payload) {
          deviceTypeChange = DEVICE_TYPES.LOCAL_CAMERA;
        } else {
          deviceTypeChange = DEVICE_TYPES.OPEN_EYE_CLOUD_CAMERA;
        }
      }
      return {
        ...state,
        isSetupWoConnect: payload,
        deviceType: deviceTypeChange,
      };
    }
    case ADD_DEVICE_DETAILS: {
      return {
        ...state,
        deviceDetails: payload,
      };
    }
    case ADD_NEW_LOCATION: {
      return {
        ...state,
        isAddLocation: payload,
      };
    }
    case DISABLED_FIELDS: {
      const { fieldName, fieldValue } = payload;
      const copiedDisabledFields = { ...(state.disabledFields || {}) };
      if (copiedDisabledFields[fieldName] && !fieldValue) {
        delete copiedDisabledFields[fieldName];
      }
      if (!copiedDisabledFields[fieldName] && fieldValue) {
        copiedDisabledFields[fieldName] = fieldValue;
      }
      return {
        ...state,
        disabledFields: copiedDisabledFields,
      };
    }
    case SET_EDITING_MODE: {
      return {
        ...state,
        inEditingMode: payload,
      };
    }
    case REDIRECT_ON_SAVE: {
      return {
        ...state,
        redirectOnSave: payload,
      };
    }
    case IS_REFRESH: {
      return {
        ...state,
        isRefresh: payload,
      };
    }
    case SET_ERROR: {
      const { fieldName, fieldValue } = payload;
      const copiedErrorFields = { ...(state.errorFields || {}) };
      if (copiedErrorFields[fieldName] && !fieldValue) {
        delete copiedErrorFields[fieldName];
      }
      if (!copiedErrorFields[fieldName] && fieldValue) {
        copiedErrorFields[fieldName] = fieldValue;
      }
      return {
        ...state,
        errorFields: copiedErrorFields,
      };
    }
    case SHOW_RTSP_AUTH_MODAL: {
      const { isVisible = false, isFetched = false } = payload;
      return {
        ...state,
        isRtspAuthModalVisible: isVisible,
        isRtspFetchedInEditing: isFetched,
      };
    }

    case SET_SUB_STEPS: {
      return {
        ...state,
        subSteps: [...state.subSteps, payload],
      };
    }
    case SET_EDIT_TYPE: {
      return {
        ...state,
        editType: payload,
      };
    }
    case SHOW_BACK_BUTTON: {
      const { inEditingMode, step, deviceType, subSteps, isEditOtherDetails } = state;
      const isDisabled = handleDisabledBackButton(
        inEditingMode,
        isEditOtherDetails,
        step,
        deviceType
      );
      const isEnabled = handleEnabledBackButton(step, subSteps);

      return {
        ...state,
        showBackButton: isEnabled && !isDisabled,
      };
    }

    default:
      return state;
  }
}
