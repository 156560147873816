import React from "react";
import styles from "./Styles.module.scss";

function DotTypingLoader() {
  return (
    <div className={styles["typing-wrapper"]}>
      <div className={styles["dot-typing"]} />
    </div>
  );
}

export default DotTypingLoader;
