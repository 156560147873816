import React from "react";

function IconDelivery({ height = "16", width = "16", color = "#3766E8" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 0.666748C11.0349 0.666748 11.3334 0.965228 11.3334 1.33341V2.00008H14.6667V6.00008H13.3209L15.1514 11.0293C15.2689 11.3299 15.3334 11.6571 15.3334 11.9994C15.3334 13.4722 14.1394 14.6661 12.6667 14.6661C11.4244 14.6661 10.3805 13.8165 10.0842 12.6667H7.24937C6.95331 13.8169 5.90927 14.6667 4.66671 14.6667C3.37038 14.6667 2.29009 13.7417 2.04987 12.5158C1.62401 12.2931 1.33337 11.8472 1.33337 11.3334V4.66675C1.33337 4.29856 1.63185 4.00008 2.00004 4.00008H6.66671C7.03491 4.00008 7.33337 4.29856 7.33337 4.66675V8.00008C7.33337 8.36828 7.63184 8.66675 8.00004 8.66675H9.33337C9.70157 8.66675 10 8.36828 10 8.00008V2.00008H8.00004V0.666748H10.6667ZM4.66671 10.6667C3.93033 10.6667 3.33337 11.2637 3.33337 12.0001C3.33337 12.7365 3.93033 13.3334 4.66671 13.3334C5.40309 13.3334 6.00004 12.7365 6.00004 12.0001C6.00004 11.2637 5.40309 10.6667 4.66671 10.6667ZM12.6667 10.6661C11.9303 10.6661 11.3334 11.263 11.3334 11.9994C11.3334 12.7358 11.9303 13.3327 12.6667 13.3327C13.4031 13.3327 14 12.7358 14 11.9994C14 11.8392 13.9718 11.6855 13.92 11.5433L13.909 11.5143C13.715 11.0178 13.232 10.6661 12.6667 10.6661ZM11.902 6.00008H11.3334V8.00008C11.3334 9.10468 10.438 10.0001 9.33337 10.0001H8.00004C6.89544 10.0001 6.00004 9.10468 6.00004 8.00008H2.66671V10.2362C3.15533 9.68262 3.87025 9.33342 4.66671 9.33342C5.90927 9.33342 6.95331 10.1833 7.24937 11.3334H10.0839C10.3797 10.1829 11.4239 9.33275 12.6667 9.33275C12.8246 9.33275 12.9792 9.34648 13.1296 9.37282L11.902 6.00008ZM6.00004 5.33342H2.66671V6.66675H6.00004V5.33342ZM13.3334 3.33341H11.3334V4.66675H13.3334V3.33341Z"
        fill={color}
      />
    </svg>
  );
}

export default IconDelivery;
