import React, { lazy, Suspense } from "react";
import LoginSkeleton from "../Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const AddCompanyComponent = lazy(() => import("../Components/SignupComponent/AddCompanyComponent"));

function AddCompany(props) {
  return (
    <Suspense
      fallback={<LoginSkeleton width="60vw" height={460} titleHeight={100} titleWidth="60vw" />}
    >
      <AddCompanyComponent
        credentials={props.location && props.location.credentials ? props.location.credentials : {}}
      />
    </Suspense>
  );
}

export default AddCompany;
