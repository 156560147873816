import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CameraGridSkeleton from "../../../Common/Components/SkeletonComponent/MainModulesSkeleton/CameraGridSkeleton";
import RecorderListSkeleton from "../../../Common/Components/SkeletonComponent/MainModulesSkeleton/RecorderListSkeleton";
import { CAMERA, PAGE404, RECORDER } from "../../../Constants";
import WocamLayout from "../../Components/WocamLayout";
import WoCamContextProvider from "../../Contexts/WocamContextProvider";

const CameraListLazyComponent = lazy(() => import("./CameraList"));
const RecorderListLazyComponent = lazy(() => import("./RecorderList"));

function Wocam() {
  return (
    <WoCamContextProvider>
      <WocamLayout>
        <Switch>
          <Route exact path={CAMERA}>
            <Suspense fallback={<CameraGridSkeleton />}>
              <CameraListLazyComponent />
            </Suspense>
          </Route>
          <Route exact path={RECORDER}>
            <Suspense fallback={<RecorderListSkeleton />}>
              <RecorderListLazyComponent />
            </Suspense>
          </Route>
          <Route path="*">
            <Redirect to={{ pathname: PAGE404 }} />
          </Route>
        </Switch>
      </WocamLayout>
    </WoCamContextProvider>
  );
}

export default Wocam;
