import React from "react";
import "./style.scss";

const ActionBlock = (props) => {
  return (
    <div className={"action-block"}>
      {props.showActionBtn && <ul className={"action-btn mb-0"}>{props.children}</ul>}
      {props.showActionList && <ul className={"list-inline mb-0"}>{props.children}</ul>}
      {props.showActionFilter && (
        <div className={`d-flex align-items-start ${props?.className ? props.className : ""}`}>
          {props.children}
        </div>
      )}
    </div>
  );
};

export default ActionBlock;
