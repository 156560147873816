import React from "react";
import IconZoomIn from "../../../../Common/Components/IconsComponent/IconZoomIn";
import CanvasImageComponent from "../../../../Common/Components/Molecule/CanvasImageComponent/CanvasImageComponent";
import Tag from "../../../../Common/Components/Molecule/Tag/Tag";
import Styles from "./StyleVerificationModal.module.scss";
import { getCustomerJourneyConfig } from "../../../utils";

function StationColumn({
  data,
  stationIndex,
  handleSelect,
  updateCar,
  setOpenLightBox,
  setImageList,
  stationType,
  stationData,
  sameCam,
  setSelectedStationType,

  compareStationCamera,
}) {
  const obscureData = getCustomerJourneyConfig()?.obscureData;
  if (stationIndex === 0 && stationType === "entry") {
    data = [data?.find((ele) => ele.lp === updateCar.Vnumber)];
  }

  const handelZoomIconClick = (car) => {
    if (compareStationCamera) {
      const exit = stationData.exit.find((ele) => ele.groupId === car.groupId);
      setImageList([
        { image: car?.image, coordinates: car?.boundingBox },
        { image: exit?.image, coordinates: exit?.boundingBox },
      ]);
    } else {
      setImageList([{ image: car?.image, coordinates: car?.boundingBox }]);
    }
  };
  const setTagColor = (status) =>
    status === "verified"
      ? "tag-success-solid"
      : status === "stale"
        ? "tag-warning-solid"
        : "tag-orange-solid";
  const getExitTime = (car) => {
    if (compareStationCamera) {
      const exit = stationData.exit.find((ele) => ele?.groupId === car?.groupId);

      return exit?.time;
    }
    return "";
  };
  return (
    <ul className={`vertical-scroll visible-scroll ${Styles.fixed_height}`}>
      {data?.map((car, i) => {
        if (car?._id) {
          return (
            <li
              key={i}
              onClick={() => {
                if (stationIndex === 0 && stationType === "entry") {
                } else {
                  handleSelect(car?.station, car.type, car?._id, car?.groupId, stationType);
                }
              }}
              id={car?.isSelected === true ? `selectedId_${car.station}_${car.type}` : ""}
              className={`mr-1 ${car?.isSelected === true && Styles.selected}`}
            >
              <div className={`${Styles.item_wrapper}`}>
                {/* Vehicles metadata */}
                <div
                  className={`${Styles.top_metadata_wrapper} ${
                    Styles.left_metadata
                  } ${Styles.tag_height} ${car?.isSelected === true ? Styles.selected : ""}`}
                >
                  {car?.lp ? <Tag className={`${Styles.tag_default} `}>{car?.lp}</Tag> : ""}

                  {car?.status ? (
                    <Tag className={`${setTagColor(car?.status)}  `}>
                      {car?.status[0].toUpperCase() + car?.status.slice(1)}
                    </Tag>
                  ) : (
                    ""
                  )}
                </div>
                {/* Zoom Action */}
                {car?.image ? (
                  <div
                    className={`${Styles.top_metadata_wrapper} ${Styles.tag_height} ${Styles.right_metadata}`}
                    onClick={(e) => {
                      const stationType = !sameCam
                        ? `${car.station} In & Out`
                        : `${car.station} ${car.type === "exit" ? "Out" : "In"}`;
                      setOpenLightBox(true);
                      handelZoomIconClick(car);
                      e.stopPropagation();
                      setSelectedStationType(stationType);
                    }}
                  >
                    <div className={`${Styles.metadata_border}`}>
                      <IconZoomIn />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* Vehicle Image */}
                <CanvasImageComponent
                  coordinates={car?.boundingBox}
                  imageSrc={car?.image}
                  className="img-fluid mx-auto d-block"
                  // style={{ background: "red" }}
                  onClick={() => {
                    if (stationIndex === 0 && stationType === "entry") {
                    } else {
                      handleSelect(car?.station, car.type, car?._id, car?.groupId, stationType);
                    }
                  }}
                  obscureData={obscureData}
                />
                <div className={`${Styles.bottom_metadata_wrapper}`}>
                  {car?.time ? (
                    <Tag color="muted" className="mr-0">
                      {car?.time}
                    </Tag>
                  ) : (
                    ""
                  )}

                  {compareStationCamera ? (
                    <Tag color="muted" className="mr-0">
                      {getExitTime(car)}
                    </Tag>
                  ) : (
                    ""
                  )}
                </div>{" "}
              </div>
            </li>
          );
        }
        // : (
        //   <></>
        // );
      })}
    </ul>
  );
}

export default StationColumn;
