import React, { useCallback, useState } from "react";
import { Sidebar as SidebarComponent } from "wobotui";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./aside-style.scss";
import CompanyLogo from "./CompanyLogo";
import RaiseATicket from "./RaiseATicket";
import getSidebarHeaderOptions, { getSidebarFooterOptions } from "./Utils/getSidebarOptions";

function Sidebar() {
  const { user: { username, id } = {} } = useSelector((state) => state.userData || {});
  const {
    location: { pathname },
    push,
  } = useHistory();
  const [showRaiseTicketModal, setRaiseTicketModal] = useState(false);

  const handleShowRaiseTicketModal = useCallback((state) => {
    setRaiseTicketModal(state);
  }, []);

  return (
    <>
      <SidebarComponent
        menuItems={getSidebarHeaderOptions({ pathname })}
        footerItems={getSidebarFooterOptions({
          showRaiseTicketModal: () => handleShowRaiseTicketModal(true),
        })}
        logo={<CompanyLogo />}
        onNavigate={(link) => push(link)}
      />
      {showRaiseTicketModal && (
        <RaiseATicket
          username={username}
          userId={id}
          show={showRaiseTicketModal}
          onClose={() => handleShowRaiseTicketModal(false)}
        />
      )}
    </>
  );
}

export default Sidebar;
