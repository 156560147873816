import React from "react";
import Skeleton from "react-loading-skeleton";
import "./styles.scss";

export default function DataSkeletonComponent() {
  return (
    <div>
      {" "}
      <div className="metric_card_wrapper">
        {Array(3)
          .fill()
          .map((e) => (
            <div className="metrics_card">
              <Skeleton height={140} width={430} />
            </div>
          ))}
      </div>
      <Skeleton height={22} width={152} className="zone_overview_heading" />
      <div className="insights">
        {Array(4)
          .fill()
          .map((e) => (
            <div className="zone_overview">
              <div className="zone_header">
                <Skeleton height={22} width={60} />
                <Skeleton height={22} width={108} />
              </div>
              <div className="zone_occupancy">
                <Skeleton height={74} width={216} />
              </div>
              <div className="zone_inOut">
                <div className="mr-4">
                  {" "}
                  <Skeleton height={74} width="100%" />
                </div>
                <div>
                  {" "}
                  <Skeleton height={74} width="100%" />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
