import axios from "axios";

export default async function fetchAssistantMessage({
  endPoint,
  params,
  method = "GET",
  contentType = "application/json",
}) {
  const config = {
    method,
    url: `${endPoint?.url}${endPoint?.endPoint}`,
    headers: {
      "Content-Type": contentType,
      Accept: "application/json",
    },
    ...(method === "POST" ? { data: params } : { params }),
  };

  if (endPoint?.token) {
    config.headers.Authorization = endPoint.token;
  }

  const { data } = await axios(config);
  return data;
}
