import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";
import style from "./style.module.scss";
import ComparativeSelectionSkeleton from "./ComparativeSelectionSkeleton";
import ComparativeDataSkeleton from "./ComparativeDataSkeleton";
import ComparativeHeatmapSkeleton from "./ComparativeHeatmapSkeleton";

function ComparativeAnalyticsSkeleton({ heatmapTask }) {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <PageTitleSkeleton />

        <div className={style.filter}>
          <Skeleton width={270} height={40} />
        </div>
        <ComparativeSelectionSkeleton />
        {heatmapTask ? <ComparativeHeatmapSkeleton /> : <ComparativeDataSkeleton />}
      </div>
    </div>
  );
}

export default ComparativeAnalyticsSkeleton;
