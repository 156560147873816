import React from "react";

const IconMap = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.4444 0C19.3833 0 19.3278 0.00557292 19.2722 0.0277604L13.3333 2.33333L6.66667 0L0.405573 2.11109C0.17224 2.18891 0 2.38891 0 2.64443V19.4444C0 19.75 0.25 20 0.555573 20C0.616667 20 0.67224 19.9944 0.727812 19.9722L6.66667 17.6667L13.3333 20L19.6 17.8944C19.8333 17.8111 20 17.6111 20 17.3555V0.555573C20 0.25 19.75 0 19.4444 0ZM13.3333 17.7778L6.66667 15.4389V2.22224L13.3333 4.56115V17.7778Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconMap;
