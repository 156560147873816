import { isPossiblePhoneNumber } from "react-phone-number-input";
import { CAMERA_TYPES } from "../Wocam/State";

const startWithLetter = (value) => {
  // Pattern to check if the value starts with a letter
  const pattern = /^[A-Za-z]/;
  return pattern.test(value);
};
const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(email);
};

const validateMobile = (mobile) => {
  const mob = isPossiblePhoneNumber(mobile);
  // const mob = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  // return !mob.test(mobile);
  return !mob;
};

const validatePassword = (password) => password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/);

const validateIPaddress = (inputText) => {
  const dns =
    /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.[a-zA-Z]{2,}$/;
  const ip =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ip.test(inputText) || dns.test(inputText);
};

const validateEmpty = (value) => {
  if (typeof value === "string") {
    return value.trim() === "";
  }
};

const validateDropDown = (option) => !(option && option.length);
export const isSourceHLS = (source) => source === "http" || source === "https";
const validateHlsUrl = (url, isProxy) => {
  const withProxyCheck = /^(http|https):\/\/.+/;
  const regex =
    /^(https?):\/\/(?:[a-zA-Z0-9-_.]+(?:\:[a-zA-Z0-9-_.!@#$%^&*]+)?@)*[a-zA-Z0-9-.]+(?:\/[^\s]*)?\.m3u8$/;
  if (isProxy) {
    return withProxyCheck.test(url);
  }

  return regex.test(url);
};
const validateRTSP = (url, isProxy) => {
  const rtsp = /(rtsp):\/\/(?:(?:[^\s@/]+)@)?(?:[^\s/:]+)(?::(?:[0-9]+))?(?:\/(?:.*))?/;
  const withProxyRtsp = /^(rtsp):\/\/.+/;
  if (isProxy) {
    return withProxyRtsp.test(url);
  }
  return rtsp.test(url);
};
const validateRTSPUrl = (strUrl, type, isProxy) => {
  const isTypeRtsp = CAMERA_TYPES.RTSP === type;
  const isTypeHLS = CAMERA_TYPES.HLS === type;
  // const url =
  //   /(rtsp|https?):\/\/(?:([^\s@\/]+)@)?([^\s\/:]+)(?::([0-9]+))?(?:\/(.*))?/;
  // validate,if url starts with http or https then it should end with .m3u8, else validate rtsp url
  // const url =
  //   /(rtsp):\/\/(?:(?:[^\s@/]+)@)?(?:[^\s/:]+)(?::(?:[0-9]+))?(?:\/(?:.*))?/;
  // return url.test(strUrl);

  if (isTypeRtsp) {
    return validateRTSP(strUrl, isProxy);
  }

  if (isTypeHLS) return validateHlsUrl(strUrl, isProxy);
  return validateRTSP(strUrl, isProxy);
};

const validateSearch = (searchStr) => {
  const search = /[\(\*\)\\\[\?][\%]/;
  return !search.test(searchStr);
};

// Validate Camera Name Input
const validateName = (inputNameStr) => {
  if (inputNameStr) {
    const name = /^[a-zA-Z][a-zA-Z0-9 ]*(?:-[a-zA-Z0-9 ]+)*$/;
    return name.test(inputNameStr);
  }
  return true;
};

// Task only number and text on start
const noSpecialcharacterStart = (inputText) => {
  const namePattern = /^[a-zA-Z0-9][a-zA-Z0-9\s\S]*$/;
  return namePattern.test(inputText);
};
// Task only text charachters on start
const onlyTextCharacters = (inputText) => {
  const textPattern = /^[a-zA-Z\s]*$/;
  return textPattern.test(inputText);
};
// For location and camera names one alphabetic charcter is manadatory
const atleastOneAlphabeticCharacter = (inputText) => {
  const namePattern = /^(?=.*[a-zA-Z])[a-zA-Z0-9._ -]+$/;
  return namePattern.test(inputText);
};

const noSpecialCharacters = (inputText) => {
  const namePattern = /[^\w\s._-]/;
  return namePattern.test(inputText);
};

const isNumber = (input) => {
  const regEx = /^[0-9]+$/;
  return regEx.test(input);
};

export {
  atleastOneAlphabeticCharacter,
  isNumber,
  noSpecialCharacters,
  noSpecialcharacterStart,
  onlyTextCharacters,
  startWithLetter,
  validateDropDown,
  validateEmail,
  validateEmpty,
  validateIPaddress,
  validateMobile,
  validateName,
  validatePassword,
  validateRTSPUrl,
  validateSearch,
};
