import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";
const LiveViewSkeleton = () => {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <PageTitleSkeleton />

        <div className={"list-inline-item"}>
          <Skeleton width={700} height={30} />
        </div>

        <div className={"panel-body mt-60 row ml-1"}>
          {Array(3)
            .fill()
            .map((e, index) => (
              <div className={"mr-2 mb-2"} key={index}>
                <Skeleton width={350} height={250} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LiveViewSkeleton;
