import React from "react";

const IconHeatMap = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_48036_14461)">
        <path
          d="M10.5 11C11.0523 11 11.5 10.5523 11.5 10C11.5 9.44772 11.0523 9 10.5 9C9.94772 9 9.5 9.44772 9.5 10C9.5 10.5523 9.94772 11 10.5 11Z"
          fill="#3766E8"
        />
        <path
          d="M7 7C7.55228 7 8 6.55228 8 6C8 5.44772 7.55228 5 7 5C6.44772 5 6 5.44772 6 6C6 6.55228 6.44772 7 7 7Z"
          fill="#3766E8"
        />
        <path
          d="M14.5 10C14.7761 10 15 9.77614 15 9.5C15 9.22386 14.7761 9 14.5 9C14.2239 9 14 9.22386 14 9.5C14 9.77614 14.2239 10 14.5 10Z"
          fill="#3766E8"
        />
        <path
          d="M13.25 15C12.9039 15 12.5655 14.8974 12.2778 14.7051C11.99 14.5128 11.7657 14.2395 11.6332 13.9197C11.5008 13.5999 11.4661 13.2481 11.5336 12.9086C11.6012 12.5691 11.7678 12.2573 12.0126 12.0126C12.2573 11.7678 12.5691 11.6012 12.9086 11.5336C13.2481 11.4661 13.5999 11.5008 13.9197 11.6332C14.2395 11.7657 14.5128 11.99 14.7051 12.2778C14.8974 12.5655 15 12.9039 15 13.25C14.9995 13.714 14.8149 14.1588 14.4868 14.4868C14.1588 14.8149 13.714 14.9995 13.25 15ZM13.25 12.5C13.1017 12.5 12.9567 12.544 12.8333 12.6264C12.71 12.7088 12.6139 12.8259 12.5571 12.963C12.5003 13.1 12.4855 13.2508 12.5144 13.3963C12.5434 13.5418 12.6148 13.6754 12.7197 13.7803C12.8246 13.8852 12.9582 13.9567 13.1037 13.9856C13.2492 14.0145 13.4 13.9997 13.537 13.9429C13.6741 13.8861 13.7912 13.79 13.8736 13.6667C13.956 13.5433 14 13.3983 14 13.25C13.9998 13.0512 13.9207 12.8605 13.7801 12.7199C13.6395 12.5793 13.4488 12.5002 13.25 12.5Z"
          fill="#3766E8"
        />
        <path
          d="M6.99973 15C6.62307 14.9998 6.25429 14.892 5.93673 14.6895C5.25703 14.9954 4.4974 15.0762 3.76854 14.9202C3.03968 14.7642 2.37969 14.3795 1.88473 13.8222C1.38978 13.2649 1.08573 12.5641 1.01691 11.8219C0.948077 11.0798 1.11806 10.335 1.50209 9.69617C1.88612 9.05735 2.46412 8.55786 3.15186 8.2705C3.8396 7.98313 4.60114 7.9229 5.3255 8.09858C6.04987 8.27427 6.6992 8.67669 7.17887 9.2472C7.65854 9.81771 7.94349 10.5265 7.99218 11.2703C8.37503 11.4885 8.6749 11.8272 8.84502 12.2337C9.01514 12.6402 9.04594 13.0916 8.93263 13.5174C8.81932 13.9433 8.56826 14.3196 8.21861 14.5878C7.86897 14.856 7.44039 15.001 6.99973 15ZM6.05758 13.4861L6.32713 13.7334C6.4358 13.8341 6.56558 13.9093 6.707 13.9535C6.84843 13.9977 6.99793 14.0098 7.14461 13.9889C7.2913 13.968 7.43147 13.9146 7.55489 13.8326C7.67832 13.7506 7.7819 13.6422 7.85808 13.5151C7.93426 13.388 7.98112 13.2455 7.99524 13.098C8.00936 12.9505 7.99038 12.8017 7.9397 12.6625C7.88901 12.5233 7.8079 12.3971 7.70226 12.2932C7.59663 12.1893 7.46913 12.1103 7.32908 12.0619L6.98363 11.9403L6.99863 11.517C7.0033 10.9651 6.82556 10.4272 6.49301 9.98676C6.16046 9.54634 5.69175 9.2281 5.15971 9.0815C4.62767 8.9349 4.0621 8.96815 3.5509 9.17609C3.03971 9.38403 2.61151 9.755 2.33287 10.2314C2.05423 10.7077 1.94074 11.2628 2.01005 11.8103C2.07936 12.3578 2.32758 12.8671 2.71614 13.259C3.10469 13.6508 3.61182 13.9034 4.15871 13.9774C4.70559 14.0514 5.26161 13.9427 5.74033 13.6681L6.05758 13.4861Z"
          fill="#3766E8"
        />
        <path
          d="M12 8.00001C11.2046 7.99912 10.4421 7.68277 9.87967 7.12035C9.31725 6.55793 9.0009 5.79539 9.00001 5.00001C9.00004 4.91106 9.00453 4.82216 9.01346 4.73366C8.70518 4.56145 8.44861 4.30985 8.2704 4.00499C8.09218 3.70014 7.99883 3.35313 8.00001 3.00001C8.00061 2.46976 8.21151 1.9614 8.58646 1.58646C8.9614 1.21151 9.46976 1.00061 10 1.00001C10.3531 0.998835 10.7002 1.09221 11.005 1.27044C11.3099 1.44867 11.5615 1.70526 11.7337 2.01356C11.8242 2.00451 11.9126 2.00001 12 2.00001C12.7957 2.00001 13.5587 2.31608 14.1213 2.87869C14.6839 3.4413 15 4.20436 15 5.00001C15 5.79566 14.6839 6.55872 14.1213 7.12133C13.5587 7.68394 12.7957 8.00001 12 8.00001ZM10 2.00001C9.73488 2.00029 9.48069 2.10574 9.29321 2.29321C9.10574 2.48069 9.00029 2.73488 9.00001 3.00001C9.00003 3.21462 9.06976 3.42341 9.19872 3.59496C9.32767 3.7665 9.50886 3.89151 9.71501 3.95116L10.1659 4.08646L10.0583 4.54456C9.96436 4.94833 9.99844 5.3713 10.1558 5.75483C10.3131 6.13835 10.5859 6.4634 10.9363 6.68488C11.2867 6.90637 11.6974 7.01331 12.1113 6.99088C12.5252 6.96845 12.9219 6.81775 13.2464 6.55969C13.5708 6.30164 13.8068 5.94901 13.9218 5.55073C14.0368 5.15245 14.025 4.72827 13.888 4.33702C13.751 3.94576 13.4956 3.60684 13.1573 3.36727C12.819 3.12769 12.4146 2.99934 12 3.00001C11.8463 3.00181 11.6932 3.02131 11.544 3.05811L11.0862 3.16516L10.9512 2.71486C10.8915 2.50874 10.7665 2.32758 10.5949 2.19866C10.4234 2.06974 10.2146 2.00002 10 2.00001Z"
          fill="#3766E8"
        />
        <path
          d="M3.25 5.5C2.80499 5.5 2.36998 5.36804 1.99997 5.12081C1.62996 4.87357 1.34157 4.52217 1.17127 4.11104C1.00097 3.6999 0.956417 3.2475 1.04323 2.81105C1.13005 2.37459 1.34434 1.97368 1.65901 1.65901C1.97368 1.34434 2.37459 1.13005 2.81105 1.04323C3.2475 0.956417 3.6999 1.00097 4.11104 1.17127C4.52217 1.34157 4.87357 1.62996 5.12081 1.99997C5.36804 2.36998 5.5 2.80499 5.5 3.25C5.49933 3.84653 5.26206 4.41844 4.84025 4.84025C4.41844 5.26206 3.84653 5.49933 3.25 5.5ZM3.25 2C3.00277 2 2.7611 2.07331 2.55554 2.21066C2.34998 2.34802 2.18976 2.54324 2.09515 2.77165C2.00054 3.00005 1.97579 3.25139 2.02402 3.49386C2.07225 3.73634 2.1913 3.95907 2.36612 4.13388C2.54093 4.3087 2.76366 4.42775 3.00614 4.47598C3.24861 4.52421 3.49995 4.49946 3.72836 4.40485C3.95676 4.31024 4.15199 4.15003 4.28934 3.94446C4.42669 3.7389 4.5 3.49723 4.5 3.25C4.4996 2.9186 4.36778 2.60089 4.13345 2.36656C3.89911 2.13222 3.5814 2.0004 3.25 2Z"
          fill="#3766E8"
        />
      </g>
      <defs>
        <clipPath id="clip0_48036_14461">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconHeatMap;
