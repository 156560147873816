import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";
import "../style.scss"; // Adjust the path according to your project structure

function PosOrderDetailsSkeleton({ fullPageSkeleton }) {
  return (
    <div className={fullPageSkeleton ? "m-20" : ""}>
      <div className="orderDetails">
        <PageTitleSkeleton />
      </div>
      {/* <Skeleton width="20%" height={30} /> */}

      <div className="gridBox mt-3">
        <div className="orderDetails">
          <Skeleton width="100%" height={230} />
        </div>
        <div className="transactionDetails ">
          <Skeleton width="100%" height={230} />
        </div>

        <div className="notesComponentBox">
          <Skeleton width="100%" height={150} />
        </div>

        <div className="videoComponentBox">
          <Skeleton width="100%" height={300} />
        </div>

        <div className="itemsDetailBox">
          <Skeleton width="100%" height="100%" />
        </div>

        <div className="eventsBox">
          <Skeleton width="100%" height={150} />
        </div>
      </div>
    </div>
  );
}

export default PosOrderDetailsSkeleton;
