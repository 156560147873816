import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";

const DetectionList = lazy(() => import("../../Components/Detection"));

function Detection() {
  return (
    <PageContainer
      initialPageTitle="Detection"
      meta="Track Drivethru analysis on selected location"
      withLayout={false}
    >
      <DetectionList />
    </PageContainer>
  );
}

export default Detection;
