import PropTypes from "prop-types";
import React from "react";

function IconRange({ color }) {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9993 10V12H12.666V10H11.3327V8.66667H15.3327V10H13.9993ZM3.33268 10V12H1.99935V10H0.666016V8.66667H4.66602V10H3.33268ZM7.33268 2V0H8.66602V2H9.99935V3.33333H5.99935V2H7.33268ZM7.33268 4.66667H8.66602V12H7.33268V4.66667ZM1.99935 7.33333V0H3.33268V7.33333H1.99935ZM12.666 7.33333V0H13.9993V7.33333H12.666Z"
        fill={color}
      />
    </svg>
  );
}

IconRange.propTypes = {
  color: PropTypes.string,
};

IconRange.defaultProps = {
  color: "#545454",
};

export default IconRange;
