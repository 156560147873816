import React from "react";
import Skeleton from "react-loading-skeleton";
import "./index.scss";

const SkeletonGrid = () => {
  return (
    <div className={""}>
      <div className="skeleton-grid row mb-4">
        {Array(4)
          .fill()
          .map((e, index) => (
            <div className={"mb-2 col-xl-3 col-lg-4"} key={index}>
              <Skeleton width={"100%"} height={300} />
            </div>
          ))}
        {Array(4)
          .fill()
          .map((e, index) => (
            <div className={"mb-2 col-xl-3 col-lg-4"} key={index}>
              <Skeleton width={"100%"} height={300} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default SkeletonGrid;
