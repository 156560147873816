import React from "react";

function Footer() {
  return (
    <div className="pt-2 pb-3">
      <div className="d-flex align-items-center justify-content-center text-center">
        <img
          className="img-fluid sidebar-logo mr-2"
          src="/assets/images/wobot-Icon-blue.svg"
          alt="logo"
        />
        <p className="mb-0">
          Powered by{" "}
          <span>
            <b className="primary-color">Wobot AI</b>
          </span>
        </p>
      </div>
    </div>
  );
}

export default Footer;
