import PropTypes from "prop-types";
import React from "react";

function IconTrash({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.68921H15C16.1046 1.68921 17 2.58464 17 3.68921V4.68921H20C21.1046 4.68921 22 5.58464 22 6.68921V8.68921C22 9.79378 21.1046 10.6892 20 10.6892H19.9199L19 21.6892C19 22.7938 18.1046 23.6892 17 23.6892H7C5.89543 23.6892 5 22.7938 5.00345 21.7723L4.07987 10.6892H4C2.89543 10.6892 2 9.79378 2 8.68921V6.68921C2 5.58464 2.89543 4.68921 4 4.68921H7V3.68921C7 2.58464 7.89543 1.68921 9 1.68921ZM4 6.68921H7H17H20V8.68921H4V6.68921ZM6.08649 10.6892H17.9132L17.0035 21.6062L17 21.6892H7L6.08649 10.6892ZM15 3.68921V4.68921H9V3.68921H15Z"
        fill={color}
      />
    </svg>
  );
}

IconTrash.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};
IconTrash.defaultProps = {
  size: 16,
  color: "#545454",
};
export default IconTrash;
