import React from "react";

const IconNextSkip = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_33329_213663)">
        <path d="M6 18L14.5 12L6 6V18ZM16 6V18H18V6H16Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_33329_213663">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconNextSkip;
