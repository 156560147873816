import React from "react";

const IconAddLocation = ({ width = "26", height = "30", color = "#545454" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6666 0.333333V4.33333H25.6666V7H21.6666V11H18.9999V7H14.9999V4.33333H18.9999V0.333333H21.6666ZM10.9999 16.3333C9.53325 16.3333 8.33325 15.1333 8.33325 13.6667C8.33325 12.2 9.53325 11 10.9999 11C12.4666 11 13.6666 12.2 13.6666 13.6667C13.6666 15.1333 12.4666 16.3333 10.9999 16.3333ZM12.3333 3.08V5.77333C11.8922 5.70364 11.4464 5.66798 10.9999 5.66667C6.53325 5.66667 2.99992 9.09333 2.99992 13.9333C2.99992 17.0533 5.59992 21.1867 10.9999 26.12C16.3999 21.1867 18.9999 17.0667 18.9999 13.9333V13.6667H21.6666V13.9333C21.6666 18.36 18.1066 23.6 10.9999 29.6667C3.89325 23.6 0.333252 18.36 0.333252 13.9333C0.333252 7.29333 5.39992 3 10.9999 3C11.4533 3 11.8933 3.02667 12.3333 3.08Z"
        fill={color}
      />
    </svg>
  );
};

export default IconAddLocation;
