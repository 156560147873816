import moment from "moment";
import "moment-timezone";
import { store } from "../Store";

const GetDateRangeList = (tz) => {
  let { userData } = store.getState();

  if (tz) {
    var date = new Date();
    var dateFilter = [
      {
        range: "Today",
        date: [
          moment(date)?.tz(tz).format("MM-DD-YYYY"),
          moment(date)?.tz(tz).format("MM-DD-YYYY"),
        ],
      },
      {
        range: "Yesterday",
        date: [
          moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1))
            .tz(tz)
            .format("MM-DD-YYYY"),
          moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1))
            .tz(tz)
            .format("MM-DD-YYYY"),
        ],
      },
      {
        range: "Last 7 Days",
        date: [
          moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6))
            .tz(tz)
            .format("MM-DD-YYYY"),
          moment(date).tz(tz).format("MM-DD-YYYY"),
        ],
      },
      {
        range: "Last 15 Days",
        date: [
          moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 15))
            .tz(tz)
            .format("MM-DD-YYYY"),
          moment(date).tz(tz).format("MM-DD-YYYY"),
        ],
      },
      {
        range: "Last 30 Days",
        date: [
          moment(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30))
            .tz(tz)
            .format("MM-DD-YYYY"),
          moment(date).tz(tz).format("MM-DD-YYYY"),
        ],
      },
      {
        range: "Last 3 Months",
        date: [
          moment(date).subtract(3, "months").add(1, "day").tz(tz).format("MM-DD-YYYY"),
          moment(date).tz(tz).format("MM-DD-YYYY"),
        ],
      },
      {
        range: "Last 6 Months",
        date: [
          moment(date).subtract(6, "months").add(1, "day").tz(tz).format("MM-DD-YYYY"),
          moment(date).tz(tz).format("MM-DD-YYYY"),
        ],
      },
      // {
      //   range: "Last 60 Days",
      //   date: [
      //     moment(
      //       new Date(date.getFullYear(), date.getMonth(), date.getDate() - 60)
      //     )
      //       .tz(tz)
      //       .format("MM-DD-YYYY"),
      //     moment(date).tz(tz).format("MM-DD-YYYY"),
      //   ],
      // },
      {
        range: "Previous Month",
        date: [
          moment(new Date(date.getFullYear(), date.getMonth() - 1, 1))
            .tz(tz)
            .format("MM-DD-YYYY"),
          moment(new Date(date.getFullYear(), date.getMonth() - 1, 1))
            .endOf("month")
            .tz(tz)
            .format("MM-DD-YYYY"),
        ],
      },
      // moment(new Date(date.getFullYear(), date.getMonth() - 1, 1))
      //       .tz(tz)
      //       .format("MM-DD-YYYY"),
      //     moment(new Date())
      //       .subtract(1, "months")
      //       .endOf("month")
      //       .tz(tz)
      //       .format("MM-DD-YYYY"),

      // {
      //   range: "This Year",
      //   date: [
      //     moment(new Date(date.getFullYear(), 0, 1))
      //       .tz(tz)
      //       .format("MM-DD-YYYY"),
      //     moment(date).tz(tz).format("MM-DD-YYYY"),
      //   ],
      // },

      // {
      //   range: "All Time",
      //   date: [
      //     userData?.manifest?.created_at
      //       ? moment(userData?.manifest?.created_at).tz(tz).format("MM-DD-YYYY")
      //       : "01-01-1970",
      //     moment(date).tz(tz).format("MM-DD-YYYY"),
      //   ],
      // },

      // {
      //   range: "Custom",
      //   date: [
      //     moment(date).tz(tz).format("MM-DD-YYYY"),
      //     moment(date).tz(tz).format("MM-DD-YYYY"),
      //   ],
      // },
    ];
    return dateFilter;
  } else {
    return [];
  }
};

export { GetDateRangeList };
