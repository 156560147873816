import React from "react";

function IconFileLine() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 1.66867V1.66602H16.6648C17.1261 1.66602 17.5 2.04544 17.5 2.49252V17.5062C17.5 17.9627 17.1292 18.3327 16.6722 18.3327H3.32783C2.87063 18.3327 2.5 17.9578 2.5 17.4937V6.66602L7.5 1.66867ZM4.85765 6.66602H7.5V4.02507L4.85765 6.66602ZM9.16667 3.33268V7.49935C9.16667 7.95958 8.79358 8.33268 8.33333 8.33268H4.16667V16.666H15.8333V3.33268H9.16667Z"
        fill="#7E7E7E"
      />
    </svg>
  );
}

export default IconFileLine;
