import moment from "moment";
import { useEffect, useState } from "react";
import { DateRange, DefinedRange, createStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useDetectClickOutside } from "react-detect-click-outside";
import { GetDateRangeList } from "../../../Services/GetDateRangeList";
import { getRangeColorsByHost } from "../../../Utils/getRangeColorsByHost";
import IconArrowDown from "../IconsComponent/IconArrowDown";
import IconScheduleCalender from "../IconsComponent/IconScheduleCalender";
import styles from "./DateFilterStyle.module.scss";

function DateFilterComponent(props) {
  const [staticRanges, setStaticRanges] = useState([]);
  const [dateRangeCount, setDateRangeCount] = useState(0);

  const dateRangeList = GetDateRangeList(Intl.DateTimeFormat()?.resolvedOptions()?.timeZone);

  const closeDateRange = (e) => {
    props.setShowDateRange(false);
  };

  const ref = useDetectClickOutside({ onTriggered: closeDateRange });

  const getStaticDateRanges = () => {
    const result = [];
    dateRangeList.map((el) => {
      result.push({
        label: el.range,
        range: () => ({
          startDate: new Date(moment(el.date[0], "MM-DD-YYYY")),
          endDate: new Date(moment(el.date[1], "MM-DD-YYYY")),
        }),
      });
    });
    const staticRangesList = createStaticRanges(result);
    setStaticRanges(staticRangesList);
  };

  const dateStylesByHost = {
    border: props.showDateRange ? `1px solid ${getRangeColorsByHost()}` : "1px solid #CED4DA",
    boxShadow: props.showDateRange ? `0 0 0 1px ${getRangeColorsByHost()}` : "unset",
  };

  useEffect(() => {
    getStaticDateRanges();
  }, []);

  return (
    <div
      className={`${styles.dateContainer} ${
        props?.dateContainerStyles && props?.dateContainerStyles
      }`}
      ref={ref}
    >
      <div
        style={dateStylesByHost}
        className={`${styles.fieldWrapper} ${props.Style ? props.Style : ""}`}
        onClick={() => props.setShowDateRange(!props.showDateRange)}
      >
        <label className="mb-0 mr-1">
          <IconScheduleCalender />
          {" "}
        </label>
        {" "}
        {!props?.startDate && !props?.endDate
          ? "Select..."
          : `${props.startDate} - ${props.endDate}`}
      </div>
      <div
        className={`${styles.dateArrow}  ${props.showDateRange ? styles.dateArrowActive : ""} `}
        onClick={() => props.setShowDateRange(!props.showDateRange)}
      >
        <IconArrowDown size={20} />
      </div>
      {/* Options Panel */}
      <div
        className={`${styles.dateRange} ${props.className ? props.className : ""}`}
        hidden={!props.showDateRange}
        onClick={(e) => e.stopPropagation()}
      >
        <DefinedRange
          ranges={props.selectionRange}
          inputRanges={[]}
          staticRanges={staticRanges}
          onChange={(ranges) => {
            const { selection } = ranges;
            props.setSelectionRange([selection]);
            props.onDateChange(ranges);
            if (!props?.showActionBtn) props.setShowDateRange(false);
          }}
          rangeColors={getRangeColorsByHost(false)}
        />
        <div>
          <DateRange
            className={!props?.showActionBtn ? "h-100" : ""}
            onChange={(ranges) => {
              const { selection } = ranges;
              props.setSelectionRange([selection]);
              const newCount = dateRangeCount + 1;
              setDateRangeCount(newCount);
              if (props.setSelectedCount) props.setSelectedCount(newCount);
              if (newCount % 2 === 0) {
                props.onDateChange(ranges);
                if (!props?.showActionBtn) props.setShowDateRange(false);
              }
            }}
            weekStartsOn={1}
            ranges={props.selectionRange}
            maxDate={new Date()}
            rangeColors={getRangeColorsByHost(false)}
          />
          {props?.showActionBtn ? (
            <div
              className={`d-flex align-items-center justify-content-end pure-white-bg ${styles.action_btn_wrapper} `}
            >
              <button className="btn btn-textIcon btn-sm mr-2" onClick={() => props.onNo()}>
                {props.secondaryBtnText}
              </button>
              <button className="btn btn-primary btn-sm" onClick={() => props.onYes()}>
                {props.primaryBtnText}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default DateFilterComponent;
