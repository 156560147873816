import React from "react";

const IconCheckedFilled = ({ color = "#194DDE" }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_34913_36766)">
        <rect width="16" height="16" rx="8" fill={color} />
        <path
          d="M6.6665 10.115L12.7945 3.98633L13.7378 4.92899L6.6665 12.0003L2.42383 7.75766L3.3665 6.81499L6.6665 10.115Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_34913_36766">
          <rect width="16" height="16" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCheckedFilled;
