import React from "react";
import Skeleton from "react-loading-skeleton";
import "./style.scss";

function StationsSkeleton() {
  return (
    <div className="drive-journey-wrapper my-4 ">
      <div className="drive-journey-cont row align-items-center">
        <div className="drive-journey-card col">
          <Skeleton width={290} height={150} />
        </div>
        <div className=" drive-journey-road col">
          {" "}
          <Skeleton width="100%" height={45} />
        </div>
        <div className="drive-areaCard col">
          <Skeleton width={290} height={150} />
        </div>
        <div className=" drive-journey-road col">
          {" "}
          <Skeleton width="100%" height={45} />
        </div>
        <div className="drive-areaCard col">
          <Skeleton width={290} height={150} />
        </div>
      </div>
    </div>
  );
}

export default StationsSkeleton;
