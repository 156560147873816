import React from "react";

const IconEmployee = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 55 55" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.5 25C41.75 25 45 21.75 45 17.5C45 13.25 41.75 10 37.5 10C33.25 10 30 13.25 30 17.5C30 21.75 33.25 25 37.5 25ZM17.5 25C21.75 25 25 21.75 25 17.5C25 13.25 21.75 10 17.5 10C13.25 10 10 13.25 10 17.5C10 21.75 13.25 25 17.5 25ZM17.5 30C11.75 30 0 33 0 38.75V45H35V38.75C35 33 23.25 30 17.5 30ZM37.5 30C36.75 30 36 30 35 30.25C38 32.25 40 35.25 40 38.75V45H55V38.75C55 33 43.25 30 37.5 30Z"
        fill="black"
      />
    </svg>
  );
};

export default IconEmployee;
