import React from "react";

function IconPerson() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12ZM15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7Z"
        fill="#545454"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 18.25V22H2V18.25C2 14.925 8.6625 13.25 12 13.25C15.3375 13.25 22 14.925 22 18.25ZM4 18.25C4 18.1533 4.03255 17.9206 4.44962 17.5357C4.88027 17.1383 5.57283 16.728 6.49218 16.3597C8.33409 15.6218 10.5813 15.25 12 15.25C13.4187 15.25 15.6659 15.6218 17.5078 16.3597C18.4272 16.728 19.1197 17.1383 19.5504 17.5357C19.9674 17.9206 20 18.1533 20 18.25V20H4V18.25Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconPerson;
