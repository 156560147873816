import {
  SET_EMPTY_MESSAGES,
  SET_IN_FULL_VIEW_MODE,
  SET_IS_OPEN_ASSISTANT,
  SET_MESSAGES,
  SET_SELECTED_OPTION,
} from ".";

export const setIsOpenAssistant = (payload) => ({
  type: SET_IS_OPEN_ASSISTANT,
  payload,
});
export const setMessages = (payload) => ({
  type: SET_MESSAGES,
  payload,
});
export const setEmptyMessages = () => ({
  type: SET_EMPTY_MESSAGES,
});
export const setInFullViewMode = (payload) => ({
  type: SET_IN_FULL_VIEW_MODE,
  payload,
});

export const setSelectedOptions = (payload) => ({
  type: SET_SELECTED_OPTION,
  payload,
});
