import React, { lazy, Suspense } from "react";
import LoginSkeleton from "../Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const ChangePasswordComponent = lazy(
  () => import("../Components/ForgotPasswordComponent/ChangePasswordComponent")
);

function ChangePassword() {
  return (
    <Suspense fallback={<LoginSkeleton />}>
      <ChangePasswordComponent />
    </Suspense>
  );
}

export default ChangePassword;
