import React from "react";

const IconFastForward = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_28267_144624)">
        <path d="M4 18L12.5 12L4 6V18ZM13 6V18L21.5 12L13 6Z" fill="#545454" />
      </g>
      <defs>
        <clipPath id="clip0_28267_144624">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconFastForward;
