import PropTypes from "prop-types";
import React from "react";
import { TASK_TYPES } from "../../../../Constants";

// Import your icons here
import IconCountBased from "../../IconsComponent/IconCountBased";
import IconHeatMap from "../../IconsComponent/IconHeatMap";
import IconJourney from "../../IconsComponent/IconJourney";
import IconOccupancyBased from "../../IconsComponent/IconOccupancyBased";
import IconProcessBased from "../../IconsComponent/IconProcessBased";
import IconTime from "../../IconsComponent/IconTime";
import IconWopipe from "../../IconsComponent/IconWopipe";
import IconProcessCompliance from "../../IconsComponent/IconprocessCompliance";

// Add, update icon here only
const iconRegistry = {
  [TASK_TYPES.TIME_BASED]: IconTime,
  [TASK_TYPES.PROCESS_BASED]: IconProcessBased,
  [TASK_TYPES.COUNT_BASED]: IconCountBased,
  [TASK_TYPES.OCCUPANCY_BASED]: IconOccupancyBased,
  [TASK_TYPES.JOURNEY_BASED]: IconJourney,
  [TASK_TYPES.HEATMAP_BASED]: IconHeatMap,
  [TASK_TYPES.PROCESS_COMPLIANCE]: IconProcessCompliance,
  [TASK_TYPES.WOPIPE]: IconWopipe,
};

/**
 * Renders a task type tag with an associated icon.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.tagType - The type of the task tag (e.g., TASK_TYPES.TIME_BASED).
 * @param {string} props.tagTypeLabel - The label to display with the icon.
 * @returns {ReactElement|null} A React component representing the task type tag.
 */
function TaskTypeTag(props) {
  const IconComponent = iconRegistry[props?.tagType];

  if (!IconComponent) {
    return null;
  }

  return (
    <div className="list-inline-item tag-block tag-lg tag-default icon-color-default mr-0">
      <IconComponent />
      <span className="ml-1">{props?.tagTypeLabel}</span>
    </div>
  );
}

TaskTypeTag.propTypes = {
  tagType: PropTypes.oneOf(Object.values(TASK_TYPES)).isRequired,
  tagTypeLabel: PropTypes.string.isRequired,
};

export default TaskTypeTag;
