import React from "react";
import Skeleton from "react-loading-skeleton";

function AnalyticsSkeleton() {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <div className="header-section">
          <div className="flex-wrapper">
            <div className="header-block">
              <Skeleton width={100} height={10} className="mb-1" />
              {/* Page Title */}
              <div className="header-title--skeleton">
                {/* <span className="rtsp-cursor mr-2" onClick={() => { history.goBack(); }}>
                  <IconArrowLeft />
                </span> */}
                <Skeleton width={100} height={25} className="mb-1" />
              </div>
            </div>
          </div>
        </div>

        <div className="list-inline-item">
          <Skeleton width={700} height={30} />
        </div>

        <div className="panel-body mt-60 row ">
          {Array(6)
            .fill()
            .map((e, index) => (
              <div className="col-lg-6 col-md-6 mb-4" key={index}>
                <Skeleton height={300} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default AnalyticsSkeleton;
