import { CHANGE_ACTIVE_SIDEBAR } from "../constants";

const initialState = [];

export default function activeNavbarReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_ACTIVE_SIDEBAR:
      return action.value;
    default:
      return state;
  }
}
