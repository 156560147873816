import React from "react";

export default function IconOut() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.93223 0.320312H10.3576C10.7469 0.320312 11.1204 0.474989 11.3957 0.750315C11.671 1.02564 11.8257 1.39906 11.8257 1.78843V3.46629C11.8257 3.63316 11.7594 3.7932 11.6414 3.91119C11.5234 4.02919 11.3634 4.09548 11.1965 4.09548C11.0296 4.09548 10.8696 4.02919 10.7516 3.91119C10.6336 3.7932 10.5673 3.63316 10.5673 3.46629V1.78843C10.5673 1.73281 10.5452 1.67946 10.5059 1.64013C10.4665 1.6008 10.4132 1.5787 10.3576 1.5787H1.96327C1.90859 1.58023 1.85665 1.60297 1.81844 1.64211C1.78024 1.68126 1.75877 1.73373 1.75857 1.78843V15.2113C1.75863 15.2389 1.76415 15.2662 1.7748 15.2917C1.78545 15.3172 1.80103 15.3403 1.82065 15.3597C1.83998 15.3792 1.86298 15.3947 1.88833 15.4052C1.91368 15.4157 1.94086 15.4211 1.9683 15.421H10.3576C10.4132 15.421 10.4665 15.3989 10.5059 15.3596C10.5452 15.3202 10.5673 15.2669 10.5673 15.2113V13.5334C10.5673 13.3665 10.6336 13.2065 10.7516 13.0885C10.8696 12.9705 11.0296 12.9042 11.1965 12.9042C11.3634 12.9042 11.5234 12.9705 11.6414 13.0885C11.7594 13.2065 11.8257 13.3665 11.8257 13.5334V15.2113C11.8257 15.6006 11.671 15.974 11.3957 16.2494C11.1204 16.5247 10.7469 16.6794 10.3576 16.6794H1.9683C1.579 16.6792 1.20571 16.5244 0.930428 16.2491C0.65515 15.9738 0.500402 15.6006 0.50018 15.2113V1.78843C0.500278 1.4053 0.650062 1.03736 0.917588 0.763096C1.18512 0.48883 1.54921 0.32994 1.93223 0.320312Z"
        fill="#7E7E7E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9087 10.5714C12.8502 10.6299 12.8039 10.6992 12.7723 10.7756C12.7406 10.852 12.7243 10.9338 12.7243 11.0165C12.7243 11.0991 12.7406 11.181 12.7723 11.2573C12.8039 11.3337 12.8502 11.4031 12.9087 11.4615C12.9671 11.52 13.0365 11.5663 13.1129 11.598C13.1892 11.6296 13.2711 11.6459 13.3537 11.6459C13.4364 11.6459 13.5182 11.6296 13.5946 11.598C13.671 11.5663 13.7403 11.52 13.7988 11.4615L16.3156 8.94474C16.374 8.88631 16.4204 8.81693 16.4521 8.74057C16.4837 8.6642 16.5 8.58235 16.5 8.49969C16.5 8.41703 16.4837 8.33518 16.4521 8.25882C16.4204 8.18245 16.374 8.11307 16.3156 8.05464L13.7988 5.53786C13.6808 5.41983 13.5207 5.35352 13.3537 5.35352C13.1868 5.35352 13.0267 5.41983 12.9087 5.53786C12.7907 5.6559 12.7243 5.81599 12.7243 5.98291C12.7243 6.14984 12.7907 6.30993 12.9087 6.42796L14.3516 7.87092H6.16289C5.99601 7.87092 5.83598 7.93721 5.71798 8.0552C5.59998 8.1732 5.53369 8.33324 5.53369 8.50011C5.53369 8.66698 5.59998 8.82702 5.71798 8.94502C5.83598 9.06302 5.99601 9.1293 6.16289 9.1293H14.3516L12.9087 10.5714Z"
        fill="#7E7E7E"
      />
    </svg>
  );
}
