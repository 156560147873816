import React from "react";

const IconFullScreenExit = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.14288 24L8.57147 24L8.57147 15.4286L2.59535e-05 15.4286L2.62533e-05 18.8571L5.14288 18.8571L5.14288 24Z"
        fill="#545454"
      />
      <path
        d="M8.57147 8.7738e-05L5.14288 8.80378e-05L5.14288 5.14294L2.64031e-05 5.14294L2.67029e-05 8.57153L8.57147 8.57153L8.57147 8.7738e-05Z"
        fill="#545454"
      />
      <path
        d="M24 8.57153L24 5.14294L18.8571 5.14294L18.8571 8.72884e-05L15.4286 8.69887e-05L15.4286 8.57153L24 8.57153Z"
        fill="#545454"
      />
      <path
        d="M18.8571 18.8571L24 18.8571L24 15.4286L15.4286 15.4286L15.4286 24L18.8571 24L18.8571 18.8571Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconFullScreenExit;
