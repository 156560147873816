import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../../Molecule/PageTitleSkeleton";

import BlocksComponent from "../../../../Molecule/Block";

function MyTaskSkeleton() {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <PageTitleSkeleton />
        <div className="mb-5 mt-5">
          <Skeleton width={700} height={20} />
        </div>
        <div className="panel-body mt-60 row mr-3">
          {Array(3)
            .fill()
            .map((e, index) => (
              <div className="col-xl-4 col-lg-6 col-md-6" key={index}>
                <BlocksComponent>
                  <div className="element-section mb-0 panel-fh">
                    <div className="element-content-wrapper">
                      <div className="d-flex align-self-center">
                        <div className="element-content-block">
                          <h6 className="text-primary mb-1">
                            <Skeleton height={20} width={100} />
                          </h6>
                          <p className="mb-0 fw-500">
                            <Skeleton />
                          </p>
                        </div>
                      </div>
                    </div>
                    <table>
                      <tbody className="data-table-list">
                        <Skeleton count={4} width={300} style={{ marginBottom: "15px" }} />
                      </tbody>
                    </table>
                  </div>
                </BlocksComponent>
                {/* <Skeleton height={250} width={500} /> */}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default MyTaskSkeleton;
