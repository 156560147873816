import React from "react";

const IconMoniter = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.3332 11.3333H15.6665V2.16667H2.3332V11.3333ZM9.83321 13V14.6667H13.1665V16.3333H4.8332V14.6667H8.16654V13H1.4932C1.38378 12.9993 1.27555 12.9771 1.17474 12.9345C1.07392 12.892 0.982494 12.83 0.905697 12.752C0.828901 12.674 0.768245 12.5817 0.727205 12.4802C0.686165 12.3788 0.665549 12.2703 0.666538 12.1608V1.33917C0.666538 0.875833 1.0457 0.5 1.4932 0.5H16.5065C16.9632 0.5 17.3332 0.874167 17.3332 1.33917V12.1608C17.3332 12.6242 16.954 13 16.5065 13H9.83321V13Z"
        fill="#3766E8"
      />
    </svg>
  );
};

export default IconMoniter;
