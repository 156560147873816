import React from "react";
import Breadcrumb from "../Breadcrumb";
import "./style.scss";

function PageTitle(props) {
  return (
    <div className="header-section">
      <div className="flex-wrapper">
        <div className="header-block">
          {props.breadcrumb && <Breadcrumb data={props.breadcrumb} />}
          {/* Page Title */}
          <div className="header-title">
            <div className={`${props.className} header-title-text`}>
              <h4 className="header-primary mb-0">{props.pageTitle} </h4>
              {props.showFlexItem && <div className={`${props.marginTop}`}>{props.flexItem}</div>}
            </div>
            {props.showMetaName && <span>{props.pageMetaName}</span>}
          </div>
          {props.showSubTitle && <p className="sub-title">{props.subTitle || ""}</p>}
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default PageTitle;
