import React from "react";

const IconInfoToast = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM9.9 13.5H8.1V8.1H9.9V13.5ZM9.9 6.3H8.1V4.5H9.9V6.3Z"
        fill="#3766E8"
      />
    </svg>
  );
};

export default IconInfoToast;
