import React from "react";
import Skeleton from "react-loading-skeleton";
import "./style.scss";

const CustomerAnalyticsSkeleton = () => {
  return (
    <div className="analytic-panel-main">
      <div className="analytic-body-wrapper">
        <div className={"analytic-compare pt-4 pl-3 "}>
          <Skeleton width={200} height={30} />
          <div className={"analytic-compareCont row"}>
            {Array(3)
              .fill()
              .map((ele, i) => {
                return (
                  <div key={i} className={`analytic-compareData mt-4 col-md-4`}>
                    <Skeleton width={150} height={20} className={"mb-2"} />
                    <Skeleton width={270} height={40} />
                  </div>
                );
              })}
          </div>
        </div>
        <div className={"analytic-area mt-5 "}>
          <Skeleton width={100} height={30} />
          <div className={"analytic-areaCont row"}>
            {Array(4)
              .fill()
              .map((e, index) => {
                return (
                  <div key={index} className="analytic-areaCard col-md-3 mt-4">
                    <Skeleton width={200} height={210} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default CustomerAnalyticsSkeleton;
