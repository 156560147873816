import React, { lazy, Suspense } from "react";
import LoginSkeleton from "../Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const AddLocationComponent = lazy(
  () => import("../Components/SignupComponent/AddLocationComponent")
);

function AddLocation() {
  return (
    <Suspense fallback={<LoginSkeleton width="60vw" height={460} />}>
      <AddLocationComponent />
    </Suspense>
  );
}

export default AddLocation;
