export const REGION_TYPES = {
  CITY: "city",
  REGION: "region",
  LOCATION: "location",
};

export const getRegionsForParentRegion = (
  regions = [],
  parentId = null,
  regionType = REGION_TYPES.LOCATION
) =>
  regions.reduce((list, { id, area, type, parent }) => {
    if (parent === parentId && type === regionType) {
      list.push({ label: area, value: id, parent });
    }

    return list;
  }, []);

export const getFormattedRegionsOptions = (regions = []) => {
  const regionsData = {};

  regions.forEach(({ id: value, area: label, type, parent }) => {
    if (type === REGION_TYPES.REGION) {
      if (!regionsData[value]) {
        regionsData[value] = {
          label,
          value,
          isDisabled: true,
          cities: [],
        };
      } else {
        regionsData[value].label = label;
      }
    }

    if (type === REGION_TYPES.CITY) {
      if (!regionsData[parent]) {
        regionsData[parent] = {
          label: "NA",
          value: parent,
          isDisabled: true,
          cities: [],
        };
      }

      regionsData[parent].cities.push({
        label,
        value,
        parent,
      });
    }
  });

  return Object.keys(regionsData).reduce((list, key) => {
    list.push(regionsData[key]);
    list.push(...regionsData[key].cities);
    return list;
  }, []);
};
