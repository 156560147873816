import React from "react";

function IconRole({ color = "#545454", compact = false }) {
  return compact ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
      <path
        d="M6.5 2.1625C7.4425 2.1625 8.20625 2.985 8.20625 4C8.20625 5.015 7.4425 5.8375 6.5 5.8375C5.5575 5.8375 4.79375 5.015 4.79375 4C4.79375 2.985 5.5575 2.1625 6.5 2.1625ZM6.5 10.0375C8.91313 10.0375 11.4563 11.315 11.4563 11.875V12.8375H1.54375V11.875C1.54375 11.315 4.08687 10.0375 6.5 10.0375ZM6.5 0.5C4.70437 0.5 3.25 2.06625 3.25 4C3.25 5.93375 4.70437 7.5 6.5 7.5C8.29562 7.5 9.75 5.93375 9.75 4C9.75 2.06625 8.29562 0.5 6.5 0.5ZM6.5 8.375C4.33063 8.375 0 9.5475 0 11.875V14.5H13V11.875C13 9.5475 8.66938 8.375 6.5 8.375Z"
        fill="#A0A0A0"
      />
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <g clip-path="url(#clip0_40484_55722)"> */}
      <path
        d="M8.00002 4.00033C8.73335 4.00033 9.33335 4.60033 9.33335 5.33366C9.33335 6.06699 8.73335 6.66699 8.00002 6.66699C7.26669 6.66699 6.66669 6.06699 6.66669 5.33366C6.66669 4.60033 7.26669 4.00033 8.00002 4.00033ZM8.00002 10.0003C9.80002 10.0003 11.8667 10.8603 12 11.3337V12.0003H4.00002V11.3403C4.13335 10.8603 6.20002 10.0003 8.00002 10.0003ZM8.00002 2.66699C6.52669 2.66699 5.33335 3.86033 5.33335 5.33366C5.33335 6.80699 6.52669 8.00033 8.00002 8.00033C9.47335 8.00033 10.6667 6.80699 10.6667 5.33366C10.6667 3.86033 9.47335 2.66699 8.00002 2.66699ZM8.00002 8.66699C6.22002 8.66699 2.66669 9.56033 2.66669 11.3337V13.3337H13.3334V11.3337C13.3334 9.56033 9.78002 8.66699 8.00002 8.66699Z"
        fill={color}
      />
      {/* </g> */}
    </svg>
  );
}

export default IconRole;
