import { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { API_BASE_URL, API_CONFIG_NO_MANIFEST_CALL } from "../../../../Constants";
import callApi from "../../../../Services/callApi";
import { getAllQueryVariables } from "../../../../Services/getQueryVariable";

const useGetMonitoringData = (selectedLocation) => {
  const { taskId } = getAllQueryVariables();
  const [loadingMonitoringData, setLoadingMonitoringData] = useState(true);
  const [monitoringData, setMonitoringData] = useState();
  const todaysDate = moment(new Date()).format("YYYY-MM-DD");
  const getMonitoringData = useCallback(async () => {
    setLoadingMonitoringData(true);
    const response = await callApi(
      `${API_BASE_URL}/graph/${taskId}?type=insightV2&location=${
        selectedLocation?.value
      }&camera=&schedule=&raisedBy=&from=${todaysDate || "2024-08-20"}&to=${
        todaysDate || "2024-08-21"
      }`,
      {
        method: "GET",
      },
      API_CONFIG_NO_MANIFEST_CALL
    );
    if (response?.status === 200 && response?.data) {
      const { data } = response;
      setMonitoringData(data);
    }
    setLoadingMonitoringData(false);
  }, [selectedLocation?.value, taskId]);

  useEffect(() => {
    if (taskId && selectedLocation?.value) {
      getMonitoringData();
    }
  }, [taskId, selectedLocation]);

  return { monitoringData, loadingMonitoringData, setMonitoringData };
};

export default useGetMonitoringData;
