import React, { useState } from "react";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { colourStyles } from "../../../../Services/colourStyles";
import { getLocationLabelIcon } from "../../../../Services/LabelFilterIcons";
import style from "../style.module.scss";

export default function FilterComponent({ setSelectedLocation, locationData, selectedLocation }) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  return (
    <div className="d-flex justify-content-between w-100 align-items-end">
      {" "}
      <div>
        <li className={`${"list-inline-item"}`}>
          <div>
            <Select
              styles={colourStyles}
              components={{
                IndicatorSeparator: () => null,
              }}
              value={{
                label: getLocationLabelIcon(selectedLocation?.label),
                value: selectedLocation?.value,
              }}
              options={locationData}
              className={`${"react-select"} ${style.locationFilter}`}
              onChange={(newValue) => {
                setSelectedLocation(newValue);
              }}
            />
          </div>
        </li>
        {/* <li className={`${"list-inline-item"}`}>
          <div>
            <Select
              styles={colourStyles}
              components={{
                IndicatorSeparator: () => null,
              }}
              value={{
                label: getLocationLabelIcon(selectedLocationFilter?.label),
                value: selectedLocationFilter?.value,
              }}
              options={locationData}
              className={`${"react-select"} ${style.locationFilter}`}
              onChange={(newValue) => {
                // if (selectedLocationFilter?.value !== newValue?.value) {
                //   setSelectedLocationFilter(newValue);
                // }
              }}
            />
          </div>
        </li> */}
      </div>
    </div>
  );
}
