import React, { useEffect, lazy, Suspense } from "react";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../Constants";
import LoginSkeleton from "../Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const VerifyComponent = lazy(() => import("../Components/ForgotPasswordComponent/VerifyComponent"));

function Verify(props) {
  const history = useHistory();
  useEffect(() => {
    if (!props?.location?.state?.user?.email || !props?.location?.state?.user?.id) {
      history.push(LOGIN);
    }
  }, [props]);

  return (
    <Suspense fallback={<LoginSkeleton height={360} width={550} />}>
      <VerifyComponent
        user={
          props && props.location && props.location.state && props.location.state.user
            ? props.location.state.user
            : null
        }
      />
    </Suspense>
  );
}

export default Verify;
