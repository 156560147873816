import React from "react";

function IconExceptionLine() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.25467 2C8.50713 2 8.738 2.14267 8.85093 2.36853L9.33333 3.33333H13.3333C13.7015 3.33333 14 3.63181 14 4V11.3333C14 11.7015 13.7015 12 13.3333 12H9.07867C8.8262 12 8.59533 11.8573 8.4824 11.6315L8 10.6667H3.33333V14.6667H2V2H8.25467ZM7.8426 3.33333H3.33333V9.33333H8.82407L9.49073 10.6667H12.6667V4.66667H8.50927L7.8426 3.33333Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconExceptionLine;
