import PropTypes from "prop-types";
import React from "react";
import { allowedCompanies, queriesTags } from "../../Resources";
import { getCurrentCompanyWithTheirAIFeatures } from "../../Utils";
import styles from "../Styles.module.scss";

function MessageTags({ searchOption, searchLocation, handleSelect, isSubmitting }) {
  const tags = () => {
    const options = queriesTags[searchOption];
    if (Array.isArray(options)) {
      if (
        searchOption === "DriveThru-Data" &&
        allowedCompanies.Pandas.id === getCurrentCompanyWithTheirAIFeatures().id
      ) {
        return options.slice(1);
      }
      return options;
    }

    if (typeof options === "object" && Object.keys(options).length) {
      return options[searchLocation];
    }
    return [];
  };

  return (
    <div className={styles["tag-wrapper"]}>
      {(tags() || []).map((tag) => (
        <button
          key={tag.label}
          type="button"
          className={`btn ${styles["tag-btn"]}`}
          value={tag.message}
          onClick={(e) => handleSelect(e.target.value)}
          disabled={isSubmitting}
        >
          {tag.label}
        </button>
      ))}
    </div>
  );
}
MessageTags.propTypes = {
  searchOption: PropTypes.string.isRequired,
  searchLocation: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
export default MessageTags;
