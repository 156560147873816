import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./Styles.module.scss";

function SettingDataOutApiSkeleton({ className }) {
  return (
    <div className={`${styles.container} ${className || ""}`}>
      <div className={`d-flex justify-content-between w-100 ${styles.header_wrapper}`}>
        <div className={styles.header}>
          <h1>
            <Skeleton width={220} height={30} />
          </h1>
          <p>
            <Skeleton width={400} height={15} />
          </p>
        </div>
      </div>

      <div className="px-4">
        <div className="form-group row">
          <div className="col-3">
            <Skeleton width={100} height={20} />
          </div>
          <div className="col-6">
            <Skeleton width="60%" height={40} />
          </div>
        </div>
        <div className={styles.access_card_container}>
          <Skeleton height={30} width={500} className="mb-2" />
          <div className={styles.access_card_wrapper}>
            <div>
              <Skeleton width={400} height={100} />
            </div>
            <div>
              <Skeleton width={400} height={100} />
            </div>
          </div>
        </div>
        <footer>
          <Skeleton width={400} height={30} />
        </footer>
      </div>
    </div>
  );
}

export default SettingDataOutApiSkeleton;
