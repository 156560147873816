import React from "react";
import Skeleton from "react-loading-skeleton";
import style from "./style.module.scss";

function ComparativeSelectionSkeleton() {
  return (
    <>
      <div className={style.locationContainerWrapper}>
        <div className={style.container}>
          <Skeleton width={160} height={40} />
        </div>
        {[1, 2].map(() => (
          <div className={style.container}>
            <Skeleton width={280} height={80} />
            <Skeleton width={40} height={40} />
          </div>
        ))}
      </div>
      <div className={style.dateContainerWrapper}>
        <div className={`${style.container} ${style.dateContainer}`}>
          <Skeleton width={160} height={40} />
          <Skeleton width={100} height={40} />
        </div>
        {[1, 2].map(() => (
          <div className={style.container}>
            <Skeleton width="100%" height={40} />
          </div>
        ))}
      </div>
    </>
  );
}

export default ComparativeSelectionSkeleton;
