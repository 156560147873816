import React from "react";

function IconFullScreen({ color = "#4B75EA" }) {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.42859 15.4285H0V23.9999H8.57145V20.5713H3.42859V15.4285Z" fill={color} />
      <path d="M0 8.57145H3.42859V3.42859H8.57145V0H0V8.57145Z" fill={color} />
      <path d="M15.4285 0V3.42859H20.5714V8.57145H24V0H15.4285Z" fill={color} />
      <path d="M20.5714 20.5713H15.4285V23.9999H24V15.4285H20.5714V20.5713Z" fill={color} />
    </svg>
  );
}

export default IconFullScreen;
