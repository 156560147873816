import React from "react";

const IconHome = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8333 15.8345H15.8333V8.3162L10 3.77954L4.16667 8.3162V15.8345H9.16667V10.8345H10.8333V15.8345ZM17.5 16.6679C17.5 16.8889 17.4122 17.1008 17.2559 17.2571C17.0996 17.4134 16.8877 17.5012 16.6667 17.5012H3.33333C3.11232 17.5012 2.90036 17.4134 2.74408 17.2571C2.5878 17.1008 2.5 16.8889 2.5 16.6679V7.90954C2.49991 7.78255 2.52885 7.65722 2.5846 7.54312C2.64035 7.42902 2.72143 7.32917 2.82167 7.2512L9.48833 2.0662C9.63462 1.95241 9.81467 1.89062 10 1.89062C10.1853 1.89062 10.3654 1.95241 10.5117 2.0662L17.1783 7.2512C17.2786 7.32917 17.3597 7.42902 17.4154 7.54312C17.4712 7.65722 17.5001 7.78255 17.5 7.90954V16.6679Z"
        fill="#7E7E7E"
      />
    </svg>
  );
};

export default IconHome;
