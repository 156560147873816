import { API_BASE_URL } from "../Constants";
import { store } from "../Store";
import { ADD_ACCESS_LIST, ADDALERTDATA, ADDUSERDATA } from "../Store/constants";
import { getHeaders } from "./getHeaders";
import { showAlert } from "./showAlert";

const updateManifest = async () =>
  fetch(`${API_BASE_URL}/manifest`, { headers: getHeaders() })
    .then((res) => res.json())
    .then((res) => {
      if (res.data) {
        store.dispatch({
          type: ADDUSERDATA,
          value: res.data,
        });
        store.dispatch({
          type: ADDALERTDATA,
          value: res.data.alert,
        });
        store.dispatch({
          type: ADD_ACCESS_LIST,
          value: res.data.accessList,
        });
      }
    })
    .catch((e) => {
      showAlert(e, "error");
    });
export default updateManifest;
