import React from "react";
import Skeleton from "react-loading-skeleton";
import style from "./style.module.scss";

function ComparativeHeatmapSkeleton() {
  return [1].map(() => (
    <div>
      <div className={style.dataTitle}>
        <Skeleton width={100} height={40} />
      </div>

      {[1].map(() => (
        <div className={style.heatmapContainerWrapper}>
          <div className={style.heatmapContainer}>
            <Skeleton width={140} height={40} />
          </div>
          {[1, 2].map(() => (
            <div className={`${style.container} ${style.data}`}>
              <Skeleton width={430} height={340} />
            </div>
          ))}
        </div>
      ))}
    </div>
  ));
}

export default ComparativeHeatmapSkeleton;
