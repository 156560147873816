import { ADDUSERDATA } from "../constants";

const initialState = null;

export default function userDataReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADDUSERDATA:
      return action.value;
    default:
      return state;
  }
}
