import React from "react";

function IconDineIn({ height = "16", width = "16", color = "#3766E8" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.3335V14.6668H12.6667V10.0002H10V5.3335C10 3.12436 11.7909 1.3335 14 1.3335ZM12.6667 3.02016C12.1133 3.3335 11.3333 4.1135 11.3333 5.3335V8.66683H12.6667V3.02016ZM6 9.26683V14.6668H4.66667V9.26683C3.14517 8.95796 2 7.61283 2 6.00016V2.00016H3.33333V6.66683H4.66667V2.00016H6V6.66683H7.33333V2.00016H8.66667V6.00016C8.66667 7.61283 7.52147 8.95796 6 9.26683Z"
        fill={color}
      />
    </svg>
  );
}

export default IconDineIn;
