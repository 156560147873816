import React from "react";

const IconPlayback = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 6H0V8.66669H16V6Z" fill="#545454" />
      <path d="M16 0.666992H0V3.33368H16V0.666992Z" fill="#545454" />
      <path d="M10.6667 11.333H0V13.9997H10.6667V11.333Z" fill="#545454" />
      <path d="M13.333 11.333V19.333L19.9997 15.333L13.333 11.333Z" fill="#545454" />
    </svg>
  );
};

export default IconPlayback;
