import React from "react";

function IconCreditPayment() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_59265_1868)">
        <rect x="2" y="4" width="18" height="13" fill="#E0E8FC" />
        <mask
          id="mask0_59265_1868"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="22"
          height="21"
        >
          <path d="M21.4766 0H0.524231V20.9524H21.4766V0Z" fill="white" />
          <path
            d="M18.6127 13.955C18.6127 14.52 18.1547 14.978 17.5897 14.978C17.0246 14.978 16.5666 14.52 16.5666 13.955C16.5666 13.3899 17.0246 12.9319 17.5897 12.9319C18.1547 12.9319 18.6127 13.3899 18.6127 13.955Z"
            fill="white"
          />
          <path
            d="M15.7478 13.955C15.7478 14.52 15.2897 14.978 14.7247 14.978C14.1597 14.978 13.7017 14.52 13.7017 13.955C13.7017 13.3899 14.1597 12.9319 14.7247 12.9319C15.2897 12.9319 15.7478 13.3899 15.7478 13.955Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_59265_1868)">
          <mask
            id="mask1_59265_1868"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="22"
            height="21"
          >
            <path d="M0.524231 -0.000230789H21.4766V20.9521H0.524231V-0.000230789Z" fill="white" />
          </mask>
          <g mask="url(#mask1_59265_1868)">
            <path
              d="M19.0213 17.0234H2.97962C2.0756 17.0234 1.34271 16.2906 1.34271 15.3865V5.56511C1.34271 4.66108 2.0756 3.9282 2.97962 3.9282H19.0213C19.9253 3.9282 20.6582 4.66108 20.6582 5.56511V15.3865C20.6582 16.2906 19.9253 17.0234 19.0213 17.0234Z"
              stroke="#3766E8"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.34271 7.20215H20.6582"
              stroke="#3766E8"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.6116 13.954C18.6116 14.519 18.1536 14.9771 17.5886 14.9771C17.0235 14.9771 16.5655 14.519 16.5655 13.954C16.5655 13.389 17.0235 12.9309 17.5886 12.9309C18.1536 12.9309 18.6116 13.389 18.6116 13.954Z"
              fill="#3766E8"
            />
            <path
              d="M15.7475 13.9545C15.7475 14.5195 15.2894 14.9775 14.7244 14.9775C14.1594 14.9775 13.7014 14.5195 13.7014 13.9545C13.7014 13.3895 14.1594 12.9314 14.7244 12.9314C15.2894 12.9314 15.7475 13.3895 15.7475 13.9545Z"
              fill="#3766E8"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_59265_1868">
          <rect width="22" height="22" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconCreditPayment;
