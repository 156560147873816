import React from "react";

function IconJourney() {
  return (
    <svg width="14" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6.5C5.39788 6.5 6.57245 5.54392 6.90549 4.25H10.5C11.5623 4.25 12.25 5.50961 12.25 6.5C12.25 7.49039 11.5623 8.25 10.5 8.25H3.5C1.70508 8.25 0.25 9.70507 0.25 11.5C0.25 13.2949 1.70508 14.75 3.5 14.75H12.0945C12.4275 16.0439 13.6021 17 15 17C16.6569 17 18 15.6569 18 14C18 12.3431 16.6569 11 15 11C13.6021 11 12.4275 11.9561 12.0945 13.25H3.5C2.5335 13.25 1.75 12.4665 1.75 11.5C1.75 10.5335 2.5335 9.75 3.5 9.75H10.5C12.4377 9.75 13.75 8.27103 13.75 6.5C13.75 4.72897 12.4377 2.75 10.5 2.75H6.90549C6.57245 1.45608 5.39788 0.5 4 0.5C2.34315 0.5 1 1.84315 1 3.5C1 5.15685 2.34315 6.5 4 6.5ZM5.5 3.5C5.5 4.32843 4.82843 5 4 5C3.17157 5 2.5 4.32843 2.5 3.5C2.5 2.67157 3.17157 2 4 2C4.82843 2 5.5 2.67157 5.5 3.5ZM16.5 14C16.5 14.8284 15.8284 15.5 15 15.5C14.1716 15.5 13.5 14.8284 13.5 14C13.5 13.1716 14.1716 12.5 15 12.5C15.8284 12.5 16.5 13.1716 16.5 14Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconJourney;
