import React, { lazy } from "react";
// import IssuesComponent from "../Components/IssuesComponent";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { settings } from "../../../Constants/MetaDetails";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";

const ZoneComponent = lazy(() => import("../../Components/ZoneComponent"));

function ZoneContainer() {
  return (
    <PageContainer
      initialPageTitle={PAGE_TITLES.zone}
      meta={settings?.zone?.subtitle}
      withLayout={false}
    >
      <ZoneComponent />
    </PageContainer>
  );
}

export default ZoneContainer;
