import React from "react";

const IconMotionBased = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7748 6C15.3343 6.15653 18.1001 8.47801 18.1531 12.0673C18.2049 15.5461 15.5832 18.1039 12.7692 18.3077C14.6079 16.7333 15.6258 14.6993 15.6282 12.1567C15.6306 9.61404 14.6155 7.5848 12.7748 6Z"
        fill="#545454"
      />
      <path
        d="M16.621 6C19.1805 6.15653 21.9463 8.47801 21.9993 12.0673C22.0511 15.5461 19.4294 18.1039 16.6154 18.3077C18.4541 16.7333 19.472 14.6993 19.4744 12.1567C19.4768 9.61404 18.4617 7.5848 16.621 6Z"
        fill="#545454"
      />
      <path
        d="M8.15383 18.3077C11.5525 18.3077 14.3077 15.5525 14.3077 12.1538C14.3077 8.75517 11.5525 6 8.15383 6C4.75517 6 2 8.75517 2 12.1538C2 15.5525 4.75517 18.3077 8.15383 18.3077Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconMotionBased;
