import PropTypes from "prop-types";
import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";
import StationsSkeleton from "./StationsSkeleton";
import "./style.scss";

function DriveThruLocationSkeleton({ fullPageSkeleton }) {
  return (
    <div className={`drive-panel-main ${fullPageSkeleton ? "m-20" : ""}`}>
      {fullPageSkeleton && <PageTitleSkeleton />}
      <div className="drive-body-wrapper">
        <div className="drive-stats-wrapper pt-3 pb-2  pl-3 pr-2 ">
          <div className="drive-stats-head">
            {" "}
            <Skeleton width={160} height={30} className="mb-2" />
            <Skeleton width={100} height={20} className="mb-2" />
          </div>
          <div className="drive-stats-info row">
            {Array(2)
              .fill()
              .map((ele, index) => (
                <Skeleton width={200} height={90} className="mr-4 mb-2" />
              ))}
          </div>
        </div>
        <StationsSkeleton />
        <div className="drive-chartCont mt-5">
          <Skeleton height={350} />
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default DriveThruLocationSkeleton;
DriveThruLocationSkeleton.propTypes = {
  fullPageSkeleton: PropTypes.bool,
};

DriveThruLocationSkeleton.defaultProps = {
  fullPageSkeleton: false,
};
