import React from "react";

const IconBell = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 7.33317C13 6.00709 12.4732 4.73532 11.5355 3.79764C10.5978 2.85995 9.32604 2.33317 7.99996 2.33317C6.67388 2.33317 5.40211 2.85995 4.46443 3.79764C3.52674 4.73532 2.99996 6.00709 2.99996 7.33317V13.9998H13V7.33317ZM14.6666 14.5557L15 14.9998C15.0464 15.0617 15.0747 15.1354 15.0816 15.2124C15.0886 15.2895 15.0739 15.367 15.0393 15.4362C15.0047 15.5054 14.9515 15.5636 14.8857 15.6043C14.8199 15.645 14.744 15.6665 14.6666 15.6665H1.33329C1.25591 15.6665 1.18006 15.645 1.11424 15.6043C1.04841 15.5636 0.99522 15.5054 0.960615 15.4362C0.926009 15.367 0.911361 15.2895 0.91831 15.2124C0.925259 15.1354 0.953531 15.0617 0.999959 14.9998L1.33329 14.5557V7.33317C1.33329 5.56506 2.03567 3.86937 3.28591 2.61913C4.53616 1.36888 6.23185 0.666504 7.99996 0.666504C9.76807 0.666504 11.4638 1.36888 12.714 2.61913C13.9642 3.86937 14.6666 5.56506 14.6666 7.33317V14.5557ZM5.91663 16.4998H10.0833C10.0833 17.0524 9.8638 17.5823 9.4731 17.973C9.0824 18.3637 8.55249 18.5832 7.99996 18.5832C7.44743 18.5832 6.91752 18.3637 6.52682 17.973C6.13612 17.5823 5.91663 17.0524 5.91663 16.4998Z"
        fill="#343434"
      />
    </svg>
  );
};

export default IconBell;
