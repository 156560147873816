import React from "react";

function IconWobotName() {
  return (
    <svg
      width="140"
      height="32"
      viewBox="0 0 140 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.6125 22.1147H19.7111C19.7111 22.1147 19.1776 22.2496 18.6442 21.3561L5.49176 0.8228C5.49176 0.8228 5.20837 0.35077 4.57492 0.300195C3.94147 0.249621 3.52473 0.789083 3.42471 1.04196L0.207446 6.85804C0.207446 6.85804 -0.259307 7.43122 0.207446 8.30784L13.7933 30.4595C13.7933 30.4595 14.1267 31.2182 14.7768 31.2519C15.4269 31.2856 23.9952 31.2519 23.9952 31.2519C23.9952 31.2519 24.6286 31.3024 25.0787 30.5101C25.5288 29.7178 28.7294 23.9017 28.7294 23.9017C28.7294 23.9017 29.2962 22.1484 27.6125 22.1147Z"
        fill="#3766E8"
      />
      <path
        d="M48.1997 22.1147H40.2982C40.2982 22.1147 39.7648 22.2496 39.2313 21.3561L26.0956 0.8228C26.0956 0.8228 25.8122 0.35077 25.1787 0.300195C24.5453 0.249621 24.1285 0.789083 24.0285 1.04196L20.8113 6.85804C20.8113 6.85804 20.3445 7.43122 20.8113 8.30784L34.3971 30.4595C34.3971 30.4595 34.7305 31.2182 35.3806 31.2519C36.0308 31.2856 44.599 31.2519 44.599 31.2519C44.599 31.2519 45.2325 31.3024 45.6825 30.5101C46.1326 29.7178 49.3332 23.9017 49.3332 23.9017C49.3332 23.9017 49.8833 22.1484 48.1997 22.1147Z"
        fill="#3766E8"
      />
      <path
        d="M43.9989 0.907091L40.8816 6.62202C40.8816 6.62202 40.2482 7.43122 40.6816 8.15612C41.115 8.88102 48.4831 20.8672 48.4831 20.8672C48.4831 20.8672 48.8331 21.6595 49.6333 21.6427C50.4167 21.609 50.6168 21.1538 50.8835 20.6312C51.1502 20.1254 54.1174 14.6802 54.1174 14.6802C54.1174 14.6802 54.5175 14.0565 54.0508 13.3484C53.584 12.6235 46.0993 0.907091 46.0993 0.907091C46.0993 0.907091 45.7659 0.199046 44.9324 0.283337C44.2489 0.367628 43.9989 0.907091 43.9989 0.907091Z"
        fill="#3766E8"
      />
      <path
        d="M98.5923 15.894C94.3916 15.894 90.9909 19.3331 90.9909 23.5814C90.9909 27.8296 94.3916 31.2687 98.5923 31.2687C102.793 31.2687 106.194 27.8296 106.194 23.5814C106.194 19.3331 102.776 15.894 98.5923 15.894ZM98.5923 30.1392C95.0083 30.1392 92.1078 27.2059 92.1078 23.5814C92.1078 19.9569 95.0083 17.0235 98.5923 17.0235C102.176 17.0235 105.077 19.9569 105.077 23.5814C105.077 27.189 102.176 30.1392 98.5923 30.1392Z"
        fill="#3766E8"
      />
      <path
        d="M65.0194 15.894C60.8187 15.894 57.418 19.3331 57.418 23.5814C57.418 27.8296 60.8187 31.2687 65.0194 31.2687C69.2202 31.2687 72.6209 27.8296 72.6209 23.5814C72.6042 19.3331 69.2036 15.894 65.0194 15.894ZM65.0194 30.1392C61.4355 30.1392 58.5349 27.2059 58.5349 23.5814C58.5349 19.9569 61.4355 17.0235 65.0194 17.0235C68.6034 17.0235 71.504 19.9569 71.504 23.5814C71.504 27.189 68.6034 30.1392 65.0194 30.1392Z"
        fill="#3766E8"
      />
      <path
        d="M82.206 15.894C79.5221 15.894 77.1717 17.3101 75.8215 19.4343V11.5109C75.8215 11.1737 75.5547 10.904 75.2213 10.904C74.8879 10.904 74.6212 11.1737 74.6212 11.5109V30.645C74.6212 30.9821 74.8879 31.2519 75.2213 31.2519C75.5547 31.2519 75.8215 30.9821 75.8215 30.645V27.7116C77.1717 29.8358 79.5221 31.2519 82.206 31.2519C86.4067 31.2519 89.8074 27.8128 89.8074 23.5645C89.7907 19.3331 86.3901 15.894 82.206 15.894ZM82.206 30.1392C78.9887 30.1392 76.3382 27.7791 75.8215 24.6772V22.4856C76.3382 19.3837 79.0054 17.0235 82.206 17.0235C85.79 17.0235 88.6905 19.9569 88.6905 23.5814C88.6905 27.189 85.79 30.1392 82.206 30.1392Z"
        fill="#3766E8"
      />
      <path
        d="M135.633 23.5645C135.633 19.3163 132.232 15.8772 128.031 15.8772C123.83 15.8772 120.43 19.3163 120.43 23.5645C120.43 27.8128 123.83 31.2519 128.031 31.2519C130.782 31.2519 133.182 29.7683 134.516 27.5431V30.6955C134.516 30.999 134.766 31.2519 135.066 31.2519C135.366 31.2519 135.616 30.999 135.616 30.6955V24.3063C135.616 24.2557 135.599 24.2051 135.583 24.1546C135.616 23.9691 135.633 23.7668 135.633 23.5645ZM128.031 30.1392C124.447 30.1392 121.547 27.2059 121.547 23.5814C121.547 19.9569 124.447 17.0235 128.031 17.0235C131.615 17.0235 134.516 19.9569 134.516 23.5814C134.516 27.189 131.615 30.1392 128.031 30.1392Z"
        fill="#3766E8"
      />
      <path
        d="M113.478 16.012H111.095V11.2917C111.095 10.9546 110.828 10.6849 110.495 10.6849C110.161 10.6849 109.894 10.9546 109.894 11.2917V16.0289H107.511C107.227 16.0289 106.994 16.2649 106.994 16.5515C106.994 16.8381 107.227 17.0741 107.511 17.0741H109.894V30.6618C109.894 30.999 110.161 31.2687 110.495 31.2687C110.828 31.2687 111.095 30.999 111.095 30.6618V17.0573H113.478C113.762 17.0573 113.995 16.8212 113.995 16.5347C113.995 16.2481 113.762 16.012 113.478 16.012Z"
        fill="#3766E8"
      />
      <path
        d="M139.8 31.2519H138.666C138.65 31.2519 138.633 31.235 138.633 31.2182V16.0963C138.633 16.0795 138.65 16.0626 138.666 16.0626H139.8C139.817 16.0626 139.833 16.0795 139.833 16.0963V31.2182C139.833 31.235 139.817 31.2519 139.8 31.2519Z"
        fill="#3766E8"
      />
      <path
        d="M139.233 13.399C139.657 13.399 140 13.0518 140 12.6235C140 12.1953 139.657 11.8481 139.233 11.8481C138.81 11.8481 138.466 12.1953 138.466 12.6235C138.466 13.0518 138.81 13.399 139.233 13.399Z"
        fill="#3766E8"
      />
      <path
        d="M117.212 31.2687C117.636 31.2687 117.979 30.9215 117.979 30.4932C117.979 30.065 117.636 29.7178 117.212 29.7178C116.789 29.7178 116.446 30.065 116.446 30.4932C116.446 30.9215 116.789 31.2687 117.212 31.2687Z"
        fill="#3766E8"
      />
    </svg>
  );
}

export default IconWobotName;
