import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import IconClose from "../../../Common/Components/IconsComponent/IconClose";
import IconExpandView from "../../../Common/Components/IconsComponent/IconExpandView";
import IconSmartAssistant from "../../../Common/Components/IconsComponent/IconSmartAssistant";
import Tag from "../../../Common/Components/Molecule/Tag/Tag";
import {
  setEmptyMessages,
  setInFullViewMode,
  setIsOpenAssistant,
} from "../../State/assistantAction";
import styles from "../Styles.module.scss";
import SearchOptions from "./SearchOptions";
import ZeroState from "./ZeroState";

function Header({ isShowZeroState, isInFullViewMode }) {
  const dispatch = useDispatch();

  const handleSetInFullViewMode = (state) => {
    dispatch(setInFullViewMode(state));
  };
  const handleCloseAssistant = () => {
    dispatch(setIsOpenAssistant(false));
    setTimeout(() => {
      dispatch(setEmptyMessages());
      handleSetInFullViewMode(false);
    }, 400);
  };

  return (
    <div className={styles["chat-header-wrapper"]}>
      <div className={styles["chat-header-container"]}>
        <div className={styles["chat-header"]}>
          <div className={styles["chat-header-title"]}>
            <IconSmartAssistant />
            <h6 className="fs-16 fw-600 m-0 text-white">InsightAI</h6>
            <Tag color="failure" className={styles["beta-tag"]}>
              BETA
            </Tag>
          </div>
          <div className={styles["chat-toggle-action"]}>
            <button
              type="button"
              className="btn p-0 no-line-height"
              onClick={() => handleSetInFullViewMode(!isInFullViewMode)}
            >
              <IconExpandView />
            </button>
            <button type="button" className="btn p-0 no-line-height" onClick={handleCloseAssistant}>
              <IconClose color="#ffffff" size="10" />
            </button>
          </div>
        </div>
        <ZeroState isShowZeroState={isShowZeroState} />
      </div>
      <SearchOptions />
    </div>
  );
}
Header.propTypes = {
  isShowZeroState: PropTypes.bool.isRequired,
  isInFullViewMode: PropTypes.bool.isRequired,
};
export default Header;
