import {
  SET_EMPTY_MESSAGES,
  SET_IN_FULL_VIEW_MODE,
  SET_IS_OPEN_ASSISTANT,
  SET_MESSAGES,
  SET_SELECTED_OPTION,
} from ".";

const initialState = {
  isAssistantOpen: false,
  messages: null,
  isInFullViewMode: false,
  selectedOptions: {},
};
export default function assistantReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case SET_IS_OPEN_ASSISTANT: {
      return {
        ...state,
        isAssistantOpen: payload,
      };
    }
    case SET_MESSAGES: {
      const messages = state?.messages ? [...state.messages, payload] : [payload];

      return {
        ...state,
        messages,
      };
    }
    case SET_EMPTY_MESSAGES: {
      return {
        ...state,
        messages: [],
        selectedOptions: {},
      };
    }
    case SET_IN_FULL_VIEW_MODE: {
      return {
        ...state,
        isInFullViewMode: payload,
      };
    }
    case SET_SELECTED_OPTION: {
      return {
        ...state,
        selectedOptions: { ...state.selectedOptions, ...payload },
      };
    }

    default:
      return state;
  }
}
