import React, { Suspense, lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";
import LoginSkeleton from "../../../Common/Components/SkeletonComponent/CommonSkeleton/LoginSkeleton";

const EmailVerifyComponent = lazy(() => import("../../Components/EmailVerifyComponent"));

function EmailVerifyComponentContainer() {
  return (
    <Suspense fallback={<LoginSkeleton showTitle={false} />}>
      <PageContainer
        initialPageTitle={PAGE_TITLES.verifyEmail}
        meta="Verify your Email"
        withLayout={false}
      >
        <EmailVerifyComponent />
      </PageContainer>
    </Suspense>
  );
}

export default EmailVerifyComponentContainer;
