const IconKnowledge = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.25 15.0547H2.75C2.15326 15.0547 1.58097 14.8176 1.15901 14.3957C0.737053 13.9737 0.5 13.4014 0.5 12.8047V0.804688C0.5 0.605775 0.579018 0.41501 0.71967 0.274357C0.860322 0.133705 1.05109 0.0546875 1.25 0.0546875H11.75C11.9489 0.0546875 12.1397 0.133705 12.2803 0.274357C12.421 0.41501 12.5 0.605775 12.5 0.804688V9.80469H15.5V12.8047C15.5 13.4014 15.2629 13.9737 14.841 14.3957C14.419 14.8176 13.8467 15.0547 13.25 15.0547ZM12.5 11.3047V12.8047C12.5 13.0036 12.579 13.1944 12.7197 13.335C12.8603 13.4757 13.0511 13.5547 13.25 13.5547C13.4489 13.5547 13.6397 13.4757 13.7803 13.335C13.921 13.1944 14 13.0036 14 12.8047V11.3047H12.5ZM11 13.5547V1.55469H2V12.8047C2 13.0036 2.07902 13.1944 2.21967 13.335C2.36032 13.4757 2.55109 13.5547 2.75 13.5547H11ZM3.5 3.80469H9.5V5.30469H3.5V3.80469ZM3.5 6.80469H9.5V8.30469H3.5V6.80469ZM3.5 9.80469H7.25V11.3047H3.5V9.80469Z"
        fill={props.color}
      />
    </svg>
  );
};

export default IconKnowledge;
