import React from "react";

function IconEvent() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2.90909C2 2.40702 2.22519 2 2.72727 2H7.27273V0H2.72727C1.22104 0 0 1.22104 0 2.72727V7.27273H2V2.90909Z"
        fill="#545454"
      />
      <path
        d="M18 2.90621C18 2.40415 17.7748 1.99712 17.2727 1.99712H12.7273V0H17.2727C18.779 0 20 1.22104 20 2.72727V7.27273H18V2.90621Z"
        fill="#545454"
      />
      <path
        d="M2 17.0909C2 17.593 2.22519 18 2.72727 18H7.27273V20H2.72727C1.22104 20 0 18.779 0 17.2727V12.7273H2V17.0909Z"
        fill="#545454"
      />
      <path
        d="M20 17.2727C20 18.779 18.779 20 17.2727 20H12.7273V18H17.2727C17.7748 18 18 17.593 18 17.0909V12.7273H20V17.2727Z"
        fill="#545454"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.72146 4.70477C9.90026 4.63324 10.0997 4.63324 10.2785 4.70477L15.2744 6.70312C15.2841 6.70694 15.2937 6.71095 15.3032 6.71516C15.3394 6.73114 15.3739 6.74978 15.4065 6.77079C15.5644 6.87261 15.6781 7.03013 15.7255 7.21085C15.7437 7.28005 15.7516 7.3499 15.75 7.4186V13.0011C15.75 13.285 15.5897 13.5446 15.3359 13.6717L10.5 16C10.2963 16.102 9.70695 16.0954 9.5 16L4.68612 13.6823C4.42028 13.5598 4.25 13.2938 4.25 13.0011V7.41857C4.24838 7.34952 4.25635 7.27932 4.27478 7.20979C4.28383 7.17549 4.29526 7.14203 4.30894 7.10964C4.3695 6.96567 4.47074 6.84961 4.59435 6.77025C4.6271 6.7492 4.66176 6.73054 4.69811 6.71456C4.70713 6.7106 4.71622 6.70681 4.72538 6.7032L9.72146 4.70477ZM5.75 8.45988V12.6185L9.22999 14.301V9.68362L5.75 8.45988ZM10.73 14.301L14.25 12.538V8.45708L10.73 9.68503V14.301ZM12.8606 7.35313L9.98098 8.35767L7.13237 7.35595L10 6.2089L12.8606 7.35313Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconEvent;
