import React from "react";

const IconFlag = ({ color = "black" }) => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 10.75V14.5H0.5V0.250001H14.8535C14.9192 0.24989 14.9838 0.26706 15.0408 0.299789C15.0979 0.332518 15.1453 0.379657 15.1783 0.43648C15.2113 0.493303 15.2289 0.557813 15.2291 0.623546C15.2294 0.689278 15.2124 0.753923 15.1798 0.811001L12.5 5.5L15.1798 10.189C15.2124 10.2461 15.2294 10.3107 15.2291 10.3765C15.2289 10.4422 15.2113 10.5067 15.1783 10.5635C15.1453 10.6203 15.0979 10.6675 15.0408 10.7002C14.9838 10.7329 14.9192 10.7501 14.8535 10.75H2ZM2 1.75V9.25H12.9155L10.772 5.5L12.9155 1.75H2Z"
        fill={` ${color} `}
      />
    </svg>
  );
};

export default IconFlag;
