import React from "react";
import Skeleton from "react-loading-skeleton";

import styles from "./Styles.module.scss";

function SettingsIntegrationModuleSkeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>
          <Skeleton width={220} height={30} />
        </h1>
        <p>
          <Skeleton width={680} height={20} />
        </p>
      </div>

      <div className={styles.tabs}>
        <Skeleton height={40} width={130} count={4} />
      </div>

      <div className={styles.cardsContainer}>
        {[1, 2, 3].map((k) => (
          <div className={styles.card} key={k}>
            <div className={styles.cardTop}>
              <div className={styles.iconBox}>
                <Skeleton width={40} height={40} />
              </div>
            </div>
            <div className={styles.cardDetails}>
              <div className={styles.cardTitle}>
                <Skeleton width={150} height={25} />
              </div>
              <div className={styles.subText}>
                <Skeleton width="100%" height={20} />
              </div>
            </div>

            <div className={styles.cardButton}>
              <Skeleton width={115} height={33} />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.help_wrapper}>
        <Skeleton width={380} height={30} />
      </div>
    </div>
  );
}

export default SettingsIntegrationModuleSkeleton;
