export const TOGGLE_NAVBAR = "TOGGLE_NAVBAR";
export const ADDUSERDATA = "ADDUSERDATA";
export const ADDALERTDATA = "ADDALERTDATA";
export const ADD_AUTH_TOKEN = "ADD_AUTH_TOKEN";

export const ADDDEVICEINFO = "ADDDEVICEINFO";
export const UPDATE_DETAILS = "UPDATE_DETAILS";

export const CHANGE_ACTIVE_SIDEBAR = "CHANGE_ACTIVE_SIDEBAR";
export const TOGGLE_LOADER = "TOGGLE_LOADER";

export const LOGOUT = "LOGOUT";

export const ADD_ACCESS_LIST = "ADD_ACCESS_LIST";

export const TICKETING_FOOTER_DISPLAY = "DISPLAY";
export const ADD_REDIRECT_URL = "ADD_REDIRECT_URL";
export const CLEAR_REDIRECT_URL_AFTER_LOGIN = "CLEAR_REDIRECT_URL_AFTER_LOGIN";
