import { OperationalDashboard, initializeConfig } from "operational-dashboard";
import "operational-dashboard/dist/index.css";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  API_BASE_URL,
  APP_SOCKET_BASE_URL,
  DRIVE_LIST,
  DRIVE_LOCATION_LINK,
  HOME,
} from "../../../Constants";
import { SHOWREVIEWOPTION } from "../../../Constants/AccessList";
import getQueryVariable from "../../../Services/getQueryVariable";
import Roles from "../../../Services/Roles";
import { logoutAction } from "../../../Store/actions";

class OperationalDashboardContainer extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {
      authToken,
      userData: {
        user: { id: userId },
        manifest: { id: companyId },
      },
      deviceInfo: { deviceId, name: device, deviceType, timeZone: timezone },
    } = this.props;

    initializeConfig({
      baseApiUrl: API_BASE_URL.replace("/v1", ""),
      layoutApiUrl: "/v1/drivethru/location/layout",
      shiftApiUrl: "/v1/operational/drivethru/station/shift/insight",
      locationFilterApi: "/v1/drivethru/filter?moduleType=thruputLive",
      cameraStatusApi: "/v1/camera/offline/status",
      leaderboardApi: "/v1/operational/drivethru/leaderboard",
      enableSocket: true,
      showJourneyDataAuth: Roles.authenticateViewAndAction(SHOWREVIEWOPTION),
      socketServerUrl: APP_SOCKET_BASE_URL,
      userId,
      deviceId,
      device,
      deviceType,
      timezone,
      companyId,
      authToken,
      isExternalDashboard: true,
    });
  }

  onAuthFailed = (history) => {
    // const { logout } = this.props;
    // logout();
    history.replace(HOME);
  };

  render() {
    const {
      history,
      match,
      userData: { manifest: { company: { logo: companyLogoUrl } } = {} } = {},
    } = this.props;
    const locationName = getQueryVariable("locationName");
    const { locationId } = match.params || {};

    return (
      <>
        <Helmet>
          {/* <title>ThruPut Live | Wobot AI</title> */}
          <title>ThruPut Live | Wobot AI</title>
          <meta name="description" content="View location wise operations" />
        </Helmet>
        {locationId ? (
          <OperationalDashboard
            companyLogoUrl={companyLogoUrl}
            locationId={locationId}
            locationName={locationName}
            goBack={(ulrLocationId, fromList) => {
              if (ulrLocationId) {
                let replaceUrl = `${DRIVE_LOCATION_LINK}/${ulrLocationId}`;
                if (fromList) {
                  replaceUrl = `${DRIVE_LIST}`;
                }
                history.replace(replaceUrl);
              } else {
                history.goBack();
              }
            }}
            onAuthFailed={() => this.onAuthFailed(history)}
          />
        ) : (
          <p>Please select a location to view operational dashboard</p>
        )}
      </>
    );
  }
}

OperationalDashboardContainer.propTypes = {
  deviceInfo: PropTypes.shape({
    deviceId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    deviceType: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
  }).isRequired,
  userData: PropTypes.shape({
    token: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    manifest: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: {
      locationId: PropTypes.string.isRequired,
    },
  }).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ location, userData, deviceInfo, authToken }) => ({
  location,
  userData,
  deviceInfo,
  authToken,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OperationalDashboardContainer));
