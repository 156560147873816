import React from "react";

function IconCameraOffline() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_60810_32496)">
        <path
          d="M13.0569 13.9998H1.99978C1.63159 13.9998 1.33312 13.7013 1.33312 13.3331V3.99978C1.33312 3.63159 1.63159 3.33311 1.99978 3.33311H2.3903L0.928711 1.87152L1.87152 0.928711L15.0709 14.128L14.128 15.0708L13.0569 13.9998ZM3.72364 4.66644H2.66645V12.6664H11.7236L10.2699 11.2127C9.64555 11.7056 8.85701 11.9998 7.99981 11.9998C5.97474 11.9998 4.33312 10.3582 4.33312 8.33311C4.33312 7.47591 4.62728 6.68738 5.12018 6.06299L3.72364 4.66644ZM6.07336 7.01618C5.81663 7.39098 5.66645 7.84451 5.66645 8.33311C5.66645 9.62178 6.71114 10.6664 7.99981 10.6664C8.48841 10.6664 8.94195 10.5162 9.31674 10.2595L6.07336 7.01618ZM14.6665 11.857L13.3331 10.5236V4.66644H10.7808L9.44748 3.33311H6.55206L6.34732 3.53786L5.40452 2.59504L5.99978 1.99978H9.99981L11.3331 3.33311H13.9998C14.3679 3.33311 14.6665 3.63159 14.6665 3.99978V11.857ZM7.50854 4.69907C7.66921 4.67756 7.83321 4.66644 7.99981 4.66644C10.0248 4.66644 11.6665 6.30807 11.6665 8.33311C11.6665 8.49971 11.6553 8.66364 11.6338 8.82438L10.0752 7.26578C9.85261 6.83378 9.49914 6.48027 9.06714 6.25766L7.50854 4.69907Z"
          fill="#FF7E17"
        />
      </g>
      <defs>
        <clipPath id="clip0_60810_32496">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconCameraOffline;
