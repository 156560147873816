import React from "react";

function IconSend({ color = "black", className = "" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        className={className}
        d="M0.5075 13.75L16.25 7L0.5075 0.25L0.5 5.5L11.75 7L0.5 8.5L0.5075 13.75Z"
        fill={color}
      />
    </svg>
  );
}

export default IconSend;
