import React from "react";
import BlocksComponent from "../../../../Common/Components/Molecule/Block";
import styles from "../style.module.scss";
import IconIn from "../Assets/IconIn";
import IconOut from "../Assets/IconOut";
import { getOccupancyClass } from "../Utils";
import IconNoEvents from "../Assets/IconNoEvents";

export default function ZoneDataComponent({ zoneData }) {
  return (
    <div className={styles.zoneDataContainer}>
      {" "}
      <h5 className={styles.zoneDataTitle}>Zone wise counts</h5>
      {zoneData && zoneData.length ? (
        <div className={styles.zoneDataWapper}>
          {zoneData?.map((zone) => {
            const currentOccupacy = zone?.entry - zone?.exit;

            return (
              <BlocksComponent className={`${styles.zoneData}  mb-0 `}>
                <>
                  <div className={styles.zoneTotalHeader}>
                    <p className={styles.title}>{zone.camera.camera}</p>{" "}
                    {/* Hiding this for now, We'll later handle this better via Location level Occupancy Limit. */}
                    {/* <p className={styles.max}>
                      Max Allowed: <span>{zone.optimalRange || 0}</span>
                    </p> */}
                  </div>

                  <div className={` ${getOccupancyClass(currentOccupacy, zone.optimalRange)} `}>
                    <div className={styles.currentOccupancyWrapper}>
                      <p className={styles.occupancyTitle}>CURRENT OCCUPANCY</p>
                      <p className={styles.occupancyValue}>{currentOccupacy}</p>
                    </div>
                  </div>
                  <div className={styles.zoneInOutDataWrapper}>
                    <div className={styles.inOutData}>
                      <p>
                        {" "}
                        <IconIn />
                        TOTAL IN
                      </p>
                      <p>{zone.entry}</p>
                    </div>
                    <div className={styles.inOutData}>
                      <p>
                        <IconOut />
                        TOTAL OUT
                      </p>
                      <p>{zone.exit}</p>
                    </div>
                  </div>
                </>
              </BlocksComponent>
            );
          })}
        </div>
      ) : (
        <div className={styles.zoneZeroState}>
          <IconNoEvents />
          <p> No events detected</p>
        </div>
      )}
    </div>
  );
}
