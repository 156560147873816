import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";

function TaskTypeEventSkeleton() {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <PageTitleSkeleton />

        <div className="list-inline-item">
          <Skeleton width={800} height={30} />
        </div>

        <div className="panel-body mt-60">
          <div className="skeleton-grid row mb-4">
            {Array(4)
              .fill()
              .map((index) => (
                <div className="mb-2 col-md-4" key={index}>
                  <Skeleton width="100%" height={300} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskTypeEventSkeleton;
