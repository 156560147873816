import React from "react";
import * as ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { tagManagerArgs } from "./Services/gmtService";
import { persistor, store } from "./Store";

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);
