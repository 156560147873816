import React from "react";

function IconVideoPlay() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM13.5 21.8689L22.5 16.8384C23.1667 16.4658 23.1667 15.5342 22.5 15.1616L13.5 10.1311C12.8333 9.75852 12 10.2243 12 10.9696V21.0304C12 21.7757 12.8333 22.2415 13.5 21.8689Z"
        fill="white"
      />
    </svg>
  );
}

export default IconVideoPlay;
