import { bool, number, string } from "prop-types";
import React from "react";

function IconInformation({ color, size, filled }) {
  if (filled) {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_44738_260737)">
          <path
            d="M8.00065 1.33325C4.32065 1.33325 1.33398 4.31992 1.33398 7.99992C1.33398 11.6799 4.32065 14.6666 8.00065 14.6666C11.6807 14.6666 14.6673 11.6799 14.6673 7.99992C14.6673 4.31992 11.6807 1.33325 8.00065 1.33325ZM8.66732 11.3333H7.33398V7.33325H8.66732V11.3333ZM8.66732 5.99992H7.33398V4.66659H8.66732V5.99992Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_44738_260737">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.41663 3.08317H6.58329V4.24984H5.41663V3.08317ZM5.41663 5.4165H6.58329V8.9165H5.41663V5.4165ZM5.99996 0.166504C2.77996 0.166504 0.166626 2.77984 0.166626 5.99984C0.166626 9.21984 2.77996 11.8332 5.99996 11.8332C9.21996 11.8332 11.8333 9.21984 11.8333 5.99984C11.8333 2.77984 9.21996 0.166504 5.99996 0.166504ZM5.99996 10.6665C3.42746 10.6665 1.33329 8.57234 1.33329 5.99984C1.33329 3.42734 3.42746 1.33317 5.99996 1.33317C8.57246 1.33317 10.6666 3.42734 10.6666 5.99984C10.6666 8.57234 8.57246 10.6665 5.99996 10.6665Z"
        fill={color}
      />
    </svg>
  );
}

IconInformation.propTypes = {
  color: string,
  size: number,
  filled: bool,
};

IconInformation.defaultProps = {
  color: "#545454",
  size: 12,
  filled: false,
};

export default IconInformation;
