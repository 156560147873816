import React from "react";

export default function IconIn() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0678 0.320312H6.64244C6.25307 0.320312 5.87964 0.474989 5.60432 0.750315C5.32899 1.02564 5.17432 1.39906 5.17432 1.78843V3.46629C5.17432 3.63316 5.24061 3.7932 5.3586 3.91119C5.4766 4.02919 5.63664 4.09548 5.80351 4.09548C5.97038 4.09548 6.13042 4.02919 6.24842 3.91119C6.36642 3.7932 6.43271 3.63316 6.43271 3.46629V1.78843C6.43271 1.73281 6.4548 1.67946 6.49413 1.64013C6.53347 1.6008 6.58681 1.5787 6.64244 1.5787H15.0367C15.0914 1.58023 15.1433 1.60297 15.1816 1.64211C15.2198 1.68126 15.2412 1.73373 15.2414 1.78843V15.2113C15.2414 15.2389 15.2359 15.2662 15.2252 15.2917C15.2145 15.3172 15.199 15.3403 15.1794 15.3597C15.16 15.3792 15.137 15.3947 15.1117 15.4052C15.0863 15.4157 15.0591 15.4211 15.0317 15.421H6.64244C6.58681 15.421 6.53347 15.3989 6.49413 15.3596C6.4548 15.3202 6.43271 15.2669 6.43271 15.2113V13.5334C6.43271 13.3665 6.36642 13.2065 6.24842 13.0885C6.13042 12.9705 5.97038 12.9042 5.80351 12.9042C5.63664 12.9042 5.4766 12.9705 5.3586 13.0885C5.24061 13.2065 5.17432 13.3665 5.17432 13.5334V15.2113C5.17432 15.6006 5.32899 15.974 5.60432 16.2494C5.87964 16.5247 6.25307 16.6794 6.64244 16.6794H15.0317C15.421 16.6792 15.7943 16.5244 16.0696 16.2491C16.3448 15.9738 16.4996 15.6006 16.4998 15.2113V1.78843C16.4997 1.4053 16.3499 1.03736 16.0824 0.763096C15.8149 0.48883 15.4508 0.32994 15.0678 0.320312Z"
        fill="#7E7E7E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 10.5714C7.81656 10.6299 7.7702 10.6992 7.73856 10.7756C7.70693 10.852 7.69065 10.9338 7.69065 11.0165C7.69065 11.0991 7.70693 11.181 7.73856 11.2573C7.7702 11.3337 7.81656 11.4031 7.875 11.4615C7.93345 11.52 8.00283 11.5663 8.07919 11.598C8.15555 11.6296 8.2374 11.6459 8.32005 11.6459C8.4027 11.6459 8.48455 11.6296 8.56091 11.598C8.63727 11.5663 8.70666 11.52 8.7651 11.4615L11.2819 8.94474C11.3403 8.88631 11.3867 8.81693 11.4184 8.74057C11.45 8.6642 11.4663 8.58235 11.4663 8.49969C11.4663 8.41703 11.45 8.33518 11.4184 8.25882C11.3867 8.18245 11.3403 8.11307 11.2819 8.05464L8.7651 5.53786C8.64707 5.41983 8.48698 5.35352 8.32005 5.35352C8.15312 5.35352 7.99304 5.41983 7.875 5.53786C7.75697 5.6559 7.69065 5.81599 7.69065 5.98291C7.69065 6.14984 7.75697 6.30993 7.875 6.42796L9.31795 7.87092H1.12919C0.962322 7.87092 0.802284 7.93721 0.684287 8.0552C0.56629 8.1732 0.5 8.33324 0.5 8.50011C0.5 8.66698 0.56629 8.82702 0.684287 8.94502C0.802284 9.06302 0.962322 9.1293 1.12919 9.1293H9.31795L7.875 10.5714Z"
        fill="#7E7E7E"
      />
    </svg>
  );
}
