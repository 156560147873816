import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

const LocationContext = createContext();

export default function LocationContextProvider({ children }) {
  const [currentTabDataCount, setDataCount] = useState({});
  const setCurrentTabDataCount = useCallback(
    (count) => {
      // {pageName:count}
      setDataCount(() => count);
    },
    [setDataCount]
  );

  const values = useMemo(
    () => ({
      currentTabDataCount,
      setCurrentTabDataCount,
    }),
    [currentTabDataCount, setCurrentTabDataCount]
  );

  return <LocationContext.Provider value={values}>{children}</LocationContext.Provider>;
}

LocationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export function useLocationContext() {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error("useLocationContext must be used within a LocationContextProvider");
  }
  return context;
}
