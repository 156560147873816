import React from "react";
import Skeleton from "react-loading-skeleton";
import FormCard from "../Common/FormCard";
import styles from "./add-device.module.scss";

export default function AddDeviceSkelton() {
  return (
    <div className={styles.addDeviceForm}>
      <section className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.formHeading}>
            <Skeleton width="200px" height="28px" className="mb-3" />
            <Skeleton width="600px" height="14px" className="mb-6" />
          </div>
          <div className={styles.formStepsContainer}>
            <div className={styles.formSteps}>
              <Skeleton height="150px" width="750px" />
            </div>
          </div>
          <FormCard
            title={<Skeleton height="16px" width="200px" />}
            description={<Skeleton width="600px" />}
          >
            <Skeleton height="160px" width="100%" />
          </FormCard>
        </div>
      </section>
    </div>
  );
}
