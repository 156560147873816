import { useCallback, useEffect, useRef } from "react";
import { usePrevious } from "./usePrevious";
import useSocket from "./useSocket";
import { store } from "../../../Store";

const RoomType = {
  LOCATION: "location",
  COMPANY: "company",
};

export default function useCompanyLocationRoomSocket({ selectedLocationId }) {
  const companyId = store?.getState()?.userData?.manifest?.id;
  const socket = useSocket();
  const previousLocationId = usePrevious(selectedLocationId);
  const previousLocationIdRef = useRef();
  previousLocationIdRef.current = previousLocationId;

  const subscribeRoom = useCallback(
    (options) => {
      if (!socket) {
        console.log("socket not found while joining", socket);
        return;
      }

      socket.emit("subscribeToRoom", options, (err) => {
        if (err) {
          console.log("err while joining", options, err);
          return;
        }
        console.log("joined room", options);
        return;
      });
    },
    [socket]
  );

  const unsubscribeRoom = useCallback(
    (options) => {
      if (!socket) {
        return;
      }

      socket.emit("unsubscribeFromRoom", options, (err) => {
        if (!err) {
          console.log("left room", options);
          return;
        }
        console.log(err);
      });
    },
    [socket]
  );

  const subscribeLocationRoom = useCallback(() => {
    subscribeRoom({
      room: selectedLocationId,
      roomType: RoomType.LOCATION,
    });

    console.log("called join ", selectedLocationId);
  }, [selectedLocationId, subscribeRoom]);

  const unsubscribeLocationRoom = useCallback(() => {
    unsubscribeRoom({ room: selectedLocationId, roomType: RoomType.LOCATION });
    console.log("called left room location ", selectedLocationId);
  }, [selectedLocationId, unsubscribeRoom]);

  const subscribeCompanyRoom = useCallback(() => {
    if (!companyId) {
      return;
    }

    subscribeRoom({
      room: companyId,
      roomType: RoomType.COMPANY,
    });
  }, [subscribeRoom]);

  const unsubscribeCompanyRoom = useCallback(() => {
    if (!companyId) {
      return;
    }

    unsubscribeRoom({
      room: companyId,
      roomType: RoomType.COMPANY,
    });
  }, [unsubscribeRoom]);

  useEffect(() => {
    const location = previousLocationIdRef.current;

    if (!location) {
      return;
    }

    return () => {
      unsubscribeRoom({
        room: location,
        roomType: RoomType.LOCATION,
      });
    };
  }, [unsubscribeRoom]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    subscribeCompanyRoom();
    subscribeLocationRoom();

    return () => {
      unsubscribeLocationRoom();
      unsubscribeCompanyRoom();
    };
  }, [
    socket,
    subscribeLocationRoom,
    subscribeCompanyRoom,
    unsubscribeLocationRoom,
    unsubscribeCompanyRoom,
  ]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    const joinRooms = () => {
      subscribeCompanyRoom();
      subscribeLocationRoom();
    };

    const leaveRooms = () => {
      unsubscribeLocationRoom();
      unsubscribeCompanyRoom();
    };

    socket.on("connect", joinRooms);
    socket.on("disconnect", leaveRooms);

    return () => {
      socket.off("connect", joinRooms);
      socket.off("disconnect", leaveRooms);
    };
  }, [
    socket,
    subscribeLocationRoom,
    subscribeCompanyRoom,
    unsubscribeLocationRoom,
    unsubscribeCompanyRoom,
  ]);

  return {
    subscribeLocationRoom,
    subscribeCompanyRoom,
  };
}
