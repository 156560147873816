import { useCallback, useState } from "react";
import { showAlert } from "../../Services/showAlert";

function useRequest({ request, initialData, config }) {
  const {
    isLoading = false,
    reportSuccess,
    successMessage,
    reportError,
    errorMessage,
    onSuccess,
    onError,
    finally: finallyCb,
  } = config || {};

  const [loading, setLoading] = useState(isLoading);
  const [data, setData] = useState(initialData);
  const [hasError, setHasError] = useState(false);

  const makeRequest = useCallback(
    async (...args) => {
      try {
        setLoading(true);

        const response = await request(...args);

        setData(response.data);
        setHasError(false);

        if (reportSuccess) {
          showAlert(successMessage || response.message);
        }

        if (typeof onSuccess === "function") {
          onSuccess(response);
        }
      } catch (ex) {
        setHasError(true);
        setData(ex);

        if (reportError) {
          showAlert(
            errorMessage || ex.message || typeof ex === "string" ? ex : "Something went wrong",
            "error"
          );
        }

        if (typeof onError === "function") {
          onError(ex);
        }
      } finally {
        setLoading(false);

        if (typeof finallyCb === "function") {
          finallyCb();
        }
      }
    },
    [request]
  );

  const updateData = useCallback((updatedData) => {
    setData(updatedData);
  }, []);

  return [
    {
      loading,
      data,
      hasError,
      updateData,
    },
    makeRequest,
  ];
}

export default useRequest;
