import React, { Suspense, lazy } from "react";
import AnalyticsSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/AnalyticsSkeleton";
import PageContainer from "../../Common/Containers/_layouts/PageContainer";
import { checkWobotDomain } from "../../Services/checkWobotDomain";

const WalkinComponent = lazy(() => import("../Components/WalkinComponent"));

function Walkin() {
  return (
    <PageContainer initialPageTitle={checkWobotDomain("Walk-in")}>
      <Suspense fallback={<AnalyticsSkeleton key="loader" />}>
        <WalkinComponent />
      </Suspense>
    </PageContainer>
  );
}

export default Walkin;
