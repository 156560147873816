import React from "react";

function IconUploadFile({ className = "" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        className={className}
        d="M9.88596 5.17144L6.1147 8.94266C5.85434 9.20299 5.85434 9.62512 6.1147 9.88546C6.37504 10.1459 6.79716 10.1459 7.0575 9.88546L10.8288 6.11424C11.6098 5.3332 11.6098 4.06687 10.8288 3.28582C10.0477 2.50477 8.78136 2.50477 8.0003 3.28582L4.22908 7.05706C2.92733 8.35879 2.92733 10.4693 4.22908 11.7711C5.53082 13.0729 7.64136 13.0729 8.9431 11.7711L12.7144 7.99986L13.6572 8.94266L9.88596 12.7139C8.0635 14.5363 5.10872 14.5363 3.28627 12.7139C1.46382 10.8915 1.46382 7.93672 3.28627 6.11424L7.0575 2.34301C8.35923 1.04126 10.4698 1.04126 11.7716 2.34301C13.0733 3.64476 13.0733 5.7553 11.7716 7.05706L8.0003 10.8283C7.2193 11.6093 5.95294 11.6093 5.17189 10.8283C4.39084 10.0473 4.39084 8.78092 5.17189 7.99986L8.9431 4.22863L9.88596 5.17144Z"
        fill="#7E7E7E"
      />
    </svg>
  );
}

export default IconUploadFile;
