import React from "react";
import Skeleton from "react-loading-skeleton";

function CommonTaskTypeSkeleton() {
  return (
    <div className="panel-body">
      <div className="skeleton-grid row">
        {Array(3)
          .fill()
          .map((e, index) => (
            <div className="mb-3 col-md-4" key={index}>
              <Skeleton width="100%" height={250} />
            </div>
          ))}
        {Array(2)
          .fill()
          .map((e, index) => (
            <div className="mb-3 col-md-6" key={index}>
              <Skeleton width="100%" height={350} />
            </div>
          ))}
        {Array(1)
          .fill()
          .map((e, index) => (
            <div className="mb-3 col-md-7" key={index}>
              <Skeleton width="100%" height={350} />
            </div>
          ))}
        {Array(1)
          .fill()
          .map((e, index) => (
            <div className="mb-2 col-md-5" key={index}>
              <Skeleton width="100%" height={350} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default CommonTaskTypeSkeleton;
