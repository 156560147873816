// MyComponent.js
import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./Styles.module.scss";

function SettingsAdvanceSettingsSkeletons() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>
          <Skeleton width={220} height={30} />
        </h1>
        <p>
          <Skeleton width={400} height={20} />
        </p>
      </div>

      <div className={styles.formContainer}>
        <div className={styles.formRow}>
          <div className={styles.formColumn}>
            <div className={styles.formLabel}>
              <Skeleton width={200} height={20} />
            </div>
            <div className={styles.formInput}>
              <Skeleton width="100%" height={35} />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formLabel}>
              <Skeleton width={200} height={20} />
            </div>
            <div className={styles.formInput}>
              <Skeleton width="100%" height={35} />
            </div>
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formColumn}>
            <div className={styles.formLabel}>
              <Skeleton width={200} height={20} />
            </div>
            <div className={styles.formInput}>
              <Skeleton width={300} height={30} count={2} className="mr-3" />
            </div>
          </div>
        </div>
        <div className={`${styles.formRow} d-flex flex-row  mt-60`}>
          <Skeleton width={135} height={33} />
        </div>
      </div>
    </div>
  );
}

export default SettingsAdvanceSettingsSkeletons;
