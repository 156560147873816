import React, { lazy } from "react";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";
import { PAGE_TITLES } from "../../../Services/checkWobotDomain";

const TatComponent = lazy(() => import("../../Components/TatComponent"));

function TatComponentContainer() {
  return (
    <PageContainer initialPageTitle={PAGE_TITLES.tat} withLayout={false}>
      <TatComponent />
    </PageContainer>
  );
}

export default TatComponentContainer;
