import React from "react";

function IconToGo({ height = "16", width = "16", color = "#3766E8" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66996 5.33317V3.99983C4.66996 2.15889 6.16235 0.666504 8.00331 0.666504C9.84424 0.666504 11.3366 2.15889 11.3366 3.99983V5.33317H13.3366C13.7048 5.33317 14.0033 5.63165 14.0033 5.99983V13.9999C14.0033 14.368 13.7048 14.6665 13.3366 14.6665H2.66996C2.30178 14.6665 2.0033 14.368 2.0033 13.9999V5.99983C2.0033 5.63165 2.30178 5.33317 2.66996 5.33317H4.66996ZM4.66996 6.6665H3.33663V13.3332H12.67V6.6665H11.3366V7.99986H10.0033V6.6665H6.0033V7.99986H4.66996V6.6665ZM6.0033 5.33317H10.0033V3.99983C10.0033 2.89527 9.10784 1.99983 8.00331 1.99983C6.89871 1.99983 6.0033 2.89527 6.0033 3.99983V5.33317Z"
        fill={color}
      />
    </svg>
  );
}

export default IconToGo;
