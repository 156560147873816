import React from "react";
import Skeleton from "react-loading-skeleton";
import BlocksComponent from "../../../Molecule/Block/";
import BlockHeader from "../../../Molecule/Block/BlockHeader";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";
const SettingsSkeleton = () => {
  return (
    <div className="main-body-wrapper">
      <PageTitleSkeleton className={"mt-4"} />
      <div className="row ">
        {/*First Block*/}
        <div className={"col-xl-4 col-lg-6 col-md-6"}>
          <BlocksComponent>
            <div className={"element-section mb-0 panel-fh"}>
              <div className={"element-content-wrapper"}>
                <div className={"d-flex align-self-center"}>
                  {/*<div className={"element-img-block mr-3"}>*/}
                  {/*  <Skeleton />*/}
                  {/*</div>*/}
                  <div className={"element-content-block"}>
                    <h6 className={"text-primary mb-1"}>
                      <Skeleton height={20} width={100} />
                    </h6>
                    <p className={"mb-0 fw-500"}>
                      <Skeleton />
                    </p>
                  </div>
                </div>
              </div>
              <tbody className={"data-table-list"}>
                <Skeleton count={4} width={300} style={{ marginBottom: "15px" }} />
              </tbody>
            </div>
          </BlocksComponent>
        </div>

        {/*Second Block*/}
        <div className={"col-xl-4 col-lg-6 col-md-6"}>
          <BlocksComponent>
            <div className={"element-section mb-0 panel-fh"}>
              <div className={"element-content-wrapper"}>
                <div className={"d-flex align-self-center"}>
                  {/*<div className={"element-img-block mr-3"}>*/}
                  {/*  <Skeleton />*/}
                  {/*</div>*/}
                  <div className={"element-content-block"}>
                    <h6 className={"text-primary mb-1"}>
                      <Skeleton height={20} width={100} />
                    </h6>
                    <p className={"mb-0 fw-500"}>
                      <Skeleton />
                    </p>
                  </div>
                </div>
              </div>
              <tbody className={"data-table-list"}>
                <Skeleton count={4} width={300} style={{ marginBottom: "15px" }} />
              </tbody>
            </div>
          </BlocksComponent>
        </div>
        <div className={"col-xl-4 col-lg-6 col-md-6"}>
          <BlocksComponent>
            <div className="element-section mb-0 h-100">
              <BlockHeader>
                <h6 className="element-title-h6">
                  <Skeleton width={200} height={20} />
                </h6>
                <Skeleton width={100} height={20} />
              </BlockHeader>
              <div className="alert-message-wrapper">
                <div className="recorder-alert-message">
                  <span>
                    <Skeleton width={200} />
                  </span>
                  <span className="triggered-count">
                    <Skeleton width={200} height={50} />
                  </span>
                  <span>
                    <Skeleton width={200} />
                  </span>
                </div>
                <div className="recorder-alert-sub-message">
                  <span>
                    <Skeleton width={100} />
                  </span>
                  <span className="message-text fw-500 text-primary">
                    <Skeleton width={150} />
                  </span>
                  <div className="d-flex">
                    <span>
                      <Skeleton width={100} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </BlocksComponent>
        </div>
      </div>
    </div>
  );
};

export default SettingsSkeleton;
