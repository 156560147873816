import { useCallback, useEffect } from "react";
import useSocket from "../Utils/socket";
import Events, { UPDATE_TYPES } from "../Utils/events";

function useCompanyLocationSocket({ handleDataReducer }) {
  const socket = useSocket();

  const updateDataHandler = useCallback((update) => {
    handleDataReducer(update);
  }, []);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const occupancyCountEventHandler = ({ data }) => {
      updateDataHandler({ type: UPDATE_TYPES.OCCUPANCY_COUNT, data });
    };

    socket.on("person:occupancyCount", occupancyCountEventHandler);

    return () => {
      socket.off("person:occupancyCount", occupancyCountEventHandler);
    };
  }, [socket, updateDataHandler]);

  useEffect(() => {
    const cancelStationTimerHandler = ({ stationId }) => {
      updateDataHandler({
        type: UPDATE_TYPES.CANCEL_STATION_TIMER,
        data: { stationId },
      });
    };

    // Events.registerEvent(Events.type.CANCEL_STATION_TIMER, cancelStationTimerHandler);

    return () => {
      Events.unregisterEvent(Events.type.CANCEL_STATION_TIMER, cancelStationTimerHandler);
    };
  }, [updateDataHandler]);

  return socket;
}

export default useCompanyLocationSocket;
