import React from "react";

const IconGroup = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6364 6.09126C15.1818 6.09126 16.3636 4.90945 16.3636 3.36399C16.3636 1.81854 15.1818 0.636719 13.6364 0.636719C12.0909 0.636719 10.9091 1.81854 10.9091 3.36399C10.9091 4.90945 12.0909 6.09126 13.6364 6.09126ZM6.36364 6.09126C7.90909 6.09126 9.09091 4.90945 9.09091 3.36399C9.09091 1.81854 7.90909 0.636719 6.36364 0.636719C4.81818 0.636719 3.63636 1.81854 3.63636 3.36399C3.63636 4.90945 4.81818 6.09126 6.36364 6.09126ZM6.36364 7.90945C4.27273 7.90945 0 9.00036 0 11.0913V13.364H12.7273V11.0913C12.7273 9.00036 8.45455 7.90945 6.36364 7.90945ZM13.6364 7.90945C13.3636 7.90945 13.0909 7.90945 12.7273 8.00036C13.8182 8.72763 14.5455 9.81854 14.5455 11.0913V13.364H20V11.0913C20 9.00036 15.7273 7.90945 13.6364 7.90945Z"
        fill="white"
      />
    </svg>
  );
};

export default IconGroup;
