import React from "react";

const IconBulbColor = () => {
  return (
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.74999 20.7503C4.74999 21.292 5.18332 21.8337 5.83332 21.8337H10.1667C10.8167 21.8337 11.25 21.292 11.25 20.7503V19.667H4.74999V20.7503ZM7.99999 0.166992C3.77499 0.166992 0.416656 3.52533 0.416656 7.75033C0.416656 10.3503 1.71666 12.6253 3.66666 13.9253V16.417C3.66666 16.9587 4.09999 17.5003 4.74999 17.5003H11.25C11.9 17.5003 12.3333 16.9587 12.3333 16.417V13.9253C14.2833 12.517 15.5833 10.242 15.5833 7.75033C15.5833 3.52533 12.225 0.166992 7.99999 0.166992Z"
        fill="#FFC107"
      />
    </svg>
  );
};

export default IconBulbColor;
