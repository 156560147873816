import React from "react";
import Skeleton from "react-loading-skeleton";
import BlocksComponent from "../../../Molecule/Block";

const journeyDetailsSkeleton = () => {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <p className="mxw-300">
          <Skeleton count={1} />
        </p>
        <h4>
          <Skeleton width={200} style={{ marginBottom: "15px" }} />
        </h4>
        <div className="panel-body row mr-3">
          <div className="col-xl-4 col-lg-6 col-md-6">
            <BlocksComponent>
              <div className="element-section mb-0 panel-fh">
                <div className="element-content-wrapper mb-3">
                  <div className="d-flex align-self-center">
                    <div className="element-content-block">
                      <h6 className="text-primary mb-1">
                        <Skeleton height={20} width={100} />
                      </h6>
                    </div>
                  </div>
                </div>
                <table>
                  <tbody className="data-table-list">
                    {Array(4)
                      .fill(null)
                      .map((_, index) => (
                        <tr key={index}>
                          <Skeleton
                            count={2}
                            width={150}
                            style={{ marginRight: "15px", marginBottom: "12px" }}
                          />
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </BlocksComponent>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6">
            {" "}
            <BlocksComponent>
              <div className="element-section mb-0 panel-fh text-center">
                <div className="mb-1">
                  <h6 className="mb-0">
                    <Skeleton width={200} />
                  </h6>
                </div>
                <div className="elementData_wrapper">
                  <h2>
                    <Skeleton width={100} height={50} />
                  </h2>
                  <div className="elementBottom_block">
                    <h6>
                      <Skeleton width={150} style={{ marginTop: "40px" }} />
                    </h6>
                  </div>
                </div>
              </div>
            </BlocksComponent>
          </div>
        </div>

        <BlocksComponent>
          <Skeleton width={"100%"} height={500} className="mb-4" />
          <div>
            <Skeleton width={"100%"} height={200} />
          </div>
        </BlocksComponent>
      </div>
    </div>
  );
};

export default journeyDetailsSkeleton;
