import React from "react";
import "./style.scss";
const ZeroStateComponent = ({ title, insights }) => {
  return (
    <>
      <div className={`main-zero-wrapper `}>
        <h6 className={"mb-0"}>{title}</h6>

        <div
          className={`text-center d-flex justify-content-center align-items-center h-100 ${
            insights ? "insight-header-zero" : ""
          }`}
        >
          <div>
            <h5 className="text-primary">No data found</h5>
            <p className="mb-0 fs-14 fw-400 text-other mt-1">
              Oops! Looks like there is no data available for this section.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ZeroStateComponent;
