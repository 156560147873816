import React from "react";

function IconArrowNextRight({ color = "fff", width = "14", height = "auto", className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 10 14"
      className={className}
    >
      <path fill={color} d="M2.325 0L.675 1.65 6.024 7l-5.35 5.35L2.326 14l7-7-7-7z" />
    </svg>
  );
}

export default IconArrowNextRight;
