import React from "react";

const IconFastRewind = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_28267_144625)">
        <path d="M11 18V6L2.5 12L11 18ZM11.5 12L20 18V6L11.5 12Z" fill="#545454" />
      </g>
      <defs>
        <clipPath id="clip0_28267_144625">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconFastRewind;
