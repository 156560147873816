import React, { useEffect } from "react";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { API_BASE_URL, LOGIN } from "../../Constants";
import { handleLoader } from "../../Services/callApi";
import { getHeaders } from "../../Services/getHeaders";
import { logoutAction } from "../../Store/actions";
import { showAlert } from "../../Services/showAlert";

function LogoutComponent(props) {
  const history = useHistory();

  const logout = async () => {
    handleLoader(true, "Logging you out");
    try {
      const response = await fetch(`${API_BASE_URL}/logout`, {
        headers: getHeaders(),
        method: "POST",
      });
      const responseData = await response.json();
      props?.logout();
      showAlert(responseData.message);
    } finally {
      const cookie = new Cookies();
      cookie.remove("token");
      handleLoader(false, "");
      history.push(LOGIN);
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return <></>;
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logoutAction());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(LogoutComponent);
