import { useEffect, useRef, useState } from "react";
import Events, { EventType } from "../Utils/events";

import getClientSocket from "../Utils/socket";

const DISCONNECTION_ERROR = {
  TRANSPORT_ERROR: "transport error",
};
const now = () => new Date().getTime();
export default function useSocket(onReconnect, onConnection) {
  const [socket] = useState(getClientSocket());
  const lastUpdatedTimeRef = useRef(now());

  useEffect(() => {
    if (!socket) {
      return;
    }

    const onConnect = () => {
      if (onConnection) {
        onConnection();
      }

      console.log("connected", socket.id);
    };

    const onDisconnect = (error) => {
      console.log("socket disconnected");
      lastUpdatedTimeRef.current = now();

      if (error === DISCONNECTION_ERROR.TRANSPORT_ERROR) {
        socket.connect();
      }
    };

    const onError = (...args) => {
      console.log("Authentication failed", ...args);
      const [data] = args;
      const error = data;

      if (error?.message === "unknown user") {
        Events.notify(EventType.AUTHENTICATION_FAILED, ...args);
      }
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", onError);

    if (!socket.connected) {
      socket.connect();
    }

    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", onError);
    };
  }, [socket, onConnection]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const onOnline = () => {
      socket.connect();

      if (typeof onReconnect === "function") {
        onReconnect(lastUpdatedTimeRef.current);
      }
    };

    const onOffline = () => {
      lastUpdatedTimeRef.current = now();
    };

    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);
    window.addEventListener("focus", onOnline);

    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
      window.removeEventListener("focus", onOnline);
    };
  }, [socket, onReconnect]);

  return socket;
}
