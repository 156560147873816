import React from "react";
import TaskTypeSkeleton from "../../TaskTypeSkeleton";
import CommonTaskTypeSkeleton from "../../TaskTypeSkeleton/CommonTaskTypeSkeleton";

function TaskDetailSkeleton() {
  return (
    <div className="ml-4 mt-4">
      <TaskTypeSkeleton />
      <CommonTaskTypeSkeleton />
    </div>
  );
}

export default TaskDetailSkeleton;
