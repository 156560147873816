import moment from "moment";
import { store } from "../../Store";
import {
  allowedCompanies,
  ENDPOINTS,
  getURLS,
  SEARCH_OPTIONS,
  searchOptions,
  TOKEN,
} from "../Resources";

export function getCurrentCompany() {
  const { userData } = store.getState() || {};
  return userData?.manifest?.company?.id;
}

export const getEndpoint = (companyId, feature) => {
  const company = Object.values(allowedCompanies).find((c) => c.id === companyId);
  if (!company) {
    return null;
  }

  if (feature === SEARCH_OPTIONS.DRIVE_THRU_DATA && company.driveThruEndpoint) {
    return company.driveThruEndpoint;
  }

  return ENDPOINTS[feature];
};

export function getCurrentCompanyWithTheirAIFeatures() {
  return Object.values(allowedCompanies).find((c) => c.id === getCurrentCompany());
}

// Finalize endpoint details
export const getEndpointDetails = (search) => {
  const company = getCurrentCompanyWithTheirAIFeatures();
  const endpoint = getEndpoint(company.id, search);
  const isVideoSearch = search === SEARCH_OPTIONS.VIDEO;
  const endpointUrl = getURLS(search);
  const token = !isVideoSearch ? TOKEN : null;
  return { endpoint, endpointUrl, token };
};

// Constructing request parameters
export const getRequestParams = (message, option, location) => {
  const company = getCurrentCompanyWithTheirAIFeatures();
  if (option === SEARCH_OPTIONS.VIDEO) {
    return { location, search_query: message };
  }
  if (option === SEARCH_OPTIONS.CAMERA_AUDIT) {
    return message;
  }
  return { query: message, companyId: company.id };
};
export function convertDurationToMs(durationString) {
  const duration = moment.duration(durationString);
  return duration.asMilliseconds();
}

export const hasAccessToFeature = (companyId, feature) => {
  const company = Object.values(allowedCompanies).find((c) => c.id === companyId);
  if (!company) {
    return false;
  }
  return company.features.includes(feature);
};

export const getSearchOptionsForCompany = (companyId) =>
  searchOptions.filter((option) => hasAccessToFeature(companyId, option.value));
