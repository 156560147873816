import React from "react";
import Skeleton from "react-loading-skeleton";
import TaskTypeSkeleton from "../../../DetailsSkeleton/TaskTypeSkeleton";

function TaskListSkeleton() {
  return (
    <>
      <TaskTypeSkeleton />
      <Skeleton count={20} height={50} className="playback-table-skeleton" />
    </>
  );
}

export default TaskListSkeleton;
