import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./Styles.module.scss";

function SettingsOpenEyeSkeleton() {
  return (
    <div className={styles.container}>
      <div className="row">
        <div className="d-flex justify-content-between w-100">
          <div className={styles.header}>
            <h1>
              <Skeleton width={220} height={30} />
            </h1>
            <p>
              <Skeleton width={400} height={15} />
            </p>
          </div>
          <Skeleton width={30} height={30} />
        </div>
        <div className={styles.content}>
          <Skeleton height={90} width="100%" className="mb-2" />
          <Skeleton height={90} width="100%" />
        </div>
      </div>
    </div>
  );
}

export default SettingsOpenEyeSkeleton;
