import React from "react";
import Skeleton from "react-loading-skeleton";

function ErrorPageSkeleton() {
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center w-100 mt-50">
        <Skeleton width={140} height={40} />
        <div className="mt-4">
          <Skeleton width={500} height={250} />
        </div>
        <div className="mt-50">
          <Skeleton width={100} height={40} />
        </div>
        <div className="mt-4">
          <Skeleton width={500} height={30} />
        </div>
        <div className="mt-4">
          <Skeleton width={700} height={30} />
        </div>
        <div className="mt-4">
          <Skeleton width={100} height={40} />
        </div>
      </div>
    </div>
  );
}

export default ErrorPageSkeleton;
