import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

function LoginSkeleton({ height, width, showTitle, titleWidth, titleHeight }) {
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center max-w-xl w-100 mt-50">
        {showTitle && <Skeleton width={titleWidth} height={titleHeight} />}
        <div className="mt-5">
          <Skeleton width={width} height={height} />
        </div>
      </div>
    </div>
  );
}

export default LoginSkeleton;

LoginSkeleton.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showTitle: PropTypes.bool,
};

LoginSkeleton.defaultProps = {
  height: 470,
  width: 450,
  showTitle: true,
  titleHeight: 40,
  titleWidth: 140,
};
