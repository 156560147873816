import React, { lazy, Suspense } from "react";
import { checkWobotDomain, PAGE_TITLES } from "../../../Services/checkWobotDomain";
import TaskDetailSkeleton from "../../../Common/Components/SkeletonComponent/DetailsSkeleton/TaskDetailSkeleton/TaskDetailSkeleton";
import PageContainer from "../../../Common/Containers/_layouts/PageContainer";

const TaskDetailsComponent = lazy(() => import("../../Components/TaskDetailsComponent"));

function TaskListDetails(props) {
  return (
    <PageContainer
      initialPageTitle={checkWobotDomain(PAGE_TITLES.taskDetails)}
      meta="Set up task on different locations by selecting cameras, schedule and alert settings."
    >
      <Suspense fallback={<TaskDetailSkeleton />}>
        <TaskDetailsComponent props={props} />
      </Suspense>
    </PageContainer>
  );
}

export default TaskListDetails;
