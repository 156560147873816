import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

const pageView = () => {
  if (process.env.REACT_APP_GTM_ID) {
    TagManager.dataLayer({
      dataLayer: {
        event: "page View",
        pagePath: window.location.pathname,
        pageTitle: document.title,
      },
    });
  }
};

export { tagManagerArgs, pageView };
