import React from "react";

function IconStationFilter({ color = "#545454" }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.99902 14V12.6667H3.33236V2.66667C3.33236 2.29848 3.63083 2 3.99902 2H11.999C12.3672 2 12.6657 2.29848 12.6657 2.66667V12.6667H13.999V14H1.99902ZM11.3323 3.33333H4.66569V12.6667H11.3323V3.33333ZM9.99899 7.33333V8.66667H8.66566V7.33333H9.99899Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconStationFilter;
