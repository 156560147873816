import React from "react";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../../Molecule/PageTitleSkeleton";

function MainTaskSkeleton() {
  return (
    <div className="wobot-panel-main">
      <div className="main-body-wrapper">
        <PageTitleSkeleton />

        {/* <div className={"panel-body mt-60 row mx-auto"}>
          {Array(5)
            .fill()
            .map((e, index) => (
              <div className={"mr-3 mb-2"} key={index}>
                <Skeleton width={150} height={150} />
              </div>
            ))}
        </div> */}
        <div className="panel-body mt-60">
          <div id="table-content">
            <Skeleton
              count={10}
              // width={1400}
              height={50}
              className="playback-table-skeleton mb-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainTaskSkeleton;
