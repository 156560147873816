import PropTypes from "prop-types";
import React from "react";
import "./style.scss";

function BlocksComponent({ className, style, children }) {
  return (
    <div className={`element-block panel-fh ${className}`} style={style}>
      {children}
    </div>
  );
}

BlocksComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.node.isRequired,
};
BlocksComponent.defaultProps = {
  className: "",
  style: {},
};
export default BlocksComponent;
