function IconPlayback() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="
  http://www.w3.org/2000/svg"
    >
      <path d="M11 8L5.75 5V11L11 8Z" fill="#545454" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 2.75C0.5 1.50736 1.50736 0.5 2.75 0.5H13.25C14.4926 0.5 15.5 1.50736 15.5 2.75V13.25C15.5 14.4926 14.4926 15.5 13.25 15.5H2.75C1.50736 15.5 0.5 14.4926 0.5 13.25V2.75ZM2.75 2H13.25C13.6642 2 14 2.33579 14 2.75V13.25C14 13.6642 13.6642 14 13.25 14H2.75C2.33579 14 2 13.6642 2 13.25V2.75C2 2.33579 2.33579 2 2.75 2Z"
        fill="#545454"
      />
    </svg>
  );
}
export default IconPlayback;
