import React from "react";
import { useSelector } from "react-redux";
import Service from "../../../../../Services/Service";

function CompanyLogo() {
  const { manifest: { appLogo } = {} } = useSelector((state) => state.userData || {});

  const getDefaultLogo = () => {
    if (Service.checkHost("airtel.com")) {
      return "https://wobot-prod-application.s3.amazonaws.com/static/7mN5kVso-ODfA2RT.svg";
    }
    if (Service.checkHost("openeye")) {
      return "https://wobot-prod-application.s3.amazonaws.com/static/uSevKxvtePov-oJq.svg";
    }
    return "/assets/images/wobot-Icon-blue.svg";
  };

  const logoSrc = appLogo || getDefaultLogo();

  return <img className="img-fluid" src={logoSrc} alt="logo" />;
}

export default CompanyLogo;
