import React from "react";

const IconLinkExpired = () => {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.4998 0C51.5453 0 61.5453 4.47721 68.7843 11.7161C76.0232 18.9544 80.5004 28.9551 80.5004 40C80.5004 51.0462 76.0232 61.0456 68.7843 68.2839C61.5453 75.5228 51.5453 80 40.4998 80C29.4542 80 19.4542 75.5228 12.2159 68.2839C4.97697 61.0456 0.499756 51.0456 0.499756 39.9994C0.499756 28.9544 4.97697 18.9538 12.2159 11.7155C19.4542 4.47721 29.4542 0 40.4998 0ZM52.6873 24.3288C53.5343 23.4811 54.9086 23.4811 55.7556 24.3288C56.6026 25.1758 56.6026 26.5495 55.7556 27.3971L43.1508 40.0026L55.7556 52.6081C56.6026 53.4551 56.6026 54.8288 55.7556 55.6764C54.9086 56.5234 53.5343 56.5234 52.6873 55.6764L40.0818 43.071L27.4763 55.6771C26.6293 56.5241 25.255 56.5241 24.408 55.6771C23.561 54.8294 23.561 53.4557 24.408 52.6087L37.0141 40.0033L24.408 27.3971C23.561 26.5495 23.561 25.1758 24.408 24.3288C25.255 23.4811 26.6293 23.4811 27.4763 24.3288L40.0824 36.9342L52.6873 24.3288ZM65.7159 14.7839C59.2628 8.33073 50.3474 4.33984 40.4998 4.33984C30.6521 4.33984 21.7374 8.33073 15.2843 14.7839C8.83114 21.237 4.8396 30.1523 4.8396 39.9994C4.8396 49.847 8.83114 58.7624 15.2836 65.2155C21.7367 71.668 30.6514 75.6595 40.4991 75.6595C50.3468 75.6595 59.2621 71.668 65.7152 65.2155C72.1684 58.7624 76.1599 49.847 76.1599 39.9994C76.1606 30.1523 72.169 21.237 65.7159 14.7839Z"
        fill="#DC3545"
      />
    </svg>
  );
};

export default IconLinkExpired;
