import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import PageTitleSkeleton from "../../../Molecule/PageTitleSkeleton";
import "./styles.scss";
import DataSkeletonComponent from "./DataSkeletonComponent";

function LiveMonitoringSkeleton({ fullPageSkeleton }) {
  return (
    <div className={fullPageSkeleton ? "m-20" : ""}>
      <PageTitleSkeleton />
      <div className="">
        <Skeleton width={200} height={40} />
      </div>
      <DataSkeletonComponent />
    </div>
  );
}

export default LiveMonitoringSkeleton;
LiveMonitoringSkeleton.propTypes = {
  fullPageSkeleton: PropTypes.bool,
};

LiveMonitoringSkeleton.defaultProps = {
  fullPageSkeleton: false,
};
