import React from "react";

const IconMobile = () => {
  return (
    <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.167 0.340365L1.83366 0.332031C0.916992 0.332031 0.166992 1.08203 0.166992 1.9987V16.9987C0.166992 17.9154 0.916992 18.6654 1.83366 18.6654H10.167C11.0837 18.6654 11.8337 17.9154 11.8337 16.9987V1.9987C11.8337 1.08203 11.0837 0.340365 10.167 0.340365ZM10.167 15.332H1.83366V3.66536H10.167V15.332Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconMobile;
