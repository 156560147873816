import React from "react";

function IconShifts() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6226 4.63459C7.32567 4.63459 3.84228 8.11798 3.84228 12.415C3.84228 16.7119 7.32567 20.1953 11.6226 20.1953C15.9196 20.1953 19.403 16.7119 19.403 12.415H21.2076C21.2076 17.7086 16.9163 22 11.6226 22C6.32899 22 2.03764 17.7086 2.03764 12.415C2.03764 7.12131 6.32899 2.82996 11.6226 2.82996C14.2528 2.82996 16.6367 3.89044 18.3677 5.60491L20.0499 4.07285L20.9863 10.2018L15.283 8.41419L17.0313 6.82201C15.6304 5.46689 13.7244 4.63459 11.6226 4.63459Z"
        fill="#545454"
      />
      <path d="M10.1585 7.5289V14.0494H16.3981V12.2448H11.9631V7.5289H10.1585Z" fill="#545454" />
    </svg>
  );
}

export default IconShifts;
