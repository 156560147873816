import React, { Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
import CustomerAnalyticsSkeleton from "../../Common/Components/SkeletonComponent/MainModulesSkeleton/CustomerAnalyticsSkeleton";
import PageContainer from "../../Common/Containers/_layouts/PageContainer";

const CustomerAnalyticsComponent = lazy(() => import("../Components/CustomerAnalyticsComponent"));

function CustomerAnalytics() {
  const location = useLocation();
  // const [paramId, setParamId] = useState();

  // const getParam = (string) => {
  //   let paramArray = string.split("/");
  //   return paramArray;
  // };
  // useEffect(() => {
  //   let param = getParam(location.pathname)[3];
  //   setParamId(param);
  // }, []);

  return (
    <PageContainer initialPageTitle="Customer Analytics | Wobot AI">
      <Suspense fallback={<CustomerAnalyticsSkeleton />}>
        <CustomerAnalyticsComponent />
      </Suspense>
    </PageContainer>
  );
}

export default CustomerAnalytics;
