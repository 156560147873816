import React from "react";

function IconAnalytics() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V11H20C21.1046 11 22 11.8954 22 13V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V11C2 9.89543 2.89543 9 4 9H8ZM10 5H14V19H10V5ZM8 11H4V19H8V11ZM16 13V19H20V13H16Z"
        fill="#545454"
      />
    </svg>
  );
}

export default IconAnalytics;
