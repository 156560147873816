import React from "react";

const IconCrossDelete = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_39850_11837)">
        <path
          d="M10 1.01072L8.98928 0L4.99998 3.98929L1.01072 0L0 1.01072L3.98929 4.99998L0 8.98928L1.01072 10L4.99998 6.01071L8.98928 10L10 8.98928L6.01071 4.99998L10 1.01072Z"
          fill="#343434"
        />
      </g>
      <defs>
        <clipPath id="clip0_39850_11837">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCrossDelete;
