/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useHistory, useLocation } from "react-router-dom";
import IconVideoPlay from "../../../Common/Components/IconsComponent/IconVideoPlay";
import { PLAYBACK } from "../../../Constants";
import { PLAYBACK_URL, VIDEO_THUMBNAIL } from "../../Resources";
import styles from "../Styles.module.scss";
const timeStringToSeconds = (timeString) => {
  const parts = timeString.split(":");
  return +parts[0] * 3600 + +parts[1] * 60 + +parts[2];
};
function RenderVideoMessage({ data, sender, isError, cameraId }) {
  const history = useHistory();
  const location = useLocation();

  const handleRedirect = (videoId, seekTo) => {
    const seekTimeInMs = timeStringToSeconds(seekTo);
    let url = `${PLAYBACK_URL}&camera=${cameraId}&video=${videoId}&seekTo=${seekTimeInMs}`;
    if (location.pathname === PLAYBACK) {
      url += `&refresh=true`;
    }
    history.push(url);
  };

  const transFormedData = data
    .map((obj) => {
      const videoId = Object.keys(obj)[0].split(".")[0];
      const duration = obj[Object.keys(obj)[0]];
      return { videoId, duration };
    })
    .filter((el) => el.duration);

  return (
    <div className={`${styles[sender]} ${isError ? styles.error : ""}`}>
      <div className={styles.message}>
        <div className={styles["video-container"]}>
          {transFormedData?.map(({ videoId, duration }, index) => {
            return (
              <div
                role="button"
                key={videoId}
                className={styles["video-card"]}
                style={{
                  backgroundImage: `url(${VIDEO_THUMBNAIL}${videoId}.jpeg)`,
                }}
                onClick={() => handleRedirect(videoId, duration)}
              >
                <div className={styles["over-lay"]} />
                <IconVideoPlay />
                <div className={styles["video-footer"]}>
                  <span className={styles["video-name"]}>{`Video ${index + 1}`}</span>
                  <span className={styles["video-duration"]}>at {duration}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function Message({ cameraId, message: { sender, content, isError, images = "", data = [] } }) {
  return (
    <>
      <div className={`${styles[sender]} ${isError ? styles.error : ""}`}>
        <div className={styles.message}>
          {images ? (
            <img src={images} alt="Uploaded-cover" />
          ) : (
            <ReactMarkdown>{content}</ReactMarkdown>
          )}
        </div>
      </div>
      {data && data.length ? (
        <RenderVideoMessage data={data} sender={sender} isError={isError} cameraId={cameraId} />
      ) : null}
    </>
  );
}
Message.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.string,
    sender: PropTypes.string,
    isError: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.arrayOf(PropTypes.object),
    // eslint-disable-next-line react/forbid-prop-types
    images: PropTypes.array,
  }).isRequired,
  cameraId: PropTypes.string.isRequired,
};
export default Message;
