import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import useRequest from "../../Common/Hooks/useRequest";
import fetchAssistantMessage from "../Requests";
import { optionTypePost, SEARCH_OPTIONS } from "../Resources";
import { setMessages } from "../State/assistantAction";
import { getEndpointDetails, getRequestParams } from "../Utils";

function useAssistantSearch({ search, location }) {
  const dispatch = useDispatch();

  const searchOption = useMemo(() => search?.value, [search]);
  const locationOption = useMemo(() => location?.value, [location]);
  const searchOptionRef = useRef(searchOption);

  useEffect(() => {
    searchOptionRef.current = searchOption;
  }, [searchOption]);

  const handleSetError = useCallback(() => {
    dispatch(
      setMessages({
        content: "Oops! Something went wrong, please try again.",
        sender: "assistant",
        isError: true,
        id: uuidV4(),
      })
    );
  }, [dispatch]);

  const handleSetAssistantMessage = useCallback(
    (response, selectedSearch) => {
      const isCameraAudit = selectedSearch === SEARCH_OPTIONS.CAMERA_AUDIT;
      const isVideoOption = selectedSearch === SEARCH_OPTIONS.VIDEO;
      let content = "";
      if (isCameraAudit) {
        content = response?.response?.content;
      } else if (isVideoOption) {
        content = `Here are the top ${response?.length} videos matching the query:`;
      } else content = response?.response;
      dispatch(
        setMessages({
          content,
          sender: "assistant",
          isError: false,
          data: isVideoOption ? response : null,
          id: uuidV4(),
        })
      );
    },
    [dispatch]
  );

  const [{ loading }, request] = useRequest({
    request: fetchAssistantMessage,
    config: {
      onError: (res) => handleSetError(res),
      onSuccess: (res) => {
        handleSetAssistantMessage(res, searchOptionRef.current);
      },
    },
  });

  const handleMessageSubmit = useCallback(
    (message, formData) => {
      const isCameraAudit = searchOption === SEARCH_OPTIONS.CAMERA_AUDIT;

      const currentMessage = {
        content: message,
        ...(isCameraAudit ? { images: message } : {}),
        sender: "user",
        id: uuidV4(),
      };

      dispatch(setMessages(currentMessage));

      const { endpoint, endpointUrl, token } = getEndpointDetails(searchOption);
      const uploadData = formData || message;
      const params = getRequestParams(uploadData, searchOption, locationOption);

      const method = optionTypePost.includes(searchOption) ? "POST" : "GET";
      request({
        endPoint: { url: endpointUrl, endPoint: endpoint, token },
        params,
        method,
        contentType: isCameraAudit ? "multipart/form-data" : undefined,
      });
    },
    [dispatch, searchOption, locationOption]
  );

  return {
    isLoadingResponse: loading,
    handleMessageSubmit,
  };
}

export default useAssistantSearch;
