import React from "react";

const IconTagging = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 2H5C3.89 2 3 2.9 3 4V18C3 19.1 3.89 20 5 20H9L12 23L15 20H19C20.1 20 21 19.1 21 18V4C21 2.9 20.1 2 19 2ZM15 8C15 9.6575 13.6575 11 12 11C10.3425 11 9 9.6575 9 8C9 6.3425 10.3425 5 12 5C13.6575 5 15 6.3425 15 8ZM6 15.5C6 13.505 9.9975 12.5 12 12.5C14.0025 12.5 18 13.505 18 15.5V17H6V15.5Z"
        fill="#545454"
      />
    </svg>
  );
};

export default IconTagging;
