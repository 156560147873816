import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./Styles.module.scss";

function SettingsPlansModuleSkeleton() {
  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-xl-9 col-lg-9 col-md-9">
          <div className={styles.header}>
            <h1>
              <Skeleton width={220} height={30} />
            </h1>
            <p>
              <Skeleton width={400} height={15} />
            </p>
          </div>
          {[1, 2, 3, 4].map((k) => (
            <div className={styles.usage} key={k}>
              <h1 className="mb-2">
                <Skeleton width={220} height={20} />
              </h1>
              <p className="mb-0">
                <Skeleton width="40%" height={15} className="mb-3" />
                <Skeleton width="100%" height={10} />
              </p>
              <div className="d-flex justify-content-end">
                <Skeleton width={200} height={10} />
              </div>
            </div>
          ))}
          <div className={styles.help_wrapper}>
            <Skeleton width={380} height={30} />
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-3">
          <Skeleton height={700} />
        </div>
      </div>
    </div>
  );
}

export default SettingsPlansModuleSkeleton;
