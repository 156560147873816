import { showAlert } from "../../../../../Services/showAlert";

async function uploadMediaRequest({ attachment, taskId }) {
  const form = new FormData();
  form.append("attachment", attachment || "");
  try {
    if (process.env.REACT_APP_CLICK_UP_ATTACHMENT_TOKEN) {
      await fetch(`https://api.clickup.com/api/v2/task/${taskId}/attachment`, {
        method: "POST",
        headers: {
          Authorization: process.env.REACT_APP_CLICK_UP_ATTACHMENT_TOKEN || "",
        },
        body: form,
      });
    } else {
      showAlert("Need Authorization token", "error");
    }
  } catch (error) {
    showAlert("Something went wrong, Failed to upload attachment", "error");
  }
}

export default uploadMediaRequest;
