import React from "react";

const IconDeleteTag = (props) => {
  return false ? (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#F8D7DA" />
      <g clip-path="url(#clip0_34640_209665)">
        <path
          d="M24 17V27H16V17H24ZM22.5 11H17.5L16.5 12H13V14H27V12H23.5L22.5 11ZM26 15H14V27C14 28.1 14.9 29 16 29H24C25.1 29 26 28.1 26 27V15Z"
          fill="#DC3545"
        />
      </g>
      <defs>
        <clipPath id="clip0_34640_209665">
          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#F9F9F9" />
      <g clip-path="url(#clip0_34640_209655)">
        <path
          d="M24 17V27H16V17H24ZM22.5 11H17.5L16.5 12H13V14H27V12H23.5L22.5 11ZM26 15H14V27C14 28.1 14.9 29 16 29H24C25.1 29 26 28.1 26 27V15Z"
          fill="#545454"
        />
      </g>
      <defs>
        <clipPath id="clip0_34640_209655">
          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDeleteTag;
