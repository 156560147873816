import { IconDone, IconIncomplete, IconProgress } from "../Assets/ProgressIcons";

export const viewType = {
  floor: "floor",
  camera: "camera",
};

export const redrawMappings = (cameraRef, floorMapRef, currentFloorConfig) => {
  if (!floorMapRef.current) return;

  const cameraCanvas = cameraRef?.current;
  const floorMapCanvas = floorMapRef.current;

  const selectedCameraObj =
    currentFloorConfig?.config?.find((camera) => camera.isSelected) ||
    currentFloorConfig?.config[0];

  const cameraBgUrl = selectedCameraObj?.camera?.thumbnail || "";
  const floorMapBgUrl = currentFloorConfig.image || "";

  // Load and draw images
  // if (cameraCanvas) {
  loadAndDrawImage(cameraCanvas, cameraBgUrl, (img) => {
    const scale = adjustCanvasDisplaySize(cameraCanvas, img.width, img.height);
    const ctx = cameraCanvas.getContext("2d");
    ctx.clearRect(0, 0, cameraCanvas.width, cameraCanvas.height);
    ctx.drawImage(img, 0, 0, cameraCanvas.width, cameraCanvas.height);

    // Draw shapes after adjusting canvas size
    drawShapes(ctx, selectedCameraObj.cameraROI, "blue", 3 / scale);
  });

  loadAndDrawImage(floorMapCanvas, floorMapBgUrl, (img) => {
    const scale = adjustCanvasDisplaySize(floorMapCanvas, img.width, img.height);
    const ctx = floorMapCanvas.getContext("2d");
    ctx.clearRect(0, 0, floorMapCanvas.width, floorMapCanvas.height);
    ctx.drawImage(img, 0, 0, floorMapCanvas.width, floorMapCanvas.height);

    currentFloorConfig.config.forEach((camera, index) => {
      drawShapes(ctx, camera.position, getRoiColor(index), 3 / scale);
    });
  });
};

export const adjustCanvasDisplaySize = (canvas, imageWidth, imageHeight) => {
  const container = canvas.parentElement;
  const containerStyle = window.getComputedStyle(container);
  const maxWidth = parseInt(containerStyle.width, 10);
  const maxHeight = parseInt(containerStyle.height, 10);

  if (!canvas.originalImageWidth) {
    canvas.originalImageWidth = imageWidth;
    canvas.originalImageHeight = imageHeight;
  }

  const scaleX = maxWidth / canvas.originalImageWidth;
  const scaleY = maxHeight / canvas.originalImageHeight;
  const scale = Math.min(scaleX, scaleY, 1);

  const newWidth = Math.round(canvas.originalImageWidth * scale);
  const newHeight = Math.round(canvas.originalImageHeight * scale);

  canvas.width = newWidth;
  canvas.height = newHeight;
  canvas.style.width = `${newWidth}px`;
  canvas.style.height = `${newHeight}px`;

  return scale;
};

const loadAndDrawImage = (canvas, imageUrl, callback) => {
  const img = new Image();
  img.onload = () => callback(img);
  img.src = imageUrl;
};

export const drawShapes = (ctx, points, color, radius = 3) => {
  if (points.length === 0) return;

  const canvasWidth = ctx.canvas.width;
  const canvasHeight = ctx.canvas.height;

  // Draw points
  points.forEach(([x, y]) => {
    ctx.beginPath();
    ctx.arc(x * canvasWidth, y * canvasHeight, radius, 0, 2 * Math.PI);
    ctx.fillStyle = color;
    ctx.fill();
    ctx.closePath();
  });

  // Draw lines
  if (points.length >= 2) {
    ctx.beginPath();
    ctx.moveTo(points[0][0] * canvasWidth, points[0][1] * canvasHeight);
    points.forEach(([x, y], index) => {
      if (index > 0) {
        ctx.lineTo(x * canvasWidth, y * canvasHeight);
      }
    });
    if (points.length >= 3) {
      ctx.closePath();
    }
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.stroke();
  }
};

export const handleCanvasClick = (e, setCurrentFloorConfig, view) => {
  const canvas = e.target;
  const rect = canvas.getBoundingClientRect();

  const x = (e.clientX - rect.left) / rect.width;
  const y = (e.clientY - rect.top) / rect.height;

  setCurrentFloorConfig((prevState) => ({
    ...prevState,
    config: prevState.config.map((camera) => {
      if (camera.isSelected) {
        if (view === "camera" && camera.cameraROI.length < 4) {
          if (camera.cameraROI.length === 3 && camera.position.length === 4) {
            return {
              ...camera,
              cameraROI: [...camera.cameraROI, [x, y]],
              isCompleted: true,
            };
          }

          return {
            ...camera,
            cameraROI: [...camera.cameraROI, [x, y]],
          };
        }
        if (view !== "camera" && camera.position.length < 4) {
          if (camera.cameraROI.length === 4 && camera.position.length === 3) {
            return {
              ...camera,
              position: [...camera.position, [x, y]],
              isCompleted: true,
            };
          }

          return {
            ...camera,
            position: [...camera.position, [x, y]],
          };
        }
      }
      return camera;
    }),
  }));
};

export const handleViewAction = (setCurrentFloorConfig, view, actionType) => {
  setCurrentFloorConfig((prevState) => ({
    ...prevState,
    config: prevState.config.map((camera) => {
      if (camera.isSelected) {
        const arrayKey = view === "camera" ? "cameraROI" : "position";
        const array = camera[arrayKey];

        switch (actionType) {
          case "undo":
            if (array.length > 0) {
              return {
                ...camera,
                isCompleted: false,
                [arrayKey]: array.slice(0, -1),
              };
            }
            break;
          case "reset":
            return {
              ...camera,
              isCompleted: false,
              [arrayKey]: [],
            };
          default:
            console.log("Unknown actionType:", actionType);
            break;
        }
      }
      return camera;
    }),
  }));
};

export function updateURL(floorId) {
  const params = new URLSearchParams(window.location.search);

  // Set the locationId field with the provided value
  if (floorId) {
    params.set("floorId", floorId);
  }

  const queryString = params.toString();
  const baseUrl = window.location.pathname;
  const updatedURL = `${baseUrl}?${queryString}`;
  window.history.replaceState({}, "", updatedURL);
}

export function updateCameraSelection(data, cameraId, thumbnail, name) {
  const cameraExists = data.config.some((item) => item.camera._id === cameraId);

  let updatedConfig;

  if (cameraExists) {
    updatedConfig = data.config.map((item) => ({
      ...item,
      isSelected: item.camera._id === cameraId,
    }));
  } else {
    const filterEmpty = data.config.filter(
      (item) => item.cameraROI.length === 4 && item.position.length === 4
    );

    updatedConfig = [
      ...filterEmpty.map((item) => ({ ...item, isSelected: false })),
      {
        position: [],
        cameraROI: [],
        isSelected: true,
        isCompleted: false,
        index: filterEmpty.length,
        camera: {
          camera: name,
          _id: cameraId,
          thumbnail,
        },
      },
    ];
  }

  return {
    ...data,
    config: updatedConfig,
  };
}

export const getProgressStatus = (length) => {
  if (length > 0 && length < 4) {
    return <IconProgress />;
  }
  if (length === 0) {
    return <IconIncomplete />;
  }
  if (length === 4) {
    return <IconDone />;
  }

  return <IconIncomplete />;
};

export const getSaveStatus = (config) =>
  config.length &&
  ((config.length === 1 && config[0].position.length === 4 && config[0].cameraROI.length === 4) ||
    (config.length > 1 &&
      config.filter((item) => item.position.length === 0 && item.cameraROI.length === 0).length <=
        1 &&
      config.every(
        (item) =>
          (item.position.length === 4 && item.cameraROI.length === 4) ||
          (item.position.length === 0 && item.cameraROI.length === 0)
      )));

export const getRoiColor = (index) => {
  const colors = [
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#FF00FF",
    "#00FFFF",
    "#800000",
    "#008000",
    "#000080",
    "#808000",
    "#800080",
    "#008080",
    "#FFA500",
    "#A52A2A",
    "#FFD700",
    "#008B8B",
    "#4B0082",
    "#7CFC00",
    "#FF4500",
    "#9400D3",
    "#1E90FF",
    "#FF1493",
    "#00FA9A",
    "#B8860B",
    "#20B2AA",
    "#FF6347",
    "#778899",
    "#00CED1",
    "#8B4513",
    "#FF69B4",
    "#2E8B57",
    "#DAA520",
    "#6A5ACD",
    "#32CD32",
    "#DC143C",
    "#4682B4",
    "#9932CC",
    "#F4A460",
    "#5F9EA0",
    "#D2691E",
  ];

  return colors[index % colors.length];
};
