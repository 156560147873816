import React from "react";

function IconAddRounded({ size, color, isNew }) {
  return isNew ? (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5C22 18.0228 17.5228 22.5 12 22.5ZM11 11.5H7V13.5H11V17.5H13V13.5H17V11.5H13V7.5H11V11.5Z"
        fill="#3766E8"
      />
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <g clipPath="url(#clip0_50308_4812)">
        <path
          d="M17 12.7143H12.7143V17H11.2857V12.7143H7V11.2857H11.2857V7H12.7143V11.2857H17V12.7143Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_50308_4812">
          <rect width="10" height="10" fill="white" transform="translate(7 7)" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconAddRounded.defaultProps = {
  size: "16",
  color: "#194DDE",
  new: false,
};

export default IconAddRounded;
