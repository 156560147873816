import PropTypes from "prop-types";
import React from "react";
import { IconSmartAssistantAnimated } from "../../../Common/Components/IconsComponent/IconSmartAssistant";
import styles from "../Styles.module.scss";

function ZeroState({ isShowZeroState }) {
  return (
    <div
      className={`${styles["zero-state-container"]} ${
        isShowZeroState ? styles["zero-state-visible"] : ""
      }`}
    >
      <div className={styles.logo}>
        <IconSmartAssistantAnimated size="88" />
      </div>
      <div className={styles.description}>
        <div>
          <h2 className="fw-500 m-0 fs-26 text-white">Hello,</h2>
          <h5 className="fw-600 m-0 fs-16 text-white">How can I help you?</h5>
        </div>
        <div className={styles["info-suggestion"]}>
          <p>
            You can talk to me in simple words, no code needed. For example: “Show me clips where
            there are unattended customers”
          </p>
        </div>
      </div>
    </div>
  );
}
ZeroState.propTypes = {
  isShowZeroState: PropTypes.bool.isRequired,
};
export default ZeroState;
