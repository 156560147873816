import React, { lazy, Suspense } from "react";
import SignupSkeleton from "../Components/SkeletonComponent/CommonSkeleton/SignupSkeleton";

const SignupComponent = lazy(() => import("../Components/SignupComponent"));

function Signup() {
  return (
    <Suspense fallback={<SignupSkeleton />}>
      <SignupComponent />
    </Suspense>
  );
}

export default Signup;
